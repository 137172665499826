import * as React from 'react'
import { useHistory } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'


const base_url = process.env.REACT_APP_BASE_URL;

var api_called = false

export default function VerifyEmail() {
  const [message, setMessage] = React.useState('Verifying Email ...')
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')
  const url = `${base_url}v1/verify_email/?id=${id}`

  const history = useHistory()

  if (!api_called) {
    api_called = true

    fetch(url, {method: 'POST'})
    .then(response => {

      if (!response.ok) {
        alert('Something went wrong. Please try again later.')
        history.push('/signin')
      } 
      else {
        response.json()
        .then(responseJson => {

          setTimeout(function(){
            setCookie('currentUser', JSON.stringify(responseJson.data.data), 30)
            history.push('/')
          }, 200)
        })
      }
    })
  }
  
  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  function eraseCookie(name) {   
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

return (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        {message}
      </Typography>
    </Box>
  </Container>
);
}