import React, { useState, useRef } from "react";
import { useHistory } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import BiosketchTable from "components/Table/BiosketchTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProposalDownloadTable from "components/Table/ProposalDownloadTable";
import { Backdrop } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const base_url = process.env.REACT_APP_BASE_URL;

export default function ProposalDownloadTableList() {
  const history = useHistory()
  const currentUser = checkUser(history)
  const classes = useStyles();

  const searchBarRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [allTopics, setAllTopics] = useState([])

  var [searchKeys, setSearchKeys] = useState()

  React.useEffect(() => {
    if (currentUser !== undefined) {
      fetchData()
    }
  }, [])

  function checkUser(history) {
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push({
        pathname: '/signin',
        state: {urlPath: '/tool/browse/proposal'},
      })
      return

    } else {
      const accountJSON = JSON.parse(accountDetails)
      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }
      return accountJSON
    }
  }

  function fetchData() {
    
    setLoading(true)
    fetch(`${base_url}v1/proposals/downloads/?id=${currentUser.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          setTableData(responseJson.data.data)
        }
      })
    })
  }

  const LoadingFade = () => {
    return (
      <Backdrop open={loading} style={{zIndex: '5'}}>
        <CircularProgress />
      </Backdrop>
    )
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <LoadingFade />

        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Proposal Download Analytics</h4>
          </CardHeader>
          <CardBody>
            <ProposalDownloadTable
              tableHeaderColor="primary"
              tableHead={['Person Name', 'Proposal Title', 'File Name', 'Date']}
              tableData={tableData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}