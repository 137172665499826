import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'

import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

const base_url = process.env.REACT_APP_BASE_URL;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright ©'}
      <Link color="inherit" href="https://www.oceanit.com/">
        Oceanit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#D37506',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ForgotPassword() {
  const classes = useStyles()

  const [loading, setLoading] = React.useState(false)
  const [isEmail, setIsEmail] = React.useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    checkFields(data)
  }

  function resetFields() {
    setIsEmail(false)
  }

  function checkFields(data) {
      var allChecked = true

      if (data.get('email') === '') {
        allChecked = false
        setIsEmail(true)
      }

      if (allChecked) {
        setLoading(true)

        fireForgotPassword(data.get('email'))
      }
  }

  function fireForgotPassword(email) {
    fetch(`${base_url}v1/forgot_password/?email=${email}`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
    })
    .then(response => {
      setTimeout(function() {
        setLoading(false)

        response.json()
        .then(responseJson => {

          if (response.ok) {
            alert(responseJson.data.message)
          } else {
            alert(responseJson.error.message)
          }
          window.location.reload(false)
        })
      }, 200)
    })
  }

  function setCookie(name, value, days) {
    var expires = ""
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + (days*24*60*60*1000))
        expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/"
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>

        <br></br>

        <Typography component="h2" variant="h6">
          Enter your registered email ID below to recieve the forgot password link.
        </Typography>
        
        <br></br>

        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>

        <br></br>
        <br></br>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container justify="center">
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  autoFocus
                  error={isEmail}
                  onChange={resetFields}
                />
              </Grid>
          </Grid>

          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
          </Button>
      </form>
      </div>

      <Grid container justify="center">
        <Grid item>
          <Link href="/signin" variant="body2">
            Have an account? Sign in
          </Link>
        </Grid>
      </Grid>

      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}