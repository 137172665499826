import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @material-ui/core components
import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Box,
  IconButton,
  Drawer,
  CircularProgress,
  Backdrop,
  Button,
  Menu,
  MenuItem,
  makeStyles
} from "@material-ui/core"

import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import RelatedResourceIcon from '@material-ui/icons/EmojiObjectsOutlined';

import AWS from 'aws-sdk'
import { downloadZip } from "client-zip/index.js"
import Highlighter from "react-highlight-words";

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

const base_url = process.env.REACT_APP_BASE_URL;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

const s3 = new AWS.S3({
  params: { Bucket: 'oceanit-proposals'},
  region: 'us-gov-east-1',
})

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, headCells, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.title}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeadCell: {
    color: "inherit",
    "&, &$tableCell": {
      fontSize: "1em",
    },
  },
  tableCell: {
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  list: {
    width: 500,
  },
  fullList: {
    width: 'auto',
  },
}));

function Row(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [resourceDrawerOpen, setResourceDrawerOpen] = useState(false)
  const [relatedProposals, setRelatedProposals] = useState([])
  // const [relatedWorks, setRelatedWorks] = useState([])

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const {prop, searchQuery, key, user, downloadProposal, openFile, classes, setLoading} = props

  const handleMouseOver = (event) => {
    if (user.role === 'GUEST') {
      return
    }
    if (user.role === 'USER_RESTRICTED' && prop.sensitive) {
      return
    }
    setMenuOpen(true)
    setMenuAnchorEl(event.target)
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  const FileItem = (file_key) => {
    let fileKeySplitArray = file_key.split('/')
    let file_name = fileKeySplitArray.at(-1)

    return (
      <MenuItem onClick={() => handleFileItemClick(file_key, file_name)}>{file_name}</MenuItem>
    )
  }

  const handleFileItemClick = (file_key, file_name) => {
    let date = new Date()
    let fileData = Object()

    fileData[file_name] = date.toLocaleDateString()

    let patchParams = {
      'pid' : prop.pid,
      'title' : prop.title,
      'email' : prop.lead_email,

      'file_key' : file_key,
      'file_names' : fileData
    }

    openFile(patchParams)
  }

  const toggleDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    logFirebaseEvent(`Browse -> Proposals -> Proposal ${prop.title} Tapped`)
    setDrawerOpen(openDrawer)
  }

  const toggleResourceDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // logFirebaseEvent(`Browse -> Proposal -> Proposal ${prop.title} Tapped`)
    fireProposalRelatedProposalsGetAPI(openDrawer)
  }

  // const fireProposalRelatedWorksGetAPI = (openDrawer) => {
  //   setLoading(true)
  //   fetch(`${base_url}v1/proposals/related_works/?id=${user.id}&pid=${prop.id}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   .then(response => {
  //     response.json()
  //     .then(responseJson => {
  //       setLoading(false)

  //       if (!response.ok) {
  //         alert(responseJson.error.message)
  //       } 
  //       else {
  //         setRelatedWorks(responseJson.data.data)
  //         setResourceDrawerOpen(openDrawer)
  //       }
  //     })
  //   })
  // }

  const fireProposalRelatedProposalsGetAPI = (openDrawer) => {
    setLoading(true)
    fetch(`${base_url}v1/proposals/related_proposals/?id=${user.id}&pid=${prop.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      response.json()
      .then(responseJson => {
        setLoading(false)

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          setRelatedProposals(responseJson.data.data)
          setResourceDrawerOpen(openDrawer)
        }
      })
    })
  }

  const TypographyHolder = (props) => {
    const {titleText, style} = props;
    return (
      <Typography 
        variant="body1"
        style={style} 
      >
        {titleText}
      </Typography>
    )
  }

  // const RelatedWorksPage = () => {

  //   return (
  //     <div
  //       className={clsx(classes.list)}
  //       role="presentation"
  //       onKeyDown={toggleResourceDrawer(false)}
  //     >
  //       <Box sx={{ margin: 30, width: '90%' }}>
  //         <h4>Related Works</h4>

  //         {relatedWorks.map(RelatedWorkDetailSection)}
          
  //       </Box>
  //     </div>
  //   )
  // }

  // const RelatedWorkDetailSection = (relatedWork) => {
  //   let hyperlink = `/tool/browse/related_work?type=search&q=${relatedWork.title}`
  //   return (
  //     <Grid 
  //       container
  //       direction="column"
  //       alignItems="baseline"
  //     >
  //       <Link
  //         style={{'fontSize' : '18px', 'fontWeight' : '700'}}
  //         href={hyperlink}
  //         target="_blank"
  //         variant="h6"
  //         color='textPrimary'
  //       >
  //         {relatedWork.title}
  //       </Link>

  //       <TypographyHolder 
  //         style={{
  //           'marginRight': 5, 
  //           'marginTop' : '10px', 
  //           'fontWeight' : 700}} 
  //         titleText={'Description:'}
  //       />
  //       <HighlightRowText 
  //         bodyText={relatedWork.description}
  //       />
  //       <br></br>
  //     </Grid>
  //   )
  // }

  const RelatedProposalsPage = () => {

    return (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onKeyDown={toggleResourceDrawer(false)}
      >
        <Box sx={{ margin: 30, width: '90%' }}>
          <h4>Related Proposals</h4>

          {relatedProposals.map(RelatedProposalsDetailSection)}
          
        </Box>
      </div>
    )
  }

  const RelatedProposalsDetailSection = (relatedProposal) => {
    // let hyperlink = `/tool/browse/related_work?type=search&q=${relatedProposal.title}`
    let hyperlink = ''

    return (
      <Grid 
        container
        direction="column"
        alignItems="baseline"
      >
        <Link
          style={{'fontSize' : '18px', 'fontWeight' : '700'}}
          href={hyperlink}
          target="_blank"
          variant="h6"
          color='textPrimary'
        >
          {relatedProposal.title}
        </Link>

        <TypographyHolder 
          style={{
            'marginRight': 5, 
            'marginTop' : '10px', 
            'fontWeight' : 700}} 
          titleText={'Description:'}
        />
        <HighlightRowText 
          bodyText={relatedProposal.description}
        />
        <br></br>
      </Grid>
    )
  }

  const DetailPage = () => {
    return (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onKeyDown={toggleDrawer(false)}
      >
        <Box sx={{ margin: 30, width: '90%' }}>
          <HighlightDrawerText bodyText={prop.title}/>
          <br></br>

          <Grid 
            container
            direction="row"
            alignItems="baseline"
          >
            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '30px', 
                'fontWeight' : 700}} 
              titleText={'Department:'}
            />
            <HighlightRowText 
              bodyText={prop.organisation}
            />
          </Grid>

          <Grid 
            container
            direction="row"
            alignItems="baseline"
          >
            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '30px', 
                'fontWeight' : 700}} 
              titleText={'Proposal Lead:'}
            />
            <HighlightRowText 
              bodyText={prop.lead_name}
            />
          </Grid>

          <Grid 
            container
            direction="row"
            alignItems="baseline"
          >
            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : 5, 
                'fontWeight' : 700
                }} 
              titleText={'Solicitation:'}
            />
            <HighlightRowText 
              bodyText={prop.solicitation_id}
            />
          </Grid>

          <HighlightRowText bodyText={prop.description}/>

          <h4>Keywords:</h4>
          <HighlightRowText bodyText={prop.keywords}/>
          <br/>
        </Box>
      </div>
    )
  }

  const HighlightDrawerText = (props) => {
    const {bodyText} = props
    var keywords = searchQuery.split(',')
    
    keywords = keywords.map(keyword => {
      return keyword.trim()
    })

    return (
      <Highlighter
        style={{ whiteSpace: 'pre-line', fontWeight: '700'}}
        highlightClassName="textHighlight"
        searchWords={keywords}
        autoEscape={true}
        textToHighlight={bodyText}
      />
    )
  }

  const HighlightRowText = (props) => {
    const {bodyText} = props
    var keywords = searchQuery.split(',')
    
    keywords = keywords.map(keyword => {
      return keyword.trim()
    })

    return (
      <Highlighter
        style={{ whiteSpace: 'pre-line'}}
        highlightClassName="textHighlight"
        searchWords={keywords}
        autoEscape={true}
        textToHighlight={bodyText}
      />
    )
  }

  const handleDownloadProposalClick = () => {
    let fileData = {}

    prop.file_keys.forEach((file_key) => {
      let date = new Date()

      let fileKeySplitArray = file_key.split('/')
      let file_name = fileKeySplitArray.at(-1)
      
      fileData[file_name] = date.toLocaleDateString()
    })

    let data = {
      'pid' : prop.pid,
      'title' : prop.title,
      'email' : prop.lead_email,
      'file_names' : fileData,
    }

    downloadProposal(data, true)
  }

  return (
    <React.Fragment>
      <TableRow hover tabIndex={-1} key={key} className={classes.tableBodyRow}>
        <TableCell>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.pt}/>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} width={'300px'} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.title}/>
        </TableCell>
        
        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.lead_name}/>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} width={'300px'} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.keywords}/>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} width={'100px'} onClick={toggleDrawer(true)}>
          {prop.due_date}
        </TableCell>

        <TableCell className={classes.tableCell} key={key} width={'100px'} onClick={toggleDrawer(true)}>
          {prop.last_updated}
        </TableCell>

        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          {prop.status}
        </TableCell>

        <TableCell className={classes.tableCell} align='justify' key={key} onClick={toggleResourceDrawer(true)}>

          <IconButton 
            aria-label="related-resources"
          >
            <div style={{'fontSize' : '18px', 'paddingRight' : '10px'}}>
              {prop.related_proposal_count > 0 ? prop.related_proposal_count : null}
            </div>
            <RelatedResourceIcon />
          </IconButton>
          
        </TableCell>

        <TableCell className={classes.tableCell} key='Download'>

        <IconButton 
          aria-owns={menuOpen ? 'simple-menu' : null}
          aria-haspopup="true"
          aria-label="download" 
          onMouseOver={handleMouseOver}
          onClick={handleDownloadProposalClick}
        >
          <DownloadIcon />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen}
          MenuListProps={{ onMouseLeave: handleCloseMenu }}
        >
          {prop.file_keys.map(FileItem)}
          
          <MenuItem onClick={handleDownloadProposalClick}>Download Files</MenuItem>
        </Menu>

        </TableCell>
      </TableRow>

      <Drawer anchor={'right'} open={drawerOpen} onClose={toggleDrawer(false)}>
        <DetailPage/>
      </Drawer>

      <Drawer anchor={'right'} open={resourceDrawerOpen} onClose={toggleResourceDrawer(false)}>
        <RelatedProposalsPage/>
      </Drawer>
    </React.Fragment>
  );
}

export default function ProposalTable(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setLoading] = useState(false)

  var {headCells, tableData, totalCount, lastPageIndex, fetchData, user, searchQuery} = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    
    logFirebaseEvent(`Browse -> Proposals -> Sort ${property} Tapped`)
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)

    if (lastPageIndex === -1) {
      return
    }

    if (newPage > lastPageIndex) {
      fetchData(newPage)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function openFile(patchParams) {
    setLoading(true)

    let email = patchParams['email']
    let file_key = patchParams['file_key']

    let s3Key = `${email}/${file_key}`

    let params = {
      Bucket: 'oceanit-proposals',
      Key: s3Key,
      Expires: 60 * 10
    }

    const url = s3.getSignedUrl('getObject', params)

    setLoading(false)
    window.open(url, '_blank')

    checkUserLimit(patchParams)
  }

  async function checkUserLimit(patchParams, download=false) {
    if (download) {
      setLoading(true)
    }

    let email = patchParams['email']
    let proposalID = patchParams['pid']

    delete patchParams['file_key']
    delete patchParams['email']

    fetch(`${base_url}v1/login/?id=${user.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patchParams)
    })
    .then(response => {
      response.json()
      .then(responseJson => {

        if (!response.ok) {
          setLoading(false)
          alert(responseJson.error.message)
        } 
        else {
          const s3Key = `${email}/${proposalID}/`

          if (download) {
            downloadProposal(s3Key, proposalID)
          }
        }
      })
    })
  }

  async function downloadProposal(s3Key, proposalID) {

    var fileArray = []

    const params = {
      Bucket: 'oceanit-proposals',
      Delimiter: '/',
      Prefix: s3Key,
    }

    let objectData = await s3.listObjectsV2(params).promise()
    let contents = objectData.Contents
    
    for (let i = 0; i < contents.length; i++) {
      let keyName = contents[i].Key

      let params = {
        Bucket: 'oceanit-proposals',
        Key: keyName
      }

      let url = await s3.getSignedUrlPromise('getObject', params)
      let fileData = await fetch(url)

      fileArray.push(fileData)
    }

    downloadZip(fileArray).blob()
    .then(data => {
      setLoading(false)
      logFirebaseEvent(`Browse -> Proposals -> Proposal ${proposalID} Downloaded`)

      const hiddenElement = document.createElement("a")
      hiddenElement.href = URL.createObjectURL(data)

      hiddenElement.download = `${proposalID}.zip`
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Backdrop
            style={{'zIndex' : '3500'}}
            className={classes.backdrop}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <Row 
                      prop={row} 
                      searchQuery={searchQuery} 
                      key={index} 
                      user={user} 
                      downloadProposal={checkUserLimit} 
                      openFile={openFile} 
                      classes={classes}
                      setLoading={setLoading}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
