import React from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import { CircularProgress, Backdrop } from "@material-ui/core";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import AWS from 'aws-sdk'
import { downloadZip } from "client-zip/index.js"
import Highlighter from "react-highlight-words";

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

const base_url = process.env.REACT_APP_BASE_URL;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

const s3 = new AWS.S3({
  params: { Bucket: 'oceanit-related-work'},
  region: 'us-gov-east-1',
})

const useStyles = makeStyles((theme) => ({
  list: {
    width: 500,
  }
}));

function Row(props) {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const {prop, key, searchQuery, downloadRelatedWork, classes} = props

  const toggleDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    logFirebaseEvent(`Browse -> RelatedWork -> Title ${prop.title} Tapped`)
    setDrawerOpen(openDrawer)
  };

  const TypographyHolder = (props) => {
    const {titleText, style} = props;
    return (
      <Typography 
        variant="body1"
        style={style} 
      >
        {titleText}
      </Typography>
    )
  }

  const DetailPage = () => {
    return (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onKeyDown={toggleDrawer(false)}
      >
        <Box sx={{ margin: 30, width: '90%' }}>
          <HighlightDrawerText bodyText={prop.title}/>
          <br></br>

          <Grid 
            container
            direction="row"
            alignItems="baseline"
          >
            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '30px', 
                'fontWeight' : 700}} 
              titleText={'Department:'}
            />
            <HighlightRowText 
              bodyText={prop.organisation}
            />
          </Grid>

          <Grid 
            container
            direction="row"
            alignItems="baseline"
          >
            <TypographyHolder 
                style={{
                  'marginRight': 5, 
                  'marginTop' : '10px', 
                  'fontWeight' : 700}} 
                titleText={'Agency:'}
              />
              <HighlightRowText 
                bodyText={prop.agency}
              />
          </Grid>

          <Grid 
            container
            direction="row"
            alignItems="baseline"
          >
            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : 30, 
                'fontWeight' : 700
                }} 
              titleText={'Field:'}
            />
            <HighlightRowText 
              bodyText={prop.field.toString()}
            />
          </Grid>

          <Grid 
            container
            direction="row"
            alignItems="baseline"
          >
            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '30px', 
                'fontWeight' : 700}} 
              titleText={'TPOC Name:'}
            />
            <HighlightRowText 
              bodyText={prop.tpoc_name}
            />
          </Grid>

          <HighlightRowText bodyText={prop.related_work_text}/>

          <h4>Keywords:</h4>
          <HighlightRowText bodyText={prop.keywords.toString()}/>
          <br/>
          
        </Box>
      </div>
    )
  }

  const HighlightDrawerText = (props) => {
    const {bodyText} = props
    var keywords = searchQuery.split(',')
    
    keywords = keywords.map(keyword => {
      return keyword.trim()
    })

    return (
      <Highlighter
        style={{ whiteSpace: 'pre-line', fontWeight: '700'}}
        highlightClassName="textHighlight"
        searchWords={keywords}
        autoEscape={true}
        textToHighlight={bodyText}
      />
    )
  }

  const HighlightRowText = (props) => {
    const {bodyText} = props
    var keywords = searchQuery.split(',')
    
    keywords = keywords.map(keyword => {
      return keyword.trim()
    })

    return (
      <Highlighter
        style={{ whiteSpace: 'pre-line'}}
        highlightClassName="textHighlight"
        searchWords={keywords}
        autoEscape={true}
        textToHighlight={bodyText}
      />
    )
  }

  return (
    <React.Fragment>
      <TableRow  hover onClick={toggleDrawer(true)} tabIndex={-1} key={key} className={classes.tableBodyRow}>

          <TableCell className={classes.tableCell}>
            <HighlightRowText bodyText={prop.title}/>
          </TableCell>

          <TableCell className={classes.tableCell}>
            <HighlightRowText bodyText={prop.organisation}/>
          </TableCell>

          <TableCell className={classes.tableCell}>
            <HighlightRowText bodyText={prop.field.toString()}/>
          </TableCell>

          <TableCell className={classes.tableCell}>
            <HighlightRowText bodyText={prop.tpoc_name}/>
          </TableCell>

          <TableCell className={classes.tableCell}>
            <HighlightRowText bodyText={prop.keywords.toString()}/>
          </TableCell>

        <TableCell className={classes.tableCell} key='Download'>

        <IconButton aria-label="download" onClick={() => {downloadRelatedWork(prop.s3_key_id, prop.title)}}>
          <DownloadIcon />
        </IconButton>

        </TableCell>
      </TableRow>

      <Drawer anchor={'right'} open={drawerOpen} onClose={toggleDrawer(false)}>
        <DetailPage/>
      </Drawer>

    </React.Fragment>
  );
}

export default function RelatedWorkTable(props) {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false)
  const { tableHeaderColor, searchQuery, tableHead, tableData } = props;

  async function downloadRelatedWork(fileKey, relatedWorkName) {
    setLoading(true)

    var fileArray = []
    const params = {
      Bucket: 'oceanit-related-work',
      Delimiter: '/',
      Prefix: fileKey,
    }

    let objectData = await s3.listObjectsV2(params).promise()
    let contents = objectData.Contents
    
    for (let i = 0; i < contents.length; i++) {
      let params = {
        Bucket: 'oceanit-related-work',
        Key: contents[i].Key
      }
      let url = await s3.getSignedUrlPromise('getObject', params)
      let fileData = await fetch(url)
      fileArray.push(fileData)
    }

    downloadZip(fileArray).blob()
    .then(data => {
      setLoading(false)
      logFirebaseEvent(`Browse -> Related Work -> Downloaded Related Work ${relatedWorkName}`)

      const hiddenElement = document.createElement("a")
      hiddenElement.href = URL.createObjectURL(data)

      hiddenElement.download = `${relatedWorkName}.zip`
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  return (
    <div className={classes.tableResponsive}>
      <Backdrop
        style={{'zIndex' : '3500'}}
        className={classes.backdrop}
        open={isLoading}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <Row 
                prop={prop} 
                key={key} 
                searchQuery={searchQuery}
                classes={classes} 
                downloadRelatedWork={downloadRelatedWork} 
              />
            )
          })}
        </TableBody>
      </Table>
    </div>
  );
}


RelatedWorkTable.defaultProps = {
  tableHeaderColor: "gray",
};

RelatedWorkTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
