import React from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles"
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

import {  
  FormControl, 
  FormControlLabel, 
  IconButton, 
  ListItem, 
  ListItemText,
  Paper,
  RadioGroup
 } from "@material-ui/core"

import CheckedBox from "@material-ui/icons/CheckBoxOutlined";
import UnCheckedBox from "@material-ui/icons/CheckBoxOutlineBlankSharp";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import SearchBar from "../TableList/SearchBar";

const useStyles = makeStyles({
    root: {
        width: '50%',
        flex: '1',
        margin: '0 auto',
        justifyContent: 'center',
    },
    timeline: {
        minWidth: '220px',
        alignSelf: 'center',
        marginTop: '-100px',
        marginLeft: '-35px',
        transform: "rotate(90deg)"
    },
    timelineContentContainer: {
        textAlign: "left"
    },
    timelineContent: {
        display: "inline-block",
        transform: "rotate(-90deg)",
        textAlign: "center",
        minWidth: 50
    },
    timelineIcon: {
        transform: "rotate(-90deg)"
    },
    statusText:{
        fontSize: '14px',
        marginTop: '-7px'
    },
    titleText: {
        fontWeight: '900'
    },
    organisationText: {
        marginTop: '-4px'
    },
    listItem: {
        display : 'flex', 
        flexDirection : 'column', 
        paddingTop : '20px', 
        paddingLeft : '30px', 
        paddingRight : '30px'
    },
    checklistDiv: {
        width: '100%',
        display : 'flex',
        flexDirection : 'row',
        // paddingRight: '10px',
        // paddingLeft: '10px',
        // paddingBottom: '20px',
    },
    checklistLeft: {
        alignSelf: 'flex-start'
    }
})

const base_url = process.env.REACT_APP_BASE_URL;

export default function UserProposals() {
    const history = useHistory()
    const classes = useStyles()
    const currentUser = checkUser(history)  

    const [loading, setLoading] = React.useState(false)

    const [userProposals, setUserProposals] = React.useState([])
    const [allUserProposals, setAllUserProposals] = React.useState([])

    React.useEffect(() => {
        if (currentUser !== undefined) {
            fireFetchDataAPI()
        }
    }, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
            pathname: '/signin',
            state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            const accountJSON = JSON.parse(accountDetails)
            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function fireFetchDataAPI() {
        setLoading(true)

        fetch(`${base_url}v1/proposal/member/?id=${currentUser.id}`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then(response => {
            setLoading(false)

            response.json()
            .then(responseJson => {

                if (!response.ok) {
                    alert(responseJson.error.message)
                } else {
                    setUserProposals(responseJson.data.data)
                    setAllUserProposals(responseJson.data.data)
                }
            })
        })
    }

    function chunkArray(arr,n){
        var chunkLength = Math.max(arr.length/n ,1);
        var chunks = [];
        for (var i = 0; i < n; i++) {
            if(chunkLength*(i+1)<=arr.length)chunks.push(arr.slice(chunkLength*i, chunkLength*(i+1)));
        }
        return chunks; 
    }

    function ChecklistComponent(props) {
        const {data} = props
        let dataSet = [[], []]

        if (data.length > 5) {
            dataSet = chunkArray(data, 2)
        }
        else {
            dataSet[0] = data
        }

        return (
            <div className={classes.checklistDiv}>
                <ListItem className={classes.listItem} alignItems="flex-start">
                    {dataSet[0].map((value) => {
                        return (
                            <ListItemText
                                primary={value}
                            />
                        )
                    })}
                </ListItem>

                <ListItem className={classes.listItem} alignItems="flex-end" >
                    {dataSet[1].map((value) => {
                        return (
                            <ListItemText
                                primary={value}
                            />
                        )
                    })}
                </ListItem>
            </div>
        )
    }

    function Feed(props) {
        const {proposal} = props

        function fireFetchProposalAPI(pid, pathname) {
            fetch(`${base_url}v1/proposal/?id=${currentUser.id}&pid=${pid}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            .then(response => {
                response.json()
                .then(responseJson => {

                    setLoading(false)

                    if (!response.ok) {
                        alert(responseJson.error.message)
                    } 
                    else {
                        let proposals = responseJson.data.data

                        history.push({
                            pathname: pathname,
                            state: {
                            proposals: proposals
                            },
                        })
                    }
                })
            })
        }

        function fireUpdateProposalAPI(pid, pathname) {
            let proposal = {
                'proposal' : pid,
                'status' : 'PENDING'
            }

            fetch(`${base_url}v1/proposal/?id=${currentUser.id}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(proposal),
              })
            .then(response => {
                response.json()
                .then(responseJson => {

                    setLoading(false)

                    if (!response.ok) {
                        alert(responseJson.error.message)
                    } 
                    else {
                        let proposal = responseJson.data.data

                        history.push({
                            pathname: pathname,
                            state: {
                            proposals: [proposal]
                            },
                        })
                    }
                })
            })
        }

        function fireDeleteProposalAPI(pid) {
            fetch(`${base_url}v1/proposal/?id=${currentUser.id}&pid=${pid}`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            .then(response => {
                response.json()
                .then(responseJson => {

                    setLoading(false)

                    if (!response.ok) {
                        alert(responseJson.error.message)
                    } 
                    else {
                        alert(responseJson.data.message)
                        fireFetchDataAPI()
                    }
                })
            })
        }

        function checkDeleteProposal(proposal) {
            let answer = window.confirm('Are you sure you want to delete this proposal?')

            if (answer) {
                fireDeleteProposalAPI(proposal.id)
            }
        }

        function checkUpdateProposal(proposal) {
            let status = proposal.status
            let pid = proposal.id

            if (status === 'Rejected by department' || status === 'Awarded') {
                let answer = window.confirm(`This proposal has already been ${status.toLowerCase()}. Do you want to continue?`)

                if (answer) {
                    updateProposalClicked(pid)
                }
            }

            else if (status === 'Submitted for internal review') {
                let answer = window.confirm(`This proposal is in review. Editing this will remove it from review process. Do you want to continue?`)

                if (answer) {
                    let pathname='/tool/proposal/'
                    fireUpdateProposalAPI(pid, pathname)
                }
            }
            else {
                updateProposalClicked(pid)
            }
        }

        function updateProposalClicked(pid, pathname='/tool/proposal/') {
            setLoading(true)
            fireFetchProposalAPI(pid, pathname)
        }

        return (
            <React.Fragment>
            <Paper style={{'marginTop' : '20px'}}>
            <ListItem className={classes.listItem} alignItems="flex-start">

                <ListItemText
                    classes={{primary: classes.titleText}}
                    style={{'textAlign' : 'flex-start'}}
                    primary={`${proposal.organisation}`}
                />
                <ListItemText
                    classes={{primary: classes.titleText}}
                    style={{'textAlign' : 'flex-start'}}
                    primary={`PT# : ${proposal.internal_id}`}
                />
                <ListItemText
                    classes={{primary: classes.titleText}}
                    style={{'textAlign' : 'flex-start'}}
                    primary={`Title : ${proposal.title}`}
                />
                <ListItemText
                    classes={{primary: classes.titleText}}
                    style={{'textAlign' : 'flex-start'}}
                    primary={`Solicitation ID : ${proposal.solicitation_id}`}
                />
                <ListItemText
                    primary={`Status : ${proposal.status}`}
                />
                <ListItemText
                    primary={`Start Date: ${proposal.start_date}`}
                />
                <ListItemText
                    primary={`Due Date: ${proposal.due_date}`}
                />
                <ListItemText
                    primary={`Uploaded By: ${proposal.owner_full_name}`}
                />
                <ListItemText
                    primary={`Authors: ${proposal.author}`}
                />

                <IconButton
                    style={{'position' : 'absolute', 'right': 40, 'top':  5}}
                    aria-label="share"
                    size="small"
                    onClick={() => checkUpdateProposal(proposal)}
                >
                    <EditIcon/>
                </IconButton>

                <IconButton
                    style={{'position' : 'absolute', 'right': 5, 'top':  5}}
                    aria-label="share"
                    size="small"
                    onClick={() => checkDeleteProposal(proposal)}
                >
                    <DeleteIcon/>
                </IconButton>

                <ListItemText
                    primary={'Files Uploaded:'}
                />
                <ChecklistComponent data={proposal.files} />

            </ListItem>
            </Paper>
            </React.Fragment>
        )
    }

    function checkKeywordEmpty(keyword) {
        if (keyword.length === 0) {
          setUserProposals(allUserProposals)
        }
    }

    function searchKeyword(keyword) {
        if (keyword.length === 0 || !keyword.replace(/\s/g, '').length) {
            alert('Please enter a valid search.')
            return
        }
        
        const proposals = allUserProposals
        const searchKey = keyword.toLowerCase()

        setUserProposals([])

        const filterTitleResults = []
        const filterProposalIDResults = []
        const filterInternalIDResults = []
        const filterAuthorResults = []
        const filterAgencyResults = []

        for (let i = 0; i < proposals.length; i++) {
            let proposal = proposals[i]
            
            // Putting this at top for Micah, might push this to bottom later
            let agency = proposal.organisation.toLowerCase()

            if (agency.includes(searchKey)) {
                if (!filterAgencyResults.includes(proposal)) {
                    filterAgencyResults.push(proposal)
                }
            }

            let topicTitle = proposal.title.toLowerCase()

            if (topicTitle.includes(searchKey)) {
                if (!filterTitleResults.includes(proposal)) {
                    filterTitleResults.push(proposal)
                }
            }

            let proposalID = proposal.solicitation_id.toLowerCase()

            if (proposalID.includes(searchKey)) {
                if (!filterProposalIDResults.includes(proposal)) {
                    filterProposalIDResults.push(proposal)
                }
            }

            let internalID = proposal.internal_id.toLowerCase()

            if (internalID.includes(searchKey)) {
                if (!filterInternalIDResults.includes(proposal)) {
                    filterInternalIDResults.push(proposal)
                }
            }

            let authors = proposal.author.toLowerCase()

            if (authors.includes(searchKey)) {
                if(!filterAuthorResults.includes(proposal)) {
                    filterAuthorResults.push(proposal)
                }
            }
        }

        var finalResults = filterAgencyResults
                           .concat(filterTitleResults)
                           .concat(filterProposalIDResults)
                           .concat(filterInternalIDResults)
                           .concat(filterAuthorResults)

        finalResults = finalResults.filter(function(item, pos) {
            return finalResults.indexOf(item) === pos;
        })
        
        if (finalResults.length === 0) {
            finalResults = proposals
            alert('No results found for this search.')
        }
        setUserProposals(finalResults)
    }

    function MainFeedComponent() {
        return (
            <React.Fragment>
            {/* <h2>My Proposals</h2> */}

            {userProposals.map((proposal, index) => {
                return (
                <Feed
                    proposal={proposal} 
                />
                )
            })}

            </React.Fragment>
        )
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) ===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    } 

    return (
        <div className={classes.root}>
            <Fade
            in={loading}
            style={{
                transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
            >
                <CircularProgress />
            </Fade>

            <h2>My Proposals</h2>

            <SearchBar
                style={{
                    maxWidth: 1000
                }}
                placeholder="Search"
                onRequestSearch={(keyword) => searchKeyword(keyword)}
                onCancelSearch={() => setUserProposals(allUserProposals)}
                onChange={(keyword) => checkKeywordEmpty(keyword) }
            />

            <MainFeedComponent />
            
        </div>
    );
}
