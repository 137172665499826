/* eslint-disable no-loop-func */
import React, { useState, useRef } from "react";
import { useHistory } from 'react-router-dom'

import {
    makeStyles,
    Button,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextField,
    Checkbox,
    IconButton,
    
} from "@material-ui/core"

import {
    InputLabel,
    MenuItem,
    Select,
    CircularProgress,
    Backdrop
} from '@mui/material'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"

import { Upload } from 'antd'
import AWS from 'aws-sdk'
import mammoth from 'mammoth'
import JSZip from "jszip"

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CheckBox, SearchOutlined } from "@material-ui/icons";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import { Autocomplete } from "@material-ui/lab";
import { authors } from "../../assets/extras/constants"


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  })

const s3 = new AWS.S3({
  params: { Bucket: 'oceanit-proposals'},
  region: 'us-gov-east-1',
})

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '200px',
    margin: theme.spacing(3, 0, 2),
  },

  option: {
    width: '400px',
    margin: theme.spacing(3, 0, 2),
  },
}))

const base_url = process.env.REACT_APP_BASE_URL;
const oceanit_base_url = process.env.REACT_APP_OCEANIT_BASE_URL
const oceanit_api_key = process.env.REACT_APP_OCEANIT_API_KEY

export default function HistoricProposal() {
    var accountJSON = {}

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const currentUser = checkUser(history)

    const [fileRefs, setFileRefs] = useState([
        useRef(null), 
        useRef(null), 
        useRef(null), 
        useRef(null), 
        useRef(null), 
        useRef(null),
        useRef(null), 
        useRef(null), 
        useRef(null), 
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ])

    const [defaultFileLists, setDefaultFileLists] = useState([
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
    ])

    const [textRefs, setTextRefs] = useState([
        useRef(null), 
        useRef(null), 
        useRef(null), 
        useRef(null), 
        useRef(null)
    ])

    const [formRefs, setFormRefs] = useState([
        useRef(null), 
        useRef(null), 
        useRef(null), 
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ])

    const [fileNames, setFileNames] = useState([])
    const [fileTypes, setFileTypes] = useState([])

    const [proposalResponse, setProposalResponse] = useState({})

    const [clientFiles, setClientFiles] = useState({})
    const [s3Files, setS3Files] = useState({})

    const [textFieldsData, setTextFieldsData] = useState({})
    const [textFieldNames, setTextFieldNames] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [isInitial, setIsInitial] = useState(true)

    const [isFileUpload, setIsFileUpload] = useState(false)

    const [updatingProposal, setUpdatingProposal] = useState(false)
    const [confirmProposal, setConfirmProposal] = useState(false)

    const [department, setDepartment] = useState('Select Department')
    var [grant, setGrant] = useState('Select Grant')
    var [agency, setAgency] = useState('Select Agency')

    const [grantString, setGrantString] = useState('')
    
    var [proposalStatus, setProposalStatus] = useState('UNDER EVALUATION')
    var [proposalSensitiveChecked, setProposalSensitiveChecked] = useState(false)

    const [showAgencyItems, setShowAgencyItems] = useState(false)

    var [authorNames, setAuthorNames] = useState([])

    var [departmentItemValues] = useState([
        <MenuItem value={'Select Department'}>Select Department</MenuItem>,

        <MenuItem value={'BARDA'}>BARDA</MenuItem>,
        <MenuItem value={'CDMRP'}>CDMRP</MenuItem>,

        <MenuItem value={'DHP'}>DHP</MenuItem>,
        <MenuItem value={'DOD'}>DOD</MenuItem>,
        <MenuItem value={'DOE'}>DOE</MenuItem>,
        <MenuItem value={'DOH'}>DOH</MenuItem>,
        <MenuItem value={'DOT'}>DOT</MenuItem>,

        <MenuItem value={'EBRAP'}>EBRAP</MenuItem>,
        <MenuItem value={'EPA'}>EPA</MenuItem>,
        
        <MenuItem value={'HTDC'}>HTDC</MenuItem>,
        <MenuItem value={'INIT'}>INIT</MenuItem>,

        <MenuItem value={'NASA'}>NASA</MenuItem>,
        <MenuItem value={'NIH'}>NIH</MenuItem>,
        <MenuItem value={'NIST'}>NIST</MenuItem>,
        <MenuItem value={'NOAA'}>NOAA</MenuItem>,
        <MenuItem value={'NSF'}>NSF</MenuItem>,

        <MenuItem value={'PRIVATE_FIRM'}>PRIVATE FIRM</MenuItem>,
        <MenuItem value={'TAQA'}>TAQA</MenuItem>,
        <MenuItem value={'USDA'}>USDA</MenuItem>
    ])

    var [grantItemValues, setGrantItemValues] = useState([
        <MenuItem value={'Select Grant'}>Select Grant</MenuItem>,

        <MenuItem value={'SBIR_PH_1'}>SBIR Phase I</MenuItem>,
        <MenuItem value={'SBIR_PH_2'}>SBIR Phase II</MenuItem>,

        <MenuItem value={'STTR_PH_1'}>STTR Phase I</MenuItem>,
        <MenuItem value={'STTR_PH_2'}>STTR Phase II</MenuItem>,

        <MenuItem value={'BAA'}>BAA</MenuItem>,
        <MenuItem value={'RFP'}>RFP</MenuItem>,

        <MenuItem value={'OTHER'}>OTHER</MenuItem>,
    ])

    const [agencyItemValues] = useState([
        <MenuItem value={'Select Agency'}>Select Agency</MenuItem>,

        <MenuItem value={'AFOSR'}>AFOSR</MenuItem>,
        <MenuItem value={'AFRL'}>AFRL</MenuItem>,
        <MenuItem value={'AIR_FORCE'}>AIR FORCE</MenuItem>,
        <MenuItem value={'ARMY'}>ARMY</MenuItem>,
        <MenuItem value={'ARPA'}>ARPA</MenuItem>,

        <MenuItem value={'CBD'}>CBD</MenuItem>,

        <MenuItem value={'DARPA'}>DARPA</MenuItem>,
        <MenuItem value={'DHA'}>DHA</MenuItem>,
        <MenuItem value={'DHMRP'}>DHMRP</MenuItem>,
        <MenuItem value={'DHS'}>DHS</MenuItem>,
        <MenuItem value={'DISA'}>DISA</MenuItem>,
        <MenuItem value={'DTRA'}>DTRA</MenuItem>,

        <MenuItem value={'MDA'}>MDA</MenuItem>,

        <MenuItem value={'NAVAIR'}>NAVAIR</MenuItem>,
        <MenuItem value={'NAVSEA'}>NAVSEA</MenuItem>,
        <MenuItem value={'NAVWAR'}>NAVWAR</MenuItem>,
        <MenuItem value={'NAVY'}>NAVY</MenuItem>,
        <MenuItem value={'NGA'}>NGA</MenuItem>,
        <MenuItem value={'NSA'}>NSA</MenuItem>,

        <MenuItem value={'ONR'}>ONR</MenuItem>,        
        <MenuItem value={'OSD'}>OSD</MenuItem>,
        <MenuItem value={'OTHER'}>OTHER</MenuItem>,

        <MenuItem value={'PACOM'}>PACOM</MenuItem>,
        <MenuItem value={'SOCOM'}>SOCOM</MenuItem>,
        <MenuItem value={'USAF'}>USAF</MenuItem>,
    ])

    const [dueDate, setDueDate] = useState(new Date())
    const [currencyValue, setCurrencyValue] = useState(0.0)

    React.useEffect(() => {
        if (currentUser !== undefined) {
            checkForProposalUpdate()
        }
    }, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
                pathname: '/signin',
                state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            accountJSON = JSON.parse(accountDetails)

            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function checkForProposalUpdate() {
        if (accountJSON.role === 'GUEST') {
            alert('Your user access does not allow uploading historic proposals. Please contact admin for further details.')
            history.push('/tool/user/')
        }

        if (location.state !== null) {

            let proposals = location.state.proposals
            
            setUpdatingProposal(true)
            prepareProposalForUpdate(proposals[0])
        }
    }

    const prepareProposalForUpdate = (proposal) => {
        proposal.department = proposal.organisation
        proposal.client_grant = proposal.grant

        setProposalResponse(proposal)

        setS3Files(proposal.files)
        setClientFiles(proposal.client_files)

        let dueDate = new Date(proposal.due_date)
        setDueDate(dueDate)

        setTimeout(() => {
            restoreFormRefsValue(proposal)
        }, 100);
    }

    function mapBackendGrantToClient(grant) {
        let grantMappings = {
            'SBIR_PH_1' : 'SBIR Phase I',
            'SBIR_PH_2' : 'SBIR Phase II',

            'STTR_PH_1' : 'STTR Phase I',
            'STTR_PH_2' : 'STTR Phase II',

            'BAA' : 'BAA',
            'RFP' : 'RFP',
            'OTHER' : 'OTHER',
        }

        return grantMappings[grant]
    } 

    function checkGrantDepartmentType(department, grant) {
        var fileNames = []
        var fileTypes = []
        var textFieldNames = []

        if (department === 'DOE') {
            
            if (grant === 'SBIR_PH_1') {
                fileNames = [
                    'Project Narrative (Required)',
                    'Project Summary (Optional)',
                    'Commercialization Plan (Optional)',
                    'Level of Effort Worksheet (Optional)',

                    'Biographical Sketch Per Person/Team (Optional)',
                    
                    'Budget (Optional)',
                    'Budget Justification (Optional)',
                    'Letters of Support (Optional)',
                    'Subaward Budget (Optional)',
                    'Subaward Budget Justification (Optional)',
                    'Reference Papers (Optional)',
                    'Miscellaneous (Optional)',

                    'Feedback (Optional)',
                    'Solicitation (Optional)'
                ]

                fileTypes = [
                    '.pdf, .docx',
                    '.pdf',
                    '.pdf',
                    '.pdf,',

                    '.pdf, .zip',

                    '.xls',
                    '.xls, .pdf', 
                    '.pdf',
                    '.xls, .pdf',
                    '.xls, .pdf',
                    '.pdf, .docx',
                    '.pdf, .docx',

                    '.pdf, .docx',
                    '.pdf, .docx'
                ]

                textFieldNames = [
                    'Abstract',
                    'Benefits',
                    'Keywords',
                ]
            }

            else {
                // It's DOE SBIR_PH_2
                fileNames = [
                    'Project Narrative (Required)',
                    'Project Summary (Optional)',
                    'Commercialization Plan (Optional)',
                    'Data Management Plan (Optional)',
                    'Phase 1 Technical Report (Optional)',
                    'Letter of Intent (Optional)',
                    'Letters of Commitment (Optional)',
                    'Level of Effort Worksheet (Optional)',

                    'Biographical Sketch Per Person/Team (Optional)',
                    'Current & Pending Per Person/Team (Optional)',
                    
                    'Budget (Optional)',
                    'Budget Justification (Optional)',
                    'Letters of Support (Optional)',
                    'Reference Papers (Optional)',
                    'Miscellaneous (Optional)',

                    'Feedback (Optional)',
                    'Solicitation (Optional)'
                ]

                fileTypes = [
                    '.pdf, .docx',
                    '.pdf',
                    '.pdf',
                    '.pdf',
                    '.pdf',
                    '.pdf',
                    '.pdf',
                    '.pdf',

                    '.pdf, .zip',
                    '.pdf, .zip',

                    '.xls',
                    '.xls, .pdf', 
                    '.pdf', 
                    '.pdf, .docx',
                    '.pdf, .docx',

                    '.pdf, .docx',
                    '.pdf, .docx'
                ]

                textFieldNames = [
                    'Abstract',
                    'Benefits',
                    'Keywords',
                ]
            }
        }
        else {
            // It;s everything else remaining -  DoD, DOH, HTDC etc.
            if (grant === 'SBIR_PH_1') {
                fileNames = [
                    'Narrative (Required)',
                    'Abstract, Benefits and Keywords (Optional)',

                    'Budget (Optional)',
                    'Budget - Material Info (Optional)',
                    'Budget - Sub Info (Optional)',

                    'Letters of Support (Optional)',
                    'Reference Papers (Optional)',
                    'Miscellaneous (Optional)',
                    
                    'Feedback (Optional)',
                    'Solicitation (Optional)'
                ]

                textFieldNames = [
                    'Abstract',
                    'Benefits',
                    'Keywords'
                ]

                fileTypes = [
                    '.pdf, .docx',
                    '.docx',

                    '.xls',
                    '.docx, .xls, .pdf',
                    '.docx, .xls, .pdf',

                    '.pdf',
                    '.pdf, .docx',
                    '.pdf, .docx',

                    '.pdf, .docx',
                    '.pdf, .docx'
                ]
            }
            else if (grant === 'SBIR_PH_2') {
                fileNames = [
                    'Narrative (Required)',
                    'Abstract, Benefits and Keywords (Optional)',
                    
                    'Budget (Optional)',
                    'Budget - Material Info (Optional)',
                    'Budget - Sub Info (Optional)',

                    'Letters of Support (Optional)',
                    'Reference Papers (Optional)',
                    'Miscellaneous (Optional)',

                    'Feedback (Optional)',
                    'Solicitation (Optional)'
                ]

                textFieldNames = [
                    'Abstract',
                    'Benefits',
                    'Keywords'
                ]

                fileTypes = [
                    '.pdf, .docx',
                    '.docx',

                    '.xls',
                    '.docx, .xls, .pdf',
                    '.docx, .xls, .pdf',

                    '.pdf',
                    '.pdf, .docx',
                    '.pdf, .docx',

                    '.pdf, .docx',
                    '.pdf, .docx'
                ]    
            }
            else if (grant === 'STTR_PH_1') {
                fileNames = [
                    'Narrative (Required)',
                    'Letter of Intent/Support (Optional)',
                    'Abstract, Benefits and Keywords (Optional)',

                    'Budget (Optional)',
                    'Budget - Material Info (Optional)',
                    'Budget - Sub Info (Optional)',

                    'Reference Papers (Optional)',
                    'Miscellaneous (Optional)',

                    'Feedback (Optional)',
                    'Solicitation (Optional)'
                    ]
                textFieldNames = [
                    'Abstract',
                    'Benefits',
                    'Keywords',
                    'Research Partner Info'
                ]

                fileTypes = [
                    '.pdf, .docx',
                    '.pdf',
                    '.docx',

                    '.xls',
                    '.doc, .xls, .pdf',
                    '.doc, .xls, .pdf',

                    '.pdf, .docx',
                    '.pdf, .docx',

                    '.pdf, .docx',
                    '.pdf, .docx'
                ]
            }
            // It's STTR_PH_2
            else {
                fileNames = [
                    'Narrative (Required)',
                    'Letter of Intent/Support (Optional)',
                    'Abstract, Benefits and Keywords (Optional)',

                    'Budget (Optional)',
                    'Budget - Material Info (Optional)',
                    'Budget - Sub Info (Optional)',

                    'Reference Papers (Optional)',
                    'Miscellaneous (Optional)',

                    'Feedback (Optional)',
                    'Solicitation (Optional)'
                ]

                textFieldNames = [
                    'Abstract',
                    'Benefits',
                    'Keywords',
                    'Research Partner Info'
                ]

                fileTypes = [
                    '.pdf, .docx',
                    '.pdf',
                    '.docx',
                    
                    '.xls',
                    '.doc, .xls, .pdf',
                    '.doc, .xls, .pdf',

                    '.pdf, .docx',
                    '.pdf, .docx',

                    '.pdf, .docx',
                    '.pdf, .docx'
                ]
            }
        }

        setFileNames(fileNames)
        setFileTypes(fileTypes)
        setTextFieldNames(textFieldNames)

        return fileNames
    }

    function displayFileUploadSection() {

        // if (updatingProposal) {
            
        // }
        // else {
        //     checkExisitingFiles(true)
        // }

        let fileNames = checkGrantDepartmentType(department, grant)
        setGrantString(mapBackendGrantToClient(grant))

        checkExisitingFiles(false, fileNames)

        setIsInitial(false)
        setIsFileUpload(true)

        setUpdatingProposal(true)

        setTimeout(() => {
            populateTextRefsFromTextFieldData()
        }, 100);
    }

    const handleDueDateChange = (date) => {

        if (!isNaN(date)) {
            let data = fetchFormRefsData()
            setDueDate(date)

            setTimeout(() => {
                restoreFormRefsValue(data, false)
            }, 300);
        }
    }

    const handleDepartmentChange = (event) => {
        var showAgency = false
        var data = fetchFormRefsData()

        let departmentValue = event.target.value
        setDepartment(departmentValue)

        if (departmentValue === 'DOD') {
            // Agency dropdown is only for DoD, for now.
            showAgency = true

            grantItemValues = [
                <MenuItem value={'Select Grant'}>Select Grant</MenuItem>,

                <MenuItem value={'SBIR_PH_1'}>SBIR Phase I</MenuItem>,
                <MenuItem value={'SBIR_PH_2'}>SBIR Phase II</MenuItem>,

                <MenuItem value={'STTR_PH_1'}>STTR Phase I</MenuItem>,
                <MenuItem value={'STTR_PH_2'}>STTR Phase II</MenuItem>,

                <MenuItem value={'BAA'}>BAA</MenuItem>,
                <MenuItem value={'RFP'}>RFP</MenuItem>,

                <MenuItem value={'OTHER'}>OTHER</MenuItem>
            ]
        }
        else if (departmentValue === 'DOE') {
            grantItemValues = [
                <MenuItem value={'Select Grant'}>Select Grant</MenuItem>,

                <MenuItem value={'SBIR_PH_1'}>SBIR Phase I</MenuItem>,
                <MenuItem value={'SBIR_PH_2'}>SBIR Phase II</MenuItem>,

                <MenuItem value={'BAA'}>BAA</MenuItem>,
                <MenuItem value={'RFP'}>RFP</MenuItem>,

                <MenuItem value={'OTHER'}>OTHER</MenuItem>
            ]
        }
        else if (departmentValue === 'PRIVATE_FIRM') {
            grantItemValues = [
                <MenuItem value={'Select Grant'}>Select Grant</MenuItem>,
                <MenuItem value={'OTHER'}>OTHER</MenuItem>,

                <MenuItem value={'BAA'}>BAA</MenuItem>,
                <MenuItem value={'RFP'}>RFP</MenuItem>,
            ]
        }
        else {
            grantItemValues = [
                <MenuItem value={'Select Grant'}>Select Grant</MenuItem>,

                <MenuItem value={'SBIR_PH_1'}>SBIR Phase I</MenuItem>,
                <MenuItem value={'SBIR_PH_2'}>SBIR Phase II</MenuItem>,

                <MenuItem value={'STTR_PH_1'}>STTR Phase I</MenuItem>,
                <MenuItem value={'STTR_PH_2'}>STTR Phase II</MenuItem>,

                <MenuItem value={'BAA'}>BAA</MenuItem>,
                <MenuItem value={'RFP'}>RFP</MenuItem>,

                <MenuItem value={'OTHER'}>OTHER</MenuItem>
            ]
        }

        setShowAgencyItems(showAgency)
        setGrantItemValues(grantItemValues)

        setTimeout(() => {
            data.department = departmentValue
            restoreFormRefsValue(data)
        }, 50);
    }

    const handleGrantChange = (event) => {
        grant = event.target.value
    }

    const handleAgencyChange = (event) => {
        agency = event.target.value
    }

    const handleProposalStatusChange = (event) => {
        proposalStatus = event.target.value
    }

    const handleProposalSensitiveChange = (event) => {
        getFormRefsValue(event)
    }

    const handleAuthorsNameChange = (event, value) => {
        authorNames = value.map(someValue => someValue.title)
    }

    const handleConfirmDetails = (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        checkFields(data)
    }

    function fetchFormRefsData() {
        let data = {
            'topic_id' : formRefs[0].current.value,
            'internal_proposal_id' : formRefs[1].current.value,
            'title' : formRefs[2].current.value, // Was conflicting with propsoalResponse

            'solicitation' : formRefs[3].current.value,
            'authors' : authorNames, // Was conflicting with propsoalResponse 
            'solicitation_id' : formRefs[5].current.value,

            'nickname' : formRefs[6].current.value, // Was conflicting with propsoalResponse
            'status' : proposalStatus, // Was conflicting with propsoalResponse
            
            'department' : department,
            'client_grant' : grant, // Was conflicting with propsoalResponse
            'agency' : agency,

            'los_company_name' : formRefs[10].current.value,
            'los_person_name' : formRefs[11].current.value,

            'is_sensitive' : proposalSensitiveChecked,
            'funding_amount' : currencyValue,
            'feedback' : formRefs[9].current.value,
        }
        return data
    }

    // Used before updating proposal sensitive check above.
    function getFormRefsValue(event) {

        let data = fetchFormRefsData()
        setProposalSensitiveChecked(event.target.checked)

        setTimeout(() => {
            restoreFormRefsValue(data, false)
        }, 100);
    }

    // Used whenever state changes on Enter Proposal Details section, to re fill the values.
    function restoreFormRefsValue(data, setSensitiveChecked=true) {
        let authorNames = checkAuthors(data.authors)

        setDepartment(data.department)
        setGrant(data.client_grant)

        setAgency(data.agency)
        setProposalStatus(data.status)

        setAuthorNames(authorNames)
        setCurrencyValue(data.funding_amount)
        
        if (setSensitiveChecked) {
            setProposalSensitiveChecked(data.is_sensitive)
        }

        formRefs[0].current.value = data.topic_id
        formRefs[1].current.value = data.internal_proposal_id
        formRefs[2].current.value = data.title

        formRefs[3].current.value = data.solicitation
        formRefs[5].current.value = data.solicitation_id

        formRefs[6].current.value = data.nickname

        formRefs[9].current.value = data.feedback
        formRefs[10].current.value = data.los_company_name
        formRefs[11].current.value = data.los_person_name
    }

    function checkFields(data) {
        let formRefsData = fetchFormRefsData()

        const title = data.get('topic_title')
        const id = data.get('solicitation_id')

        const internal_id = data.get('internal_proposal_id')

        if (department === 'Select Department') {
            alert('Please select a valid value for Department.')
            return
        }
        if (grant === 'Select Grant') {
            alert('Please select a valid value for Grant.')
            return
        }

        if (proposalStatus === 'Select Proposal Status') {
            alert('Please select a valid status for Proposal.')
            return
        }

        if (title.length === 0 || !title.replace(/\s/g, '').length) {

            if (id.length === 0 || !id.replace(/\s/g, '').length) {

                if (internal_id.length === 0 || !internal_id.replace(/\s/g, '').length) {
                    alert('Please enter valid details for Solicitation ID, Internal ID and Title')
                    return
                }
                alert('Please enter valid details for Solicitation ID and Title')
                return
            }

            alert('Please enter valid detail for Title')
            return
        }
        else if (id.length === 0 || !id.replace(/\s/g, '').length) {

            if (internal_id.length === 0 || !internal_id.replace(/\s/g, '').length) {
                alert('Please enter valid details for Solicitation ID and Internal ID')
                return
            }

            alert('Please enter valid detail for Solicitation ID')
            return
        }
        else if (internal_id.length === 0 || !internal_id.replace(/\s/g, '').length) {
            alert('Please enter valid detail for Internal ID')
            return
        }

        if (internal_id.length > 50) {
            alert('Internal Proposal ID is exceeding character limit of 50.')
            return
        }

        restoreFormRefsValue(formRefsData)

        if (!updatingProposal) {
            prepareDataForCreateProposalAPI(data)
        }
        else {
            prepareDataForUpdateProposalAPI(data)
        }
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-');
    }

    function checkAuthors(authors) {
        if (typeof(authors[0]) === 'object') {
            return authors
        }
        else {
            return parseAuthorsToDict(authors)
        }
    }

    function parseAuthorsToDict(authors) {
        var updatedAuthors = authors.map(function(author) {
            return {'title' : author}
        })

        return updatedAuthors
    }

    function parseAuthorNames(authorNames) {
        let updatedAuthors = authorNames.map(function(author) {
            if (author.constructor === Object) {
                return author.title
            }
            return author
        })

        return updatedAuthors
    }

    const prepareDataForCreateProposalAPI = (data) => {
        var fundingAmountString = data.get('funding_amount')
        fundingAmountString = fundingAmountString.replaceAll(',', '')

        let feedback = formRefs[9].current.value

        let internal_proposal_id = data.get('internal_proposal_id')
        let solicitation_id = data.get('solicitation_id')

        let owner_full_name = accountJSON.first_name + ' ' + accountJSON.last_name
        let proposal_id = internal_proposal_id + ' ' + solicitation_id

        if (agency === 'Select Agency') {
            agency = 'OTHER'
        }

        let proposal = {
            'owner' : accountJSON.id,
            'owner_full_name' : owner_full_name,

            'owner_email' : accountJSON.email,
            'is_sensitive' : proposalSensitiveChecked,

            'organisation' : department,
            'grant' : grant,
            'agency' : agency,

            'due_date' : formatDate(dueDate),

            'title' : data.get('topic_title'),
            'solicitation_id' : solicitation_id,
            'internal_proposal_id' : internal_proposal_id,
            
            'proposal_id' : proposal_id,
            'topic_id' : data.get('topic_id'),

            'nickname' : data.get('proposal_nick_name'),
            'solicitation' : data.get('solicitation'),

            'los_company_name' : data.get('los_company_name'),
            'los_person_name' : data.get('los_person_name'),

            'status' : proposalStatus,
            'authors' : parseAuthorNames(authorNames),
            'feedback' : feedback,
            'funding_amount' : parseFloat(fundingAmountString)
        }

        fireCreateProposalAPI(proposal, data)
    }

    const prepareDataForUpdateProposalAPI = (data) => {
        var fundingAmountString = data.get('funding_amount')
        fundingAmountString = fundingAmountString.replaceAll(',', '')

        let feedback = formRefs[9].current.value

        if (agency === 'Select Agency') {
            agency = 'OTHER'
        }

        let proposal = {
            'proposal' : proposalResponse.id,
            'due_date' : formatDate(dueDate),

            'grant' : grant,
            'agency' : agency,
            'is_sensitive' : proposalSensitiveChecked,

            'title' : data.get('topic_title'),
            'solicitation_id' : data.get('solicitation_id'),
            'internal_proposal_id' : data.get('internal_proposal_id'),
            
            'topic_id' : data.get('topic_id'),
            'nickname' : data.get('proposal_nick_name'),
            'solicitation' : data.get('solicitation'),

            'los_company_name' : data.get('los_company_name'),
            'los_person_name' : data.get('los_person_name'),

            'status' : proposalStatus,
            'authors' : parseAuthorNames(authorNames),
            'feedback' : feedback,
            'funding_amount' : parseFloat(fundingAmountString)
        }

        fireUpdateProposalAPI(proposal)
    }

    const fireCreateProposalAPI = (proposal) => {
        setIsLoading(true)

        fetch(`${base_url}v1/proposal/?id=${accountJSON.id}`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(proposal),
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)
                var proposalResponse = proposal

                if (response.ok) {
                    proposalResponse = responseJson.data.data

                    proposalResponse.department = department
                    proposalResponse.client_grant = grant

                    setProposalResponse(proposalResponse)
                    displayFileUploadSection()
                } 
                else {
                    proposalResponse.department = department
                    proposalResponse.client_grant = grant

                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)

                    restoreFormRefsValue(proposalResponse)
                }
            })
        })
    }

    const fireUpdateProposalAPI = (proposal) => {
        setIsLoading(true)

        fetch(`${base_url}v1/proposal/?id=${accountJSON.id}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(proposal),
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)
                var proposalResponse = proposal

                if (response.ok) {
                    
                    if (confirmProposal) {
                        alert('Proposal has been submitted successfully!')
                        history.push('/tool/user/proposals')
                    }
                    else {
                        proposalResponse = responseJson.data.data
                    
                        proposalResponse.department = department
                        proposalResponse.client_grant = grant

                        setProposalResponse(proposalResponse)
                        displayFileUploadSection()
                    }
                } 
                else {
                    proposalResponse.department = department
                    proposalResponse.client_grant = grant

                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)

                    restoreFormRefsValue(proposalResponse)
                }
            })
        })
    }

    const firePatchProposalAPI = (proposal_id, updatingS3Files=false) => {

        let proposalData = {
            'proposal' : proposal_id,
            'files' : s3Files,
            'client_files' : clientFiles
        }

        let proposal = Object.assign({}, proposalData, textFieldsData)

        fetch(`${base_url}v1/proposal/?id=${accountJSON.id}`, {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(proposal),
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)

                if (response.ok) {
                    if (updatingS3Files === true) {
                        populateFilesFromBackend(fileNames)
                    }
                    else {
                        setIsFileUpload(false)
                        setIsInitial(true)

                        if (location.state === null) {
                            alert('Files have been uploaded successfully!')

                            setUpdatingProposal(true)
                            setConfirmProposal(true)

                            setTimeout(() => {
                                if (Object.keys(proposalResponse).length !== 0) {
                                    restoreFormRefsValue(proposalResponse)
                                }
                            }, 100)

                        }
                        else {
                            alert('Proposal has been submitted successfully!')
                            history.push('/tool/user/proposals')
                        }
                    }
                }
                else {
                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)
                }
            })
        })
    }

    function populateKeysForClientAndS3Files() {
        fileNames.forEach((fileName, index) => {

            clientFiles[fileName.toLowerCase()] = []
            s3Files[fileName.toLowerCase()] = []

            setClientFiles(clientFiles)
            setS3Files(s3Files)
        })
    }

    async function populateMissingKeysInClientFiles() {
        let templateFileNames = []

        fileNames.forEach((file, index) => {
            let fileName = file.toLowerCase()
            templateFileNames.push(fileName)

            if (clientFiles[fileName.toLowerCase()] === undefined) {
                clientFiles[fileName.toLowerCase()] = []
                s3Files[fileName.toLowerCase()] = []
            }
        })

        Object.keys(clientFiles).forEach((fileName, index) => {

            if (!templateFileNames.includes(fileName)) {    

                delete clientFiles[fileName]
                delete s3Files[fileName]
            }
        })

        setClientFiles(clientFiles)
        setS3Files(s3Files)
    }

    async function uploadToS3(proposal) {
        getTextFromFields()
        setIsLoading(true)

        var count = 0
        const proposal_id = proposal.proposal_id
        const proposal_owner_email = proposal.owner_email

        var isFinal = false
        var totalCount = fileNames.length

        if (Object.keys(clientFiles).length === 0) {
            populateKeysForClientAndS3Files()
            firePatchProposalAPI(proposal.id)

            return
        }

        if (clientFiles.length !== fileNames.length) {
            await populateMissingKeysInClientFiles()
        }

        for (const [key, files] of Object.entries(clientFiles)) {
            count += 1
            var filesUploaded = []

            if (count === totalCount) {
                isFinal = true
            }

            if (files.length > 0) {
                var filesToUpload = files

                for (const file of files) {

                    if (file.name.includes('.zip')) {

                        let index = filesToUpload.indexOf(file)
                        filesToUpload.splice(index, 1)

                        let fileResponse = await parseZipFile(file)
                        let newFiles = extractFromZip(fileResponse.files)

                        filesToUpload = filesToUpload.concat(newFiles)
                    }
                }

                filesUploaded = await iterateOver(filesToUpload, proposal_id, proposal_owner_email)
            }

            if (updatingProposal === true) {
                if (s3Files[key] === undefined) {
                    s3Files[key] = filesUploaded
                }
                else {
                    let s3FileList = s3Files[key]
                    s3Files[key] = s3FileList.concat(filesUploaded)
                }
            }
            else {
                s3Files[key] = filesUploaded
            }

            if (isFinal === true) {
                setS3Files(s3Files)
                firePatchProposalAPI(proposal.id)
            }
        }
    }

    async function parseZipFile(file) {
        const zip = new JSZip()
        return await zip.loadAsync(file.originFileObj)
    }

    function extractFromZip(files) {
        var filesToUpload = []

        for (const [key, file] of Object.entries(files)) {

            if (!key.includes('__')) {
                filesToUpload.push(file)
            }
        }
        return filesToUpload
    }

    function checkFileType(file) {
        let fileName = file.name

        if (file.type === undefined) {

            if (fileName.includes('.pdf')) {
                return 'application/pdf'
            }

            else if (fileName.includes('.xlsx') || fileName.includes('.xls')) {
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }

            else if (file.name.includes('.docx') || fileName.includes('.doc')) {
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            else {
                return undefined
            }
        }
        return file.type
    }  

    const iterateOver = async (filesToUpload, proposal_id, proposal_owner_email) => {
        var filesUploaded = []

        for (let i = 0; i < filesToUpload.length; i++) {
            var data = null
            const file = filesToUpload[i]

            if ('originFileObj' in file) {
                try {
                    data = file.originFileObj.slice(0, file.size)
                }
                catch (err) {
                    continue
                }
            }
            else {
                try {
                    await file.async('nodebuffer').then(function(fileData) {
                        data = fileData
                    })
                }
                catch (err) {
                    continue
                }
            }
            
            const fileKey = `${proposal_owner_email}/${proposal_id}/${file.name}`
            const fileType = checkFileType(file)

            const params = {
                Body: data,
                Bucket: 'oceanit-proposals',
                ContentType: fileType,
                Key: fileKey
            }

            filesUploaded.push(fileKey)
            await s3.putObject(params).promise()
        }
        return filesUploaded
    }

    const getTextFromFields = () => {
        textFieldNames.forEach((fieldName, index) => {

            if (fieldName === 'Keywords') {
                let keywordsString = textRefs[index].current.value
                let keywords = keywordsString.split(',')

                textFieldsData[fieldName.toLowerCase()] = keywords
            }

            else if (fieldName === 'Budget - Sub Info') {
                textFieldsData['budget_sub_info'] = textRefs[index].current.value
            }

            else if (fieldName === 'Research Partner Info') {
                textFieldsData['research_partner_info'] = textRefs[index].current.value
            }

            else {
                textFieldsData[fieldName.toLowerCase()] = textRefs[index].current.value
            }
        })
        setTextFieldsData(textFieldsData)
    }

    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess('OK')
        }, 0)
    }

    const checkExisitingFiles = (isCache, fileNameList=null) => {
        if (Object.keys(clientFiles).length === 0) {
            return
        }
        else {
            if (isCache === true) {
                populateFilesFromCache()
            }
            else {
                populateFilesFromBackend(fileNameList)
            }
        }
    }

    const populateFilesFromCache = () => {
        var index = 0

        for (const [key, value] of Object.entries(clientFiles)) {
            defaultFileLists[index] = value
            index += 1

            if (key.includes('abstract') && value.length > 0) {
            checkForAbstractFile(value[0])
            }
        }
        setDefaultFileLists(defaultFileLists)
    }

    const populateFilesFromBackend = (fileNameList) => {
        var checkForAbstract = false
        var abstractFile = null
        let fileListNames = fileNameList.map(name => name.toLowerCase())

        for (const [key, value] of Object.entries(clientFiles)) {
            let elementIndex = fileListNames.indexOf(key)
            defaultFileLists[elementIndex] = value

            if (key.includes('abstract') && value.length > 0) {
            checkForAbstract = true
            abstractFile = value[0]
            }
        }
        setDefaultFileLists(defaultFileLists)

        setTimeout(() => {
            if (checkForAbstract === true) {
                populateTextFieldsFromBackend(abstractFile)
            }
            else {
                textRefs[0].current.value = proposalResponse.abstract
                textRefs[1].current.value = proposalResponse.benefits
                textRefs[2].current.value = proposalResponse.keywords
                
                if (textRefs[3].current !== null) {
                    textRefs[3].current.value = proposalResponse.research_partner_info
                }
            }
        }, 100);
    }

    const populateTextFieldsFromBackend = (abstractFile) => {
        let abstract = proposalResponse.abstract
        let benefits = proposalResponse.benefits
        let keywordString = proposalResponse.keywords.toString()

        if (abstract !== '' & benefits !== '' & keywordString !== '') {
            textRefs[0].current.value = abstract
            textRefs[1].current.value = benefits
            textRefs[2].current.value = keywordString
        }
        else {
            checkForAbstractFile(abstractFile)
        }
    }

    const checkForAbstractFile = (file) => {
        let fileName = String(file.name).toLowerCase()

        if (fileName.includes('abstract') && fileName.includes('keywords')) {
            
            if (file.status === 'done') {
                const data = file.originFileObj.slice(0, file.size)

                mammoth.extractRawText({arrayBuffer: data})
                .then(function(result){
                    handleTextExtractionFromAbstractFile(result)
                })
                .done()
            }
            else {
                textRefs[0].current.value = ''
                textRefs[1].current.value = ''
                textRefs[2].current.value = ''
            }
        }
    }

    const handleTextExtractionFromAbstractFile = (result) => {
        let text = result.value
        var abstractKeyword = String()
        var benefitsKeyword = String()

        if (text.includes('Abstract')) {
            abstractKeyword = 'Abstract'
        } 
        else if (text.includes('Abstracts')) {
            abstractKeyword = 'Abstracts'
        }

        if (text.includes('Benefit')) {
            benefitsKeyword = 'Benefit'
        } 
        else if (text.includes('Benefits')) {
            benefitsKeyword = 'Benefits'
        }

        let textArrayAbstract = text.split(abstractKeyword) // 1st Split for Ab
        let abstractTextArray = textArrayAbstract[1].split(benefitsKeyword) // 2nd split for Ab, we need 0 index element here.

        let abstract = abstractTextArray[0].replace(/(?:\r\n|\r|\n)/g, '')

        let textArrayBenefits = text.split(benefitsKeyword) // 1st split for Be
        let benefitsTextArray = textArrayBenefits[1].split('Keywords') // 2nd split for Be

        let benefits = benefitsTextArray[0].replace(/(?:\r\n|\r|\n)/g, '')

        let textArrayKeywords = text.split('Keywords') // Only split needed for Keywords
        let keywords = textArrayKeywords[1].replace(/(?:\r\n|\r|\n)/g, '')

        textRefs[0].current.value = abstract
        textRefs[1].current.value = benefits
        textRefs[2].current.value = keywords
    }

    const handleFileUploadChange = ({file}) => {

        if (file.status !== 'uploading') {
            
            checkForAbstractFile(file)

            fileNames.forEach((fileName, index) => {
                let sectionFiles = fileRefs[index].current.fileList
                let updatedSectionFiles = checkSectionFiles(sectionFiles, file)

                clientFiles[fileName.toLowerCase()] = updatedSectionFiles
                defaultFileLists[index] = updatedSectionFiles
            })

            setTimeout(() => {
                setIsLoading(true)
                setIsLoading(false)

                if (updatingProposal) {
                    checkExisitingFiles(false, fileNames)
                }
                else {
                    checkExisitingFiles(true)
                }
            }, 500)
        }

        if (file.status === 'removed' && updatingProposal === true) {
            handleFileRemoveChange(file)
        }

        setClientFiles(clientFiles)
    }

    const checkSectionFiles = (sectionFiles, file) => {
        var repeatCount = 0
        var repeatIndex = -1

        sectionFiles.forEach((sectionFile, index) => {
            if (file.name === sectionFile.name) {

                if (repeatCount === 0) {
                    repeatCount += 1
                    repeatIndex = index
                }
                else {
                    sectionFiles.splice(repeatIndex, 1)
                }
            }
        })
        return sectionFiles
    }

    const handleFileRemoveChange = async (file) => {
        var objectKey = ''

        for(const [_, s3FileObject] of Object.entries(s3Files)) {

            if (s3FileObject.length) {
                for (const s3File of s3FileObject) {

                    if (s3File.includes(file.name)) {
                        let index = s3FileObject.indexOf(s3File)
                        s3FileObject.splice(index, 1)
                        objectKey = s3File

                        break
                    }
                }
            }
        }

        setS3Files(s3Files)
        setIsLoading(true)

        if (objectKey !== '') {
            await removeFileFromS3(objectKey)
        }
        else {
            setTimeout(() => {
            setIsLoading(false)
            populateFilesFromBackend(fileNames)
            }, 500)
        }
    }

    async function removeFileFromS3(objectKey) {
        const params = {
            Bucket: 'oceanit-proposals',
            Key: objectKey
        }

        await s3.deleteObject(params).promise()
        firePatchProposalAPI(proposalResponse.id, true)
    }

    const handleFilesUploadBack = () => {
        getTextFromFields()

        // NOTE: Not sure if this is needed.
        // if (updatingProposal) {
            
        // }
        // else {
            
        // }

        setIsInitial(true)
        setIsFileUpload(false)

        setTimeout(() => {
            if (Object.keys(proposalResponse).length !== 0) {
                restoreFormRefsValue(proposalResponse)
            }
        }, 100)
    }

    const populateTextRefsFromTextFieldData = () => {
        textFieldNames.forEach((textField, index) => {

            if (textField === 'Research Partner Info') {
                textRefs[index].current.value = textFieldsData['research_partner_info']
            }
            else if (textField === 'Budget - Sub Info') {
                textRefs[index].current.value = textFieldsData['budget_sub_info']
            }
            else {
                textRefs[index].current.value = textFieldsData[textField.toLowerCase()]
            }
        })
    }

    const SearchButton = () => {
        return (
            <IconButton onClick={checkInternalProposalNumber}>
                <SearchOutlined />
            </IconButton>
        )
    }

    function checkInternalProposalNumber() {
        let data = fetchFormRefsData()

        setIsLoading(true)

        fetch(`${oceanit_base_url}?apikey=${oceanit_api_key}&proposalnumber=${data.internal_proposal_id}`, {
            method: 'GET'
        })
        .then(response => {

            response.json()
            .then(responseJson => {

            setIsLoading(false)
            let proposal = responseJson

            if (proposal.status === 'error') {
                alert('No data found for this proposal number. Please try a different one.')
            
                setTimeout(() => {
                    restoreFormRefsValue(data)
                }, 50);
            } 
            else {
                updateFieldsOnSuccess(proposal, data)
            }
            })
        })
    }

    function checkAuthorNames(authorList) {
        var updatedAuthorsList = []

        authorList.forEach(authorName => {
            let authorNameArray = authorName.split(' ')

            authors.forEach(authorDict => {
                var matchCount = 0
                let author = authorDict.title.toUpperCase()

                authorNameArray.forEach(value => {
                    if (author.includes(value)) {
                        matchCount += 1

                        if (matchCount >= 2) {
                            updatedAuthorsList.push(authorDict)
                        }
                    }
                })
            })
        })

        return updatedAuthorsList
    }

    function updateFieldsOnSuccess(proposal, data) {
        let unixTime = Date.parse(proposal.deadline)
        let date = new Date(unixTime)
        let proposalSecurity = proposal.security

        let proposalStatusMappings = {
            'Win' : 'AWARDED',
            'Loss' : 'REJECTED',
            'Submitted' : 'UNDER EVALUATION'
        }

        var isProposalSensitive = true // NOTE: Need to decide about this
        let proposalStatus = proposalStatusMappings[proposal.proposalstatus_description]

        if (proposalSecurity == null) {
            isProposalSensitive = false
        }
        else {
            if (proposalSecurity.includes('Non-Sensitive') || proposalSecurity.includes('Not Sensitive')) {
                isProposalSensitive = false
            }
        }

        // Updating these 5 (state-change ones) first because formRefs loose value if state changes.
        setProposalSensitiveChecked(isProposalSensitive)
        setProposalStatus(proposalStatus)
        setDueDate(date)

        setCurrencyValue(proposal.estimated_value)

        setDepartment(data.department)
        setGrant(data.client_grant)
        setAgency(data.agency)

        var authorsList = []
        var authorNameList = []
        
        if (proposal.proposal_leader_display_name !== null) {
            let authorName = proposal.proposal_leader_display_name
            authorNameList.push(authorName)
        }
        if (proposal.technical_lead_display_name !== null) {
            let authorName = proposal.technical_lead_display_name
            authorNameList.push(authorName)
        }

        if (proposal.participating_staff !== null) {
            let scorecardAuthors = proposal.participating_staff.split(';')
            let formattedScorecardAuthors = scorecardAuthors.map(function(author) {
                return author.trim()
            })

            authorNameList = authorNameList.concat(formattedScorecardAuthors)
            authorNameList = [... new Set(authorNameList)]

            authorsList = authorNameList.map(function(author) {
                return author.trim().toUpperCase()
            })

            let updatedAuthorsList = checkAuthorNames(authorsList)
            setAuthorNames(updatedAuthorsList)
        }

        else {
            setAuthorNames([])
        }

        // Data from response
        formRefs[2].current.value = proposal.ProposalDescription
        formRefs[5].current.value = proposal.solicitation_ref
        formRefs[9].current.value = proposal.notes

        // Data from form/old fields stored above in checkInternalProposalNumber
        formRefs[0].current.value = data.topic_id
        formRefs[1].current.value = data.internal_proposal_id

        formRefs[3].current.value = data.solicitation_id
        formRefs[6].current.value = data.nickname

        formRefs[10].current.value = data.los_company_name
        formRefs[11].current.value = data.los_person_name

        formRefs[1].current.focus()
    }

    const ConfirmTopicDetailsComponent = () => {
        let titleText = 'Enter Proposal Details'

        if (confirmProposal) {
            titleText = 'Confirm Proposal Details'
        }
        return (
            <div>
                <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
                >
                <CircularProgress />
                </Backdrop>

                <GridContainer
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <InputLabel style={{fontWeight: 'bold', fontSize: '1.5em'}}>{titleText}</InputLabel>
                    <form className={classes.form} onSubmit={handleConfirmDetails}>

                    <div style={{float : 'left', 'marginBottom' : '20px', 'marginTop' : '20px'}}>
                        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoFocus
                            inputRef={formRefs[8]}
                            style={{width: '150%'}}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            placeholder="Due Date"
                            value={dueDate}
                            onChange={handleDueDateChange}
                        />
                        </MuiPickersUtilsProvider>
                    </div>

                    <FormControlLabel
                        style={{
                            width: '100%',
                            marginRight: '10px'
                        }}
                        control={
                            <Checkbox
                                style={{
                                    transform: 'scale(1.4)',
                                }}
                                checked={proposalSensitiveChecked}
                                onChange={handleProposalSensitiveChange}
                                name="is_proposal_sensitive"
                                color="primary"
                            />
                        }
                        label="Proposal Contains Sensitive Information"
                    />

                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={department}
                            label={'Department'}
                            style={{textAlign: 'left', marginTop: '10px'}}
                            onChange={handleDepartmentChange}
                        >
                            {departmentItemValues}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <Select
                            id="grant_edit"
                            defaultValue={grant}
                            label="Grant"
                            style={{textAlign: 'left', marginTop: '10px'}}
                            onChange={handleGrantChange}
                        >
                            {grantItemValues}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        {!showAgencyItems ? null : (
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={agency}
                            label="Agency"
                            style={{textAlign: 'left', marginTop: '10px'}}
                            onChange={handleAgencyChange}
                        >
                            {agencyItemValues}
                        </Select>
                        )}
                    </FormControl>

                    <FormControl fullWidth>
                        <Select
                            inputRef={formRefs[7]}
                            labelId="proposal_status_label"
                            id="proposal_status"
                            label='Proposal Status'
                            defaultValue={proposalStatus}
                            onChange={handleProposalStatusChange}
                            placeholder='Proposal Status'
                            style={{width: '100%', alignSelf: 'left', textAlign: 'left', marginTop: '10px'}}
                        >
                        <MenuItem value={'Select Proposal Status'}>Select Proposal Status</MenuItem>
                        <MenuItem value={'UNDER EVALUATION'}>Under Evaluation</MenuItem>
                        <MenuItem value={'AWARDED'}>Awarded</MenuItem>
                        <MenuItem value={'REJECTED'}>Rejected</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        inputRef={formRefs[1]}
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="internal_proposal_id"
                        name="internal_proposal_id"
                        placeholder="Internal Proposal ID (PT#) *"
                        InputProps={{endAdornment: <SearchButton />}}
                    />

                    <TextField
                        inputRef={formRefs[5]}
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="solicitation_id"
                        name="solicitation_id"
                        placeholder="Solicitation ID *"
                    />
                    <TextField
                        inputRef={formRefs[2]}
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="topic_title"
                        name="topic_title"
                        placeholder="Topic Title *"
                    />
                    <TextField
                        inputRef={formRefs[0]}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="topic_id"
                        name="topic_id"
                        placeholder="Topic ID"
                    />
                    <TextField
                        inputRef={formRefs[3]}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="solicitation"
                        name="solicitation"
                        placeholder="Full Solicitation"
                    />
                    <Autocomplete
                        multiple
                        freeSolo={true}
                        defaultValue={authorNames}
                        options={authors}
                        getOptionLabel={(option) => option.title}
                        onChange={handleAuthorsNameChange}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                inputRef={formRefs[4]}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="topic_authors"
                                name="topic_authors"
                                placeholder="Topic Authors (John, Jane)"
                            />
                        )}
                    />
                    <TextField
                        inputRef={formRefs[6]}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="proposal_nick_name"
                        name="proposal_nick_name"
                        placeholder="Proposal Nickname"
                    />
                    <TextField
                        inputRef={formRefs[10]}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="los_company_name"
                        name="los_company_name"
                        placeholder="LOS (Company Name)"
                    />
                    <TextField
                        inputRef={formRefs[11]}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="los_person_name"
                        name="los_person_name"
                        placeholder="LOS (Person Name)"
                    />
                    <CurrencyTextField
                        fullWidth
                        textAlign="left"
                        variant="outlined"
                        margin="normal"
                        currencySymbol="$"
                        id="funding_amount"
                        name="funding_amount"
                        placeholder="Funding Amount"
                        value={currencyValue}
                    />
                    <TextField
                        inputRef={formRefs[9]}
                        fullWidth
                        multiline
                        minRows={5}
                        variant="outlined"
                        margin="normal"
                        id="Feedback"
                        name="feedback"
                        placeholder="Feedback"
                    />
                    
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Submit
                    </Button>
                </form>
                </GridContainer>
            </div>
        )
    }

    const FileUploadComponent = () => {
        return (
            <div>
                <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
                >
                <CircularProgress />
                </Backdrop>

                <GridContainer
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        startIcon={<ArrowBackIcon/>}
                        onClick={handleFilesUploadBack}
                        style={{'position' : 'absolute', 'top' : '8%', 'left' : '2%', 'transform' : 'translateY(-50%)'}}
                    >
                    Back
                    </Button>

                <GridItem xs={12} sm={12} md={6}>
                    <Card>

                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Template: {department} {grantString}</h4>
                        <p className={classes.cardTitleWhite}> Proposal Lead: {accountJSON.first_name + ' ' + accountJSON.last_name}</p>
                    </CardHeader>

                    <br></br>

                    <CardBody>
                        {fileNames.map((fileName, index) => (
                        <div>
                            <div align='left'>
                            <InputLabel style={{textAlign: 'left', fontWeight: 'bold'}}>{fileName}</InputLabel>
                            <p>Supported file types: {fileTypes[index]}</p>
                            <Upload
                                id={fileName}
                                ref={fileRefs[index]}
                                defaultFileList={defaultFileLists[index]}
                                multiple={true}
                                customRequest={dummyRequest}
                                onChange={handleFileUploadChange}
                            >
                                <Button 
                                    variant="outlined"
                                >
                                Click to Upload or Drop Here
                                </Button>
                            </Upload>
                            </div>
                            <br></br>
                        </div>
                        ))}
                    {textFieldNames.map((textFieldName, index) => (
                        <div>
                        <div align='left'>
                            <InputLabel style={{textAlign: 'left', fontWeight: 'bold'}}>{textFieldName}</InputLabel>
                            <br></br>
                            <TextField
                                id="outlined-multiline-static"
                                variant="outlined"
                                inputRef={textRefs[index]}
                                multiline
                                fullWidth
                                minRows={4}
                            />
                        </div>
                        <br></br>
                        </div>
                    ))}
                    </CardBody>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{alignSelf: 'center'}}
                        onClick={() => uploadToS3(proposalResponse)}
                    >
                        Submit
                    </Button>

                    </Card>
                </GridItem>
                </GridContainer>
            </div>
        )
    }

    return (
        <div>
            { isInitial ? <ConfirmTopicDetailsComponent/> : null}
            { isFileUpload ? <FileUploadComponent/> : null }
        </div>

    );
}
