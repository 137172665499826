import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles"

import Button from '@material-ui/core/Button'
import GridContainer from "components/Grid/GridContainer.js";
import {CircularProgress, Backdrop} from '@mui/material';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import GridItem from "components/Grid/GridItem.js"

import { Upload } from 'antd'
import AWS from 'aws-sdk'

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Autocomplete } from "@material-ui/lab";
import { authors } from "../../assets/extras/constants"

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  })

const s3 = new AWS.S3({
  params: { Bucket: 'oceanit-biosketches'},
  region: 'us-gov-east-1',
})

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '200px',
    margin: theme.spacing(3, 0, 2),
  },

  option: {
    width: '400px',
    margin: theme.spacing(3, 0, 2),
  },
}))

const base_url = process.env.REACT_APP_BASE_URL;

export default function Biosketch() {
    var accountJSON = {}

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const currentUser = checkUser(history)

    const [fileRefs, setFileRefs] = useState([
        useRef(null), 
        useRef(null),
        useRef(null),
        useRef(null),
    ])

    var [defaultFileLists, setDefaultFileLists] = useState([
        [],
        [],
        [],
        [],
    ])

    let fileNames = [
        'Bioblurb -- short paragraph describing your background and expertise (for DoD proposals)',
        'NSF Formatted Biosketch (also used in DOE proposals)',
        'NSF Formatted Current and Pending Support (also used in DOE proposals)',
        'Biosketch (NIH) -- NIH formatted biosketch'
    ]

    let fileTypes = [
        '.pdf, .docx',
        '.pdf, .docx',
        '.pdf, .docx',
        '.pdf, .docx'
    ]

    let workFieldValues = [
        <MenuItem value={'Select Field of Work'}>Select Field of Work</MenuItem>,

        <MenuItem value={'CHEMICAL ENGINEERING'}>Chemical Engineering</MenuItem>,
        <MenuItem value={'CIVIL ENGINEERING'}>Civil Engineering</MenuItem>,

        <MenuItem value={'ELECTRICAL ENGINEERING'}>Electrical Engineering</MenuItem>,
        <MenuItem value={'MECHANICAL ENGINEERING'}>Mechanical Engineering</MenuItem>,

        <MenuItem value={'NUCLEAR ENGINEERING'}>Nuclear Engineering</MenuItem>,
        <MenuItem value={'BIOMEDICAL ENGINEERING'}>Biomedical Engineering</MenuItem>,

        <MenuItem value={'MATERIALS SCIENCE'}>Materials Science</MenuItem>,
        <MenuItem value={'DESIGN ENGINEERING'}>Design Engineering</MenuItem>,

        <MenuItem value={'SOFTWARE ENGINEERING'}>Software Engineering</MenuItem>,
        <MenuItem value={'OCEAN ENGINEERING'}>Ocean Engineering</MenuItem>,

        <MenuItem value={'ASTROPHYSICS'}>Astrophysics</MenuItem>,
        <MenuItem value={'CYBER SECURITY'}>Cyber Security</MenuItem>,

        <MenuItem value={'MACHINE LEARNING'}>Machine Learning</MenuItem>,
        <MenuItem value={'MATHEMATICS'}>Mathematics</MenuItem>,

        <MenuItem value={'CONTROL SYSTEM'}>Control System</MenuItem>,
        <MenuItem value={'CHEMISTRY'}>Chemistry</MenuItem>,

        <MenuItem value={'BIOLOGY'}>Biology</MenuItem>,
        <MenuItem value={'PHYSICS'}>Physics</MenuItem>,

        <MenuItem value={'ASTRONOMY'}>Astronomy</MenuItem>,
        <MenuItem value={'LINGUISTICS'}>Linguistics</MenuItem>,

        <MenuItem value={'ENVIRONMENTAL SCIENCE'}>Environmental Science</MenuItem>,
        <MenuItem value={'AGRICULTURE'}>Agriculture</MenuItem>,

        <MenuItem value={'SOCIAL SCIENCE'}>Social Science</MenuItem>,
        <MenuItem value={'ECONOMICS'}>Economics</MenuItem>,
        <MenuItem value={'COMMERCIALIZATION'}>Commercialization</MenuItem>,
    ]

    let locationFieldValues = [
        <MenuItem value={'Select Location'}>Select Location</MenuItem>,
        <MenuItem value={'OAHU'}>Oahu</MenuItem>,

        <MenuItem value={'HOUSTON'}>Houston</MenuItem>,
        <MenuItem value={'DC'}>DC</MenuItem>,
        <MenuItem value={'FULLY REMOTE'}>Fully Remote</MenuItem>,
    ]

    var [selectedBiosketch, setSelectedBiosketch] = useState({})

    const [biosketchData, setBiosketchData] = useState({})
    const [biosketchResponse, setBiosketchResponse] = useState({})

    const [clientFiles, setClientFiles] = useState({})
    const [s3Files, setS3Files] = useState({})

    const [isLoading, setIsLoading] = useState(false)
    const [isInitial, setIsInitial] = useState(true)
    var [updatingBiosketch, setUpdatingBiosketch] = useState(false)

    const [isConfirmDetails, setIsConfirmDetails] = useState(false)

    const [lastUpdated, setLastUpdated] = useState(null)

    var [workFieldValue, setWorkFieldValue] = useState(['Select Field of Work'])
    var [locationFieldValue, setLocationFieldValue] = useState('Select Location')
    var [expertiseFieldValue, setExpertiseFieldValue] = useState('')

    var [personName, setPersonName] = useState('')

    useEffect(() => {
        if (currentUser !== undefined) {
            checkForBiosketchUpdate()
        }
    }, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
                pathname: '/signin',
                state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            accountJSON = JSON.parse(accountDetails)

            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function checkForBiosketchUpdate() {
        if (accountJSON.role === 'GUEST') {
            alert('Your user access does not allow uploading biosketches. Please contact admin for further details.')
            history.push('/tool/user/')
        }

        if (location.state !== null) {

            let biosketch = location.state.biosketch

            updatingBiosketch = true
            selectedBiosketch = biosketch

            setUpdatingBiosketch(true)
            updateSelectedBiosketch(biosketch)
        }
        else {
            logFirebaseEvent('Upload -> Biosketches -> Arrived')
            setTimeout(() => {
                setIsInitial(true)
            }, 100);
        }
    }

    function updateSelectedBiosketch(biosketch) {
        setSelectedBiosketch(biosketch)

        setS3Files(biosketch.files)
        setClientFiles(biosketch.client_files)

        setTimeout(() => {
            updateFormData()
        }, 100);
    }

    const handleLastUpdatedDateChange = (date, dateString) => {

        if (!isNaN(date)) {
            setLastUpdated(date)

            setTimeout(() => {
                updateFormData(false)

            }, 300);
        }
    }

    const handlePersonNameChange = (event, value) => {
        personName = value.title
    }

    const handleWorkFieldChange = (event) => {
        let values = event.target.value

        if (values[0] === 'Select Field of Work') {
            values.shift()
        }
        workFieldValue = values
    }

    const handleLocationFieldChange = (event) => {
        locationFieldValue = event.target.value
    }

    const handleConfirmDetails = (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        checkFields(data)
        logFirebaseEvent('Upload -> Biosketches -> Confirm Tapped')
    }

    function checkFields(data) {
        let personName = data.get('person_name')

        if (lastUpdated === null) {
            alert('Please enter a valid value for Last Updated On.')
            return
        }

        if (personName.length === 0 || !personName.replace(/\s/g, '').length) {
            alert('Please enter valid detail for Person Name')
            return
        }

        if (workFieldValue[0] === 'Select Field of Work') {
            alert('Please select a valid value for Field of Work.')
            return
        }

        if (locationFieldValue === 'Select Location') {
            alert('Please select a valid value for Location.')
            return
        }

        if (!updatingBiosketch) {
            prepareDataForCreateBiosketchAPI(data)
        }
        else {
            prepareDataForUpdateBiosketchAPI(data)
        }
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-');
    }

    const prepareDataForCreateBiosketchAPI = (data) => {

        let expertiseString = data.get('expertise')
        let expertise = expertiseString.split(',')

        var biosketchData = {
            'owner' : accountJSON.id,
            'updated_at' : formatDate(lastUpdated),

            'person_name' : data.get('person_name'),
            'expertise' : expertise,

            'field' : workFieldValue,
            'work_location' : locationFieldValue
        }

        setIsLoading(true)

        setBiosketchData(biosketchData)
        fireCreateBiosketchAPI(biosketchData, data)
    }

    const prepareDataForUpdateBiosketchAPI = (data) => {

        let expertiseString = data.get('expertise')
        let expertise = expertiseString.split(',')

        var biosketchData = {
            'biosketch' : selectedBiosketch.id,
            'updated_at' : formatDate(lastUpdated),

            'person_name' : data.get('person_name'),
            'expertise' : expertise,

            'field' : workFieldValue,
            'work_location' : locationFieldValue
        }

        setIsLoading(true)

        setBiosketchData(biosketchData)
        fireUpdateBiosketchAPI(biosketchData, data)
    }

    const fireCreateBiosketchAPI = (biosketch, data) => {
        fetch(`${base_url}v1/biosketch/?id=${accountJSON.id}`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(biosketch),
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)

                if (response.ok) {
                    let biosketchResponse = responseJson.data.data

                    setBiosketchResponse(biosketchResponse)

                    setIsConfirmDetails(true)
                    setIsInitial(false)
                } 
                else {
                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)
                }
            })
        })
    }

    const fireUpdateBiosketchAPI = (biosketch, data) => {
        fetch(`${base_url}v1/biosketch/?id=${accountJSON.id}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(biosketch),
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)

                if (response.ok) {
                    let biosketchResponse = responseJson.data.data
                    setBiosketchResponse(biosketchResponse)

                    setIsConfirmDetails(true)
                    setIsInitial(false)
                } 
                else {
                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)
                }
            })
        })
    }

    // Used when user comes back from file upload.
    function restoreFormDataValues(data) {
        let expertise = data['expertise']

        personName = {'title' : data['person_name']}
        workFieldValue = data['field']
        locationFieldValue = data['work_location']

        setLastUpdated(lastUpdated)
        setWorkFieldValue(workFieldValue)
        setLocationFieldValue(locationFieldValue)

        setPersonName(personName)
        setExpertiseFieldValue(expertise)
    }

    const firePatchBiosketchAPI = (biosketch_id, updatingS3Files=false) => {
        let biosketch = {
            'biosketch' : biosketch_id,
            'files' : s3Files,
            'client_files' : clientFiles
        }

        fetch(`${base_url}v1/biosketch/?id=${accountJSON.id}`, {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(biosketch),
        })
        .then(response => {
            response.json()
            .then(responseJson => {

                if (response.ok) {
                    if (updatingS3Files === true) {
                        populateFilesFromBackend(fileNames)
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                        setIsConfirmDetails(false)

                        setIsInitial(true)
                        alert('Files have been uploaded successfully!')

                        history.push('/tool/user/biosketches')
                    }
                }
                else {
                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)
                }
            })
        })
    }

    function populateKeysForClientAndS3Files() {
        fileNames.forEach((fileName, index) => {

            clientFiles[fileName.toLowerCase()] = []
            s3Files[fileName.toLowerCase()] = []

            setClientFiles(clientFiles)
            setS3Files(s3Files)
        })
    }

    async function populateMissingKeysInClientFiles() {
        let templateFileNames = []

        fileNames.forEach((file, index) => {
            let fileName = file.toLowerCase()
            templateFileNames.push(fileName)

            if (clientFiles[fileName.toLowerCase()] === undefined) {
                clientFiles[fileName.toLowerCase()] = []
                s3Files[fileName.toLowerCase()] = []
            }
        })

        Object.keys(clientFiles).forEach((fileName, index) => {

            if (!templateFileNames.includes(fileName)) {    

                delete clientFiles[fileName]
                delete s3Files[fileName]
            }
        })

        setClientFiles(clientFiles)
        setS3Files(s3Files)
    }

    async function uploadToS3() {
        setIsLoading(true)

        var count = 0
        const s3KeyID = biosketchResponse.s3_key_id

        var isFinal = false
        var totalCount = fileNames.length

        if (Object.keys(clientFiles).length === 0) {
            populateKeysForClientAndS3Files()
            firePatchBiosketchAPI(biosketchResponse.id)
            return
        }

        if (clientFiles.length !== fileNames.length) {
            await populateMissingKeysInClientFiles()
        }

        for (const [key, filesToUpload] of Object.entries(clientFiles)) {
            count += 1
            var filesUploaded = []

            if (count === totalCount) {
                isFinal = true
            }

            if (filesToUpload.length > 0) {
                filesUploaded = await iterateOver(filesToUpload, s3KeyID)
            }

            if (updatingBiosketch === true) {
                if (s3Files[key] === undefined) {
                    s3Files[key] = filesUploaded
                }
                else {
                    let s3FileList = s3Files[key]
                    s3Files[key] = s3FileList.concat(filesUploaded)
                }
            }
            else {
                s3Files[key] = filesUploaded
            }

            if (isFinal === true) {
                setS3Files(s3Files)
                firePatchBiosketchAPI(biosketchResponse.id)
            }
        }
    }

    const iterateOver = async (filesToUpload, s3KeyID) => {
        var filesUploaded = []

        for (let i = 0; i < filesToUpload.length; i++) {
            var data = null
            const file = filesToUpload[i]

            try {
                data = file.originFileObj.slice(0, file.size)
            }
            catch (err) {
                continue
            }
            
            const fileKey = `${accountJSON.email}/${s3KeyID}/${file.name}`
            const params = {
                Body: data,
                Bucket: 'oceanit-biosketches',
                ContentType: `${file.type}`,
                Key: fileKey
            }

            filesUploaded.push(fileKey)
            await s3.putObject(params).promise()
        }
        return filesUploaded
    }

    // When user comes to updated Biosketch, we use this to prefill biosketch details.
    const updateFormData = (updateDate = true) => {

        if (updatingBiosketch === true) {

            if (updateDate === true) {
                if (selectedBiosketch.due_date !== null) {
                    setLastUpdated(new Date(selectedBiosketch.updated_at))
                }
            }

            // State change vars need to be updated first as formRefs loose value is state changes.
            setWorkFieldValue(selectedBiosketch.field)
            setLocationFieldValue(selectedBiosketch.work_location)

            authors.forEach((element) => {
                if (element.title === selectedBiosketch.person_name) {
                    setPersonName(element)
                }
            })

            setExpertiseFieldValue(selectedBiosketch.expertise.toString()) 
        }
    }

    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess('OK')
        }, 0)
    }

    const checkExisitingFiles = (isCache, fileNameList=null) => {

        if (Object.keys(clientFiles).length === 0) {
            return
        }
        else {
            if (isCache === true) {
                populateFilesFromCache()
            }
            else {
                populateFilesFromBackend(fileNameList)
            }
        }
    }

    const populateFilesFromCache = () => {
        var index = 0

        for (const [key, value] of Object.entries(clientFiles)) {
            defaultFileLists[index] = value
            index += 1
        }
        setDefaultFileLists(defaultFileLists)
    }

    const populateFilesFromBackend = (fileNameList) => {
        let fileListNames = fileNameList.map(name => name.toLowerCase())

        for (const [key, value] of Object.entries(clientFiles)) {
            let elementIndex = fileListNames.indexOf(key)
            defaultFileLists[elementIndex] = value
        }
        setDefaultFileLists(defaultFileLists)
    }

    const handleFileUploadChange = ({file}) => {
        if (file.status !== 'uploading') {

            if (file.status === 'done') {
                logFirebaseEvent(`Upload -> Biosketches -> File Upload Tapped ${file.name}`)
            }

            fileNames.forEach((fileName, index) => {
                let sectionFiles = fileRefs[index].current.fileList
                let updatedSectionFiles = checkSectionFiles(sectionFiles, file)

                clientFiles[fileName.toLowerCase()] = updatedSectionFiles
                defaultFileLists[index] = updatedSectionFiles
            })

            setTimeout(() => {
                setIsLoading(true)
                setIsLoading(false)

                if (updatingBiosketch) {
                    checkExisitingFiles(false, fileNames)
                }
                else {
                    checkExisitingFiles(true)
                }
            }, 500)
        }

        if (file.status === 'removed' && updatingBiosketch === true) {
            logFirebaseEvent(`Upload -> Biosketches -> File Remove Tapped ${file.name}`)
            handleFileRemoveChange(file)
        }

        setClientFiles(clientFiles)
    }

    const checkSectionFiles = (sectionFiles, file) => {
        var repeatCount = 0
        var repeatIndex = -1

        sectionFiles.forEach((sectionFile, index) => {
            if (file.name === sectionFile.name) {

            if (repeatCount === 0) {
                repeatCount += 1
                repeatIndex = index
            }
            else {
                sectionFiles.splice(repeatIndex, 1)
            }
            }
        })
        return sectionFiles
    }

    const handleFileRemoveChange = async (file) => {
        var objectKey = ''

        for(const [_, s3FileObject] of Object.entries(s3Files)) {

            if (s3FileObject.length) {
                for (const s3File of s3FileObject) {

                    if (s3File.includes(file.name)) {
                        let index = s3FileObject.indexOf(s3File)
                        s3FileObject.splice(index, 1)
                        objectKey = s3File

                        break
                    }
                }
            }
        }

        setS3Files(s3Files)
        setIsLoading(true)

        if (objectKey !== '') {
            await removeFileFromS3(objectKey)
        }
        else {
            setTimeout(() => {
            setIsLoading(false)
            populateFilesFromBackend(fileNames)
            }, 500)
        }
    }

    async function removeFileFromS3(objectKey) {
        const params = {
            Bucket: 'oceanit-biosketches',
            Key: objectKey
        }

        await s3.deleteObject(params).promise()
        firePatchBiosketchAPI(selectedBiosketch.id, true)
    }

    const handleBiosketchFileUploadBack = () => {
        setIsInitial(true)
        setIsConfirmDetails(false)

        setTimeout(() => {
            restoreFormDataValues(biosketchData)
        }, 100);
    }

    const BiosketchDetailsComponent = () => {
        return (
            <div>
                <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
                >
                <CircularProgress />
                </Backdrop>

                <GridContainer
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <InputLabel style={{fontWeight: 'bold', fontSize: '1.5em'}}>Enter Biosketch Details</InputLabel>

                    <form className={classes.form} onSubmit={handleConfirmDetails}>
                        <div style={{float : 'left', 'marginTop' : '20px'}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoFocus
                                required
                                style={{width: '150%'}}
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                placeholder="Last Updated On *"
                                value={lastUpdated}
                                onChange={handleLastUpdatedDateChange}
                            />
                            </MuiPickersUtilsProvider>
                        </div>

                        <Autocomplete
                            defaultValue={personName}
                            options={authors}
                            getOptionLabel={(option) => option.title}
                            onChange={handlePersonNameChange}
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    required
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    id="person_name"
                                    name="person_name"
                                    placeholder="Person Name *"
                                />
                            )}
                        />

                        <FormControl fullWidth>
                            <Select
                                id="work_field"
                                multiple
                                defaultValue={workFieldValue}
                                label="Field of Work"
                                style={{textAlign: 'left', marginTop: '10px'}}
                                onChange={handleWorkFieldChange}
                            >
                                {workFieldValues}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <Select
                                id="location_field"
                                defaultValue={locationFieldValue}
                                label="Location"
                                style={{textAlign: 'left', marginTop: '10px'}}
                                onChange={handleLocationFieldChange}
                            >
                                {locationFieldValues}
                            </Select>
                        </FormControl>

                        <TextField
                            defaultValue={expertiseFieldValue}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="expertise"
                            name="expertise"
                            placeholder="Expertise"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Submit
                        </Button>
                    </form>
                </GridContainer>
            </div>
        )
    }

    const FileUploadComponent = () => {
        return (
            <div>
                <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
                >
                <CircularProgress />
                </Backdrop>

                <GridContainer
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                >

                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        startIcon={<ArrowBackIcon/>}
                        onClick={handleBiosketchFileUploadBack}
                        style={{'position' : 'absolute', 'top' : '8%', 'left' : '2%', 'transform' : 'translateY(-50%)'}}
                    >
                    Back
                    </Button>

                <GridItem xs={12} sm={12} md={8}>
                    <Card>

                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Biosketch Files</h4>
                    </CardHeader>

                    <br></br>

                    <CardBody>
                        {fileNames.map((fileName, index) => (
                        <div>
                            <div align='left'>
                            <InputLabel style={{textAlign: 'left', fontWeight: 'bold'}}>{fileName}</InputLabel>
                            <p>Supported file types: {fileTypes[index]}</p>
                            <Upload
                                id={fileName}
                                ref={fileRefs[index]}
                                defaultFileList={defaultFileLists[index]}
                                multiple={true}
                                customRequest={dummyRequest}
                                onChange={handleFileUploadChange}
                            >
                                <Button 
                                    variant="outlined"
                                >
                                Click to Upload or Drop Here
                                </Button>
                            </Upload>
                            </div>
                            <br></br>
                        </div>
                        ))}
                    </CardBody>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{alignSelf: 'center'}}
                        onClick={() => uploadToS3()}
                    >
                        Upload
                    </Button>

                    </Card>
                </GridItem>
                </GridContainer>
            </div>
        )
    }

    return (

    <div>
        { isInitial ? <BiosketchDetailsComponent/> : null}
        { isConfirmDetails ? <FileUploadComponent/> : null }
    </div>

    );
}
