import './App.css';
import Admin from "./layouts/Admin.js";
import SignIn from "./views/SignIn/SignIn"
import SignUp from "./views/SignUp/SignUp"
import VerifyEmail from './views/SignUp/verifyEmail'
import EmailNotVerified from "./views/SignUp/emailNotVerified"
import ForgotPassword from "./views/SignUp/ForgotPassword"
import ResetPassword from "./views/SignUp/ResetPassword"
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>

      <Route path="/tool" component={Admin} />
      <Route path="/signup" exact component={ () => <SignUp />} />
      <Route path="/signin" exact component={ () => <SignIn />} />
      <Route path="/verify" exact component={ () => <VerifyEmail />} />
      <Route path="/notVerified" exact component={ () => <EmailNotVerified />} />
      <Route path="/forgotPassword" exact component={ () => <ForgotPassword />} />
      <Route path="/resetPassword" exact component={ () => <ResetPassword />} />

      <Route exact path="/">
        <Redirect to="/tool" />
      </Route>

      </Router>

    </div>
  );
}

export default App;
