import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles"

import Button from '@material-ui/core/Button'
import GridContainer from "components/Grid/GridContainer.js";
import {CircularProgress, Backdrop} from '@mui/material';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import GridItem from "components/Grid/GridItem.js"

import { Upload } from 'antd'
import AWS from 'aws-sdk'

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Autocomplete } from "@material-ui/lab";
import { authors } from "../../assets/extras/constants"

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  })

const s3 = new AWS.S3({
  params: { Bucket: 'oceanit-related-work'},
  region: 'us-gov-east-1',
})

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '200px',
    margin: theme.spacing(3, 0, 2),
  },

  option: {
    width: '400px',
    margin: theme.spacing(3, 0, 2),
  },
}))

const base_url = process.env.REACT_APP_BASE_URL;

export default function RelatedWork() {
    var accountJSON = {}

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const currentUser = checkUser(history)

    const [fileRefs, setFileRefs] = useState([
        useRef(null)
    ])

    const [defaultFileLists, setDefaultFileLists] = useState([
        [],
        [],
        [],
        []
    ])

    const [formRefs, setFormRefs] = useState([
        useRef(null), 
        useRef(null),
        useRef(null), 
        useRef(null),
        useRef(null), 
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ])

    let fileNames = [
        'Related work paragraph/images (optional)'
    ]

    let fileTypes = [
        '.docx, .jpeg, .png'
    ]

    let departmentItemValues = [
        <MenuItem value={'Select Department'}>Select Department</MenuItem>,

        <MenuItem value={'DOD'}>DOD</MenuItem>,
        <MenuItem value={'DOE'}>DOE</MenuItem>,

        <MenuItem value={'DOH'}>DOH</MenuItem>,
        <MenuItem value={'HTDC'}>HTDC</MenuItem>,
        <MenuItem value={'NIH'}>NIH</MenuItem>,

        <MenuItem value={'NOAA'}>NOAA</MenuItem>,
        <MenuItem value={'NASA'}>NASA</MenuItem>,
        <MenuItem value={'NSF'}>NSF</MenuItem>,

        <MenuItem value={'NIST'}>NIST</MenuItem>,
        <MenuItem value={'TAQA'}>TAQA</MenuItem>,
        <MenuItem value={'INIT'}>INIT</MenuItem>,

        <MenuItem value={'DHP'}>DHP</MenuItem>,
        <MenuItem value={'EBRAP'}>EBRAP</MenuItem>,
        <MenuItem value={'BARDA'}>BARDA</MenuItem>,

        <MenuItem value={'DOT'}>DOT</MenuItem>,
        <MenuItem value={'EPA'}>EPA</MenuItem>,
        <MenuItem value={'CDMRP'}>CDMRP</MenuItem>,

        <MenuItem value={'USDA'}>USDA</MenuItem>,
        <MenuItem value={'PRIVATE_FIRM'}>PRIVATE FIRM</MenuItem>,
    ]

    let grantItemValues = [
        <MenuItem value={'Select Grant'}>Select Grant</MenuItem>,

        <MenuItem value={'SBIR_PH_1'}>SBIR Phase I</MenuItem>,
        <MenuItem value={'SBIR_PH_2'}>SBIR Phase II</MenuItem>,

        <MenuItem value={'STTR_PH_1'}>STTR Phase I</MenuItem>,
        <MenuItem value={'STTR_PH_2'}>STTR Phase II</MenuItem>,

        <MenuItem value={'OTHER'}>OTHER</MenuItem>,
    ]

    let agencyItemValues = [
        <MenuItem value={'Select Agency'}>Select Agency</MenuItem>,

        <MenuItem value={'DARPA'}>DARPA</MenuItem>,
        <MenuItem value={'ONR'}>ONR</MenuItem>,
        <MenuItem value={'DHA'}>DHA</MenuItem>,

        <MenuItem value={'NAVSEA'}>NAVSEA</MenuItem>,
        <MenuItem value={'NAVY'}>NAVY</MenuItem>,
        <MenuItem value={'ARMY'}>ARMY</MenuItem>,

        <MenuItem value={'AIR_FORCE'}>AIR FORCE</MenuItem>,
        <MenuItem value={'MDA'}>MDA</MenuItem>,
        <MenuItem value={'NAVAIR'}>NAVAIR</MenuItem>,

        <MenuItem value={'AFRL'}>AFRL</MenuItem>,
        <MenuItem value={'OSD'}>OSD</MenuItem>,
        <MenuItem value={'CBD'}>CBD</MenuItem>,

        <MenuItem value={'NGA'}>NGA</MenuItem>,
        <MenuItem value={'AFOSR'}>AFOSR</MenuItem>,
        <MenuItem value={'ARPA'}>ARPA</MenuItem>,

        <MenuItem value={'SOCOM'}>SOCOM</MenuItem>,
        <MenuItem value={'USAF'}>USAF</MenuItem>,
        <MenuItem value={'PACOM'}>PACOM</MenuItem>,

        <MenuItem value={'NSA'}>NSA</MenuItem>,
        <MenuItem value={'DISA'}>DISA</MenuItem>,
        <MenuItem value={'DTRA'}>DTRA</MenuItem>,

        <MenuItem value={'DHS'}>DHS</MenuItem>,
        <MenuItem value={'OTHER'}>OTHER</MenuItem>
    ]

    let fieldItemValues = [
        <MenuItem value={'Select Field'}>Select Field</MenuItem>,

        <MenuItem value={'MATERIALS/ENERGY'}>MATERIALS/ENERGY</MenuItem>,
        <MenuItem value={'BIOMEDICAL/BIOLOGY'}>BIOMEDICAL/BIOLOGY</MenuItem>,

        <MenuItem value={'SPACE/ASTROPHYSICS/ASTRONOMY'}>SPACE/ASTROPHYSICS/ASTRONOMY</MenuItem>,
        <MenuItem value={'OCEAN SCIENCE/ENGINEERING'}>OCEAN SCIENCE/ENGINEERING</MenuItem>,

        <MenuItem value={'CIVIL ENGINEERING'}>CIVIL ENGINEERING</MenuItem>,
        <MenuItem value={'CYBER SECURITY'}>CYBER SECURITY</MenuItem>,

        <MenuItem value={'AI/MACHINE LEARNING'}>AI/MACHINE LEARNING</MenuItem>,
        <MenuItem value={'COMMUNICATION/SENSOR'}>COMMUNICATION/SENSOR</MenuItem>,

        <MenuItem value={'OTHER'}>OTHER</MenuItem>,
    ]

    const [selectedRelatedWork, setSelectedRelatedWork] = useState({})

    const [clientFiles, setClientFiles] = useState({})
    const [s3Files, setS3Files] = useState({})

    const [isLoading, setIsLoading] = useState(false)

    const [isInitial, setIsInitial] = useState(true)
    const [updatingRelatedWork, setUpdatingRelatedWork] = useState(false)

    const [isConfirmDetails, setIsConfirmDetails] = useState(false)
    const [formRefsData, setFormRefsData] = React.useState({})

    var [fieldValue, setFieldValue] = React.useState(['Select Field'])
    var [departmentValue, setDepartmentValue] = React.useState('Select Department')

    var [grantValue, setGrantValue] = React.useState('Select Grant')
    var [agencyValue, setAgencyValue] = React.useState('Select Agency')

    const [showAgencyItems, setShowAgencyItems] = React.useState(false)
    var [relatedWorkSensitiveChecked, setRelatedWorkChecked] = React.useState(false)

    var [investigatorName, setInvestigatorName] = React.useState('')

    React.useEffect(() => {
        if (currentUser !== undefined) {
            checkForRelatedWorkUpdate()
        }
    }, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
                pathname: '/signin',
                state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            accountJSON = JSON.parse(accountDetails)

            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function checkForRelatedWorkUpdate() {
        if (accountJSON.role === 'GUEST') {
            alert('Your user access does not allow uploading related works. Please contact admin for further details.')
            history.push('/tool/user/')
        }

        if (location.state !== null) {

            let relatedWork = location.state.relatedWork

            setUpdatingRelatedWork(true)
            updateSelectedRelatedWork(relatedWork)
        }
        else {
            setTimeout(() => {
                logFirebaseEvent('Upload -> Related Works -> Arrived')
                setIsInitial(true)
            }, 100);
        }
    }

    const updateSelectedRelatedWork = (relatedWork) => {
        setSelectedRelatedWork(relatedWork)

        setS3Files(relatedWork.files)
        setClientFiles(relatedWork.client_files)
    }

    useEffect(() => {
        setIsInitial(false)
        checkExisitingFiles(false, fileNames)

      }, [clientFiles])

    const handleFieldValueChange = (event) => {
        let values = event.target.value

        if (values[0] === 'Select Field') {
            values.shift()
        }
        fieldValue = values
    }

    const handleDepartmentValueChange = (event) => {
        let value = event.target.value
        setDepartmentValue(value)

        if (value === 'DOD') {
            setShowAgencyItems(true)
        } 
        else {
            if (showAgencyItems === true) {
                setShowAgencyItems(false)
            }
        }
    }

    const handleGrantValueChange = (event) => {
        grantValue = event.target.value
    }

    const handleAgencyValueChange = (event) => {
        agencyValue = event.target.value
    }

    const handleRelatedWorkSensitiveChange = (event) => {
        relatedWorkSensitiveChecked = event.target.checked
    }

    const handleInvestigatorNameChange = (event, value) => {
        investigatorName = value.title
    }

    const handleConfirmDetails = (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        checkFields(data)
        logFirebaseEvent('Upload -> Related Works -> Confirm Tapped')
    }

    function fetchFormRefsData() {
        let data = {
            'project_title' : formRefs[0].current.value,
            'proposal_number' : formRefs[1].current.value,
            'tpoc_name' : formRefs[2].current.value,

            'nickname' : formRefs[3].current.value,
            'principal_investigator' : formRefs[4].current.value,

            'tpoc_contact_information' : formRefs[5].current.value,
            'project_link' : formRefs[6].current.value,
            'keywords' : formRefs[7].current.value,
            'related_work_text' : formRefs[8].current.value,

            'grant' : grantValue,
            'department' : departmentValue,

            'field' : fieldValue,
            'is_related_work_sensitive' : relatedWorkSensitiveChecked
        }
        return data
    }

    function restoreFormRefsValue(data) {
        setFieldValue(data.field)
        setGrantValue(data.grant)

        setDepartmentValue(data.department)
        setRelatedWorkChecked(data.is_related_work_sensitive)

        formRefs[0].current.value = data.project_title
        formRefs[1].current.value = data.proposal_number
        formRefs[2].current.value = data.tpoc_name

        formRefs[3].current.value = data.nickname
        formRefs[4].current.value = data.principal_investigator

        formRefs[5].current.value = data.tpoc_contact_information
        formRefs[6].current.value = data.project_link

        formRefs[7].current.value = data.keywords
        formRefs[8].current.value = data.related_work_text
    }

    function checkFields(data) {
        let projectTitle = data.get('project_title')
        let proposalNumber = data.get('proposal_number')

        if (projectTitle.length === 0 || !projectTitle.replace(/\s/g, '').length) {
            alert('Please enter valid detail for Project Title')
            return
        }

        if (proposalNumber.length === 0 || !proposalNumber.replace(/\s/g, '').length) {
            alert('Please enter valid detail for Proposal Number')
            return
        }

        if (departmentValue === 'Select Department') {
            alert('Please select a valid value for Department.')
            return
        }

        if (fieldValue[0] === 'Select Field') {
            alert('Please select a valid value for Field of Work.')
            return
        }

        restoreFormDataValues(data)

        if (!updatingRelatedWork) {
            prepareDataForCreateRelatedWorkAPI(data)
        }
        else {
            prepareDataForUpdateRelatedWorkAPI(data)
        }
    }

    // function padTo2Digits(num) {
    //     return num.toString().padStart(2, '0');
    // }

    // function formatDate(date) {
    //     return [
    //         date.getFullYear(),
    //         padTo2Digits(date.getMonth() + 1),
    //         padTo2Digits(date.getDate()),
    //     ].join('-');
    // }

    const prepareDataForCreateRelatedWorkAPI = (data) => {
        let grant = grantValue
        let agency = agencyValue

        if (agency === 'Select Agency') {
            agency = 'OTHER'
        }

        if (agency === 'Select Grant') {
            grant = 'OTHER'
        }

        let keywordString = formRefs[7].current.value
        let keywords = keywordString.split(',')

        let proposalNumbersString = formRefs[1].current.value
        let proposalNumbers = proposalNumbersString.split(',')

        var relatedWorkData = {
            'owner' : accountJSON.id,
            'organisation' : departmentValue,

            'field': fieldValue,
            'grant' : grant,

            'agency' : agency,
            'is_sensitive': relatedWorkSensitiveChecked,

            'title' : formRefs[0].current.value,
            'internal_proposal_ids' : proposalNumbers,
            'tpoc_name' : formRefs[2].current.value,

            'nickname' : formRefs[3].current.value,
            'principal_investigator' : formRefs[4].current.value,

            'tpoc_contact_information' : formRefs[5].current.value,
            'project_link' : formRefs[6].current.value,

            'keywords' : keywords,
            'related_work_text' : formRefs[8].current.value
        }

        setIsLoading(true)
        fireCreateRelatedWorkAPI(relatedWorkData, data)
    }

    const prepareDataForUpdateRelatedWorkAPI = (data) => {
        let grant = grantValue
        let agency = agencyValue

        if (agency === 'Select Agency') {
            agency = 'OTHER'
        }

        if (agency === 'Select Grant') {
            grant = 'OTHER'
        }

        let keywordString = formRefs[7].current.value
        let keywords = keywordString.split(',')

        let proposalNumbersString = formRefs[1].current.value
        let proposalNumbers = proposalNumbersString.split(',')

        var relatedWorkData = {
            'related_work' : selectedRelatedWork.id,
            'organisation' : departmentValue,

            'field': fieldValue,
            'grant' : grant,

            'agency' : agency,
            'is_sensitive': relatedWorkSensitiveChecked,

            'title' : formRefs[0].current.value,
            'internal_proposal_ids' : proposalNumbers,
            'tpoc_name' : formRefs[2].current.value,

            'nickname' : formRefs[3].current.value,
            'principal_investigator' : formRefs[4].current.value,

            'tpoc_contact_information' : formRefs[5].current.value,
            'project_link' : formRefs[6].current.value,

            'keywords' : keywords,
            'related_work_text' : formRefs[8].current.value
        }

        setIsLoading(true)
        fireUpdateRelatedWorkAPI(relatedWorkData, data)
    }

    const fireCreateRelatedWorkAPI = (relatedWork, data) => {
        fetch(`${base_url}v1/related_work/?id=${accountJSON.id}`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(relatedWork),
        })
        .then(response => {
            response.json()
            .then(responseJson => {

                if (response.ok) {
                    let relatedWorkResponse = responseJson.data.data
                    uploadToS3(relatedWorkResponse)
                } 
                else {
                    setIsLoading(false)

                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)

                    restoreFormDataValues(data)
                }
            })
        })
    }

    const fireUpdateRelatedWorkAPI = (relatedWork, data) => {
        fetch(`${base_url}v1/related_work/?id=${accountJSON.id}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(relatedWork),
        })
        .then(response => {
            response.json()
            .then(responseJson => {

                if (response.ok) {
                    let relatedWorkResponse = responseJson.data.data
                    uploadToS3(relatedWorkResponse)
                } 
                else {
                    setIsLoading(false)

                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)

                    restoreFormDataValues(data)
                }
            })
        })
    }

    // Used when Related Work API errors
    function restoreFormDataValues(data) {
        setFieldValue(fieldValue)
        setGrantValue(grantValue)

        setDepartmentValue(departmentValue)
        setRelatedWorkChecked(relatedWorkSensitiveChecked)

        formRefs[0].current.value = data.get('project_title')
        formRefs[1].current.value = data.get('proposal_number')
        formRefs[2].current.value = data.get('tpoc_name')

        formRefs[3].current.value = data.get('nickname')
        formRefs[4].current.value = data.get('principal_investigator')

        formRefs[5].current.value = data.get('tpoc_contact_information')
        formRefs[6].current.value = data.get('project_link')

        formRefs[7].current.value = data.get('keywords')
        formRefs[8].current.value = data.get('related_work_text')
    }

    const firePatchRelatedWorkAPI = (related_work_id, updatingS3Files=false) => {
        let relatedWork = {
            'related_work' : related_work_id,
            'files' : s3Files,
            'client_files' : clientFiles
        }

        fetch(`${base_url}v1/related_work/?id=${accountJSON.id}`, {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(relatedWork),
        })
        .then(response => {
            response.json()
            .then(responseJson => {

                if (response.ok) {
                    if (updatingS3Files === true) {
                        populateFilesFromBackend(fileNames)
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                        setIsConfirmDetails(false)

                        setIsInitial(true)
                        alert('Files have been uploaded successfully!')

                        history.push('/tool/user/related_works')
                    }
                }
                else {
                    let errorMessage = String(responseJson.error.message)
                    alert(errorMessage)
                }
            })
        })
    }

    function populateKeysForClientAndS3Files() {
        fileNames.forEach((fileName, index) => {

            clientFiles[fileName.toLowerCase()] = []
            s3Files[fileName.toLowerCase()] = []

            setClientFiles(clientFiles)
            setS3Files(s3Files)
        })
    }

    async function populateMissingKeysInClientFiles() {
        let templateFileNames = []

        fileNames.forEach((file, index) => {
            let fileName = file.toLowerCase()
            templateFileNames.push(fileName)

            if (clientFiles[fileName.toLowerCase()] === undefined) {
                clientFiles[fileName.toLowerCase()] = []
                s3Files[fileName.toLowerCase()] = []
            }
        })

        Object.keys(clientFiles).forEach((fileName, index) => {

            if (!templateFileNames.includes(fileName)) {    

                delete clientFiles[fileName]
                delete s3Files[fileName]
            }
        })

        setClientFiles(clientFiles)
        setS3Files(s3Files)
    }

    async function uploadToS3(relatedWork) {
        var count = 0
        const s3KeyID = relatedWork.s3_key_id

        var isFinal = false
        var totalCount = fileNames.length

        if (Object.keys(clientFiles).length === 0) {
            populateKeysForClientAndS3Files()
            firePatchRelatedWorkAPI(relatedWork.id)
            return
        }

        if (clientFiles.length !== fileNames.length) {
            await populateMissingKeysInClientFiles()
        }

        for (const [key, filesToUpload] of Object.entries(clientFiles)) {
            count += 1
            var filesUploaded = []

            if (count === totalCount) {
                isFinal = true
            }

            if (filesToUpload.length > 0) {
                filesUploaded = await iterateOver(filesToUpload, s3KeyID)
            }

            if (updatingRelatedWork === true) {
                if (s3Files[key] === undefined) {
                    s3Files[key] = filesUploaded
                }
                else {
                    let s3FileList = s3Files[key]
                    s3Files[key] = s3FileList.concat(filesUploaded)
                }
            }
            else {
                s3Files[key] = filesUploaded
            }

            if (isFinal === true) {
                setS3Files(s3Files)
                firePatchRelatedWorkAPI(relatedWork.id)
            }
        }
    }

    const iterateOver = async (filesToUpload, s3KeyID) => {
        var filesUploaded = []

        for (let i = 0; i < filesToUpload.length; i++) {
            var data = null
            const file = filesToUpload[i]

            try {
                data = file.originFileObj.slice(0, file.size)
            }
            catch (err) {
                continue
            }
            
            const fileKey = `${accountJSON.email}/${s3KeyID}/${file.name}`
            const params = {
                Body: data,
                Bucket: 'oceanit-related-work',
                ContentType: `${file.type}`,
                Key: fileKey
            }

            filesUploaded.push(fileKey)
            await s3.putObject(params).promise()
        }
        return filesUploaded
    }

    const checkAndDisplayTopicDetails = () => {
        logFirebaseEvent('Upload -> Related Works -> Files Submit Tapped')

        setIsConfirmDetails(true)
        setIsInitial(false)

        setTimeout(() => {
            if (Object.keys(formRefsData).length !== 0) {
                restoreFormRefsValue(formRefsData)
            }
            updateFormData()
        }, 100)
    }

    // When user enters Confirm Related Work Details section.
    const updateFormData = () => {
        if (updatingRelatedWork === true) {

            setFieldValue(selectedRelatedWork.field)
            setGrantValue(selectedRelatedWork.grant)

            setDepartmentValue(selectedRelatedWork.organisation)
            setRelatedWorkChecked(selectedRelatedWork.is_related_work_sensitive)

            formRefs[0].current.value = selectedRelatedWork.title
            formRefs[1].current.value = selectedRelatedWork.internal_proposal_ids.toString()
            formRefs[2].current.value = selectedRelatedWork.tpoc_name

            formRefs[3].current.value = selectedRelatedWork.nickname
            formRefs[4].current.value = selectedRelatedWork.principal_investigator

            formRefs[5].current.value = selectedRelatedWork.tpoc_contact_information
            formRefs[6].current.value = selectedRelatedWork.project_link

            formRefs[7].current.value = selectedRelatedWork.keywords.toString()
            formRefs[8].current.value = selectedRelatedWork.related_work_text
        }
        setFormRefs(formRefs)
    }

    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess('OK')
        }, 0)
    }

    const checkExisitingFiles = (isCache, fileNameList=null) => {
        if (Object.keys(clientFiles).length === 0) {
            return
        }
        else {
            if (isCache === true) {
                populateFilesFromCache()
            }
            else {
                populateFilesFromBackend(fileNameList)
            }
        }
    }

    const populateFilesFromCache = () => {
        var index = 0

        for (const [key, value] of Object.entries(clientFiles)) {
            defaultFileLists[index] = value
            index += 1
        }
        setDefaultFileLists(defaultFileLists)
        setIsInitial(true)
    }

    const populateFilesFromBackend = (fileNameList) => {
        let fileListNames = fileNameList.map(name => name.toLowerCase())

        for (const [key, value] of Object.entries(clientFiles)) {
            let elementIndex = fileListNames.indexOf(key)
            defaultFileLists[elementIndex] = value
        }
        setDefaultFileLists(defaultFileLists)
        setIsInitial(true)
    }

    const handleFileUploadChange = ({file}) => {
        if (file.status !== 'uploading') {

            if (file.status === 'done') {
                logFirebaseEvent(`Upload -> Related Works -> File Upload Tapped ${file.name}`)
            }

            fileNames.forEach((fileName, index) => {
                let sectionFiles = fileRefs[index].current.fileList
                let updatedSectionFiles = checkSectionFiles(sectionFiles, file)

                clientFiles[fileName.toLowerCase()] = updatedSectionFiles
                defaultFileLists[index] = updatedSectionFiles
            })

            setTimeout(() => {
                setIsLoading(true)
                setIsLoading(false)

                if (updatingRelatedWork) {
                    checkExisitingFiles(false, fileNames)
                }
                else {
                    checkExisitingFiles(true)
                }
            }, 500)
        }

        if (file.status === 'removed' && updatingRelatedWork === true) {
            logFirebaseEvent(`Upload -> Related Works -> File Remove Tapped ${file.name}`)
            handleFileRemoveChange(file)
        }

        setClientFiles(clientFiles)
    }

    const checkSectionFiles = (sectionFiles, file) => {
        var repeatCount = 0
        var repeatIndex = -1

        sectionFiles.forEach((sectionFile, index) => {
            if (file.name === sectionFile.name) {

                if (repeatCount === 0) {
                    repeatCount += 1
                    repeatIndex = index
                }
                else {
                    sectionFiles.splice(repeatIndex, 1)
                }
            }
        })
        return sectionFiles
    }

    const handleFileRemoveChange = async (file) => {
        var objectKey = ''

        for(const [_, s3FileObject] of Object.entries(s3Files)) {

            if (s3FileObject.length) {
                for (const s3File of s3FileObject) {

                    if (s3File.includes(file.name)) {
                        let index = s3FileObject.indexOf(s3File)
                        s3FileObject.splice(index, 1)
                        objectKey = s3File

                        break
                    }
                }
            }
        }

        setS3Files(s3Files)
        setIsLoading(true)

        if (objectKey !== '') {
            await removeFileFromS3(objectKey)
        }
        else {
            setTimeout(() => {
            setIsLoading(false)
            populateFilesFromBackend(fileNames)
            }, 500)
        }
    }

    async function removeFileFromS3(objectKey) {
        const params = {
            Bucket: 'oceanit-related-work',
            Key: objectKey
        }

        await s3.deleteObject(params).promise()
        firePatchRelatedWorkAPI(selectedRelatedWork.id, true)
    }

    const handleRelatedWorkDetailsConfirmBack = () => {
        if (updatingRelatedWork) {
            checkExisitingFiles(false, fileNames)
        }
        else {
            checkExisitingFiles(true)
        }
        setIsInitial(true)
        setIsConfirmDetails(false)

        let formData = fetchFormRefsData()
        setFormRefsData(formData)
    }

    const RelatedWorkDetailsComponent = () => {
        return (
            <div>
                <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
                >
                <CircularProgress />
                </Backdrop>

                <GridContainer
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        startIcon={<ArrowBackIcon/>}
                        onClick={handleRelatedWorkDetailsConfirmBack}
                        style={{'position' : 'absolute', 'top' : '8%', 'left' : '2%', 'transform' : 'translateY(-50%)'}}
                    >
                    Back
                    </Button>
                    <InputLabel style={{fontWeight: 'bold', fontSize: '1.5em'}}>Confirm Related Work Details</InputLabel>

                    <form className={classes.form} onSubmit={handleConfirmDetails}>
                        
                        <FormControlLabel
                            style={{
                                width: '100%',
                                marginRight: '10px'
                            }}
                            control={
                                <Checkbox
                                    style={{
                                        transform: 'scale(1.4)',
                                    }}
                                    defaultChecked={relatedWorkSensitiveChecked}
                                    onChange={handleRelatedWorkSensitiveChange}
                                    name="is_rw_sensitive"
                                    color="primary"
                                />
                            }
                            label="Related Work Contains Sensitive Information"
                        />

                        <FormControl fullWidth>
                            <Select
                                id="department"
                                defaultValue={departmentValue}
                                label="Department"
                                style={{textAlign: 'left', marginTop: '20px'}}
                                onChange={handleDepartmentValueChange}
                            >
                                {departmentItemValues}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <Select
                                id="field"
                                multiple
                                defaultValue={fieldValue}
                                label="Field"
                                style={{textAlign: 'left', marginTop: '20px'}}
                                onChange={handleFieldValueChange}
                            >
                                {fieldItemValues}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <Select
                                id="grant"
                                defaultValue={grantValue}
                                label="Grant"
                                style={{textAlign: 'left', marginTop: '20px'}}
                                onChange={handleGrantValueChange}
                            >
                                {grantItemValues}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            {!showAgencyItems ? null : (
                                <Select
                                    id="agency"
                                    defaultValue={agencyValue}
                                    label="Agency"
                                    style={{textAlign: 'left', marginTop: '20px'}}
                                    onChange={handleAgencyValueChange}
                                >
                                    {agencyItemValues}
                                </Select>
                            )}
                        </FormControl>

                        <TextField
                            inputRef={formRefs[0]}
                            required
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="project_title"
                            name="project_title"
                            placeholder="Project Title *"
                        />

                        <TextField
                            inputRef={formRefs[1]}
                            required
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="proposal_number"
                            name="proposal_number"
                            placeholder="Proposal Number(s) (PT#) *"
                        />

                        <TextField
                            inputRef={formRefs[2]}
                            required
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="tpoc_name"
                            name="tpoc_name"
                            placeholder="TPOC Name *"
                        />

                        <TextField
                            inputRef={formRefs[3]}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="nickname"
                            name="nickname"
                            placeholder="Project Nickname"
                        />

                        <Autocomplete
                            defaultValue={investigatorName}
                            options={authors}
                            getOptionLabel={(option) => option.title}
                            onChange={handleInvestigatorNameChange}
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    inputRef={formRefs[4]}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    id="principal_investigator"
                                    name="principal_investigator"
                                    placeholder="Principal Investigator"
                                />
                            )}
                        />

                        <TextField
                            inputRef={formRefs[5]}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="tpoc_contact_information"
                            name="tpoc_contact_information"
                            placeholder="TPOC Contact Information"
                        />

                        <TextField
                            inputRef={formRefs[6]}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            id="project_link"
                            name="project_link"
                            placeholder="Project Link"
                        />

                        <TextField
                            inputRef={formRefs[7]}
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="normal"
                            id="keywords"
                            name="keywords"
                            placeholder="Keywords"
                        />

                        <TextField
                            inputRef={formRefs[8]}
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="normal"
                            id="related_work_text"
                            name="related_work_text"
                            placeholder="Related Work Text"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Confirm
                        </Button>
                    </form>
                </GridContainer>
            </div>
        )
    }

    const FileUploadComponent = () => {
        return (
            <div>
                <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
                >
                <CircularProgress />
                </Backdrop>

                <GridContainer
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                >

                <GridItem xs={12} sm={12} md={6}>
                    <Card>

                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Related Work Files</h4>
                    </CardHeader>

                    <br></br>

                    <CardBody>
                        {fileNames.map((fileName, index) => (
                        <div>
                            <div align='left'>
                            <InputLabel style={{textAlign: 'left', fontWeight: 'bold'}}>{fileName}</InputLabel>
                            <p>Supported file types: {fileTypes[index]}</p>
                            <Upload
                                id={fileName}
                                ref={fileRefs[index]}
                                defaultFileList={defaultFileLists[index]}
                                multiple={true}
                                customRequest={dummyRequest}
                                onChange={handleFileUploadChange}
                            >
                                <Button 
                                    variant="outlined"
                                >
                                Click to Upload or Drop Here
                                </Button>
                            </Upload>
                            </div>
                            <br></br>
                        </div>
                        ))}
                    </CardBody>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{alignSelf: 'center'}}
                        onClick={() => checkAndDisplayTopicDetails()}
                    >
                        Submit
                    </Button>

                    </Card>
                </GridItem>
                </GridContainer>
            </div>
        )
    }

    return (

    <div>
        { isInitial ? <FileUploadComponent/> : null}
        { isConfirmDetails ? <RelatedWorkDetailsComponent/> : null }
    </div>

    );
}

