import React, { useState, useRef } from "react";
import { useHistory } from 'react-router-dom'

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import BiosketchTable from "components/Table/BiosketchTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import SearchBar from "./SearchBar";

// @material-ui/core components
import {
  Backdrop,
  TextField,
  InputAdornment,
  makeStyles,
  CircularProgress,
  IconButton
} from "@material-ui/core"

import { Autocomplete } from "@material-ui/lab";
import { SearchOutlined } from "@material-ui/icons";
import { authors } from "assets/extras/constants";

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const base_url = process.env.REACT_APP_BASE_URL;

export default function BiosketchTableList() {
  const history = useHistory()
  const currentUser = checkUser(history)
  const classes = useStyles();

  const searchBarRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const [tableData, setTableData] = useState([])
  const [allTopics, setAllTopics] = useState([])

  var [searchKeys, setSearchKeys] = useState()
  const [searchQuery, setSearchQuery] = useState('')

  React.useEffect(() => {
    if (currentUser !== undefined) {
      fetchData()
    }
  }, [])

  function checkUser(history) {
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push({
        pathname: '/signin',
        state: {urlPath: '/tool/browse/proposal'},
      })
      return

    } else {
      const accountJSON = JSON.parse(accountDetails)
      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }
      return accountJSON
    }
  }

  function fetchData() {
    logFirebaseEvent('Browse -> Biosketch -> Arrived')
    
    setLoading(true)
    fetch(`${base_url}v1/biosketches/?id=${currentUser.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          setTableData(responseJson.data.data)
          setAllTopics(responseJson.data.data)
        }
      })
    })
  }

  function revertToDefaultData() {
    setSearchQuery('')
    setTableData(allTopics)
  }

  function checkKeywordEmpty() {
    let search = searchBarRef.current.value

    if (search.length === 0) {
      revertToDefaultData()
    }
  }

  const handleSearchKeysChange = (event, value) => {
    if (value !== null) {

      if (value.title !== undefined) {
        searchKeys = value.title
        searchKeyword(searchKeys)
      }
      else {
        searchBarRef.current.value = value
        searchKeyword()
      }
    }
    else {
      revertToDefaultData()
    }
  }

  const SearchButton = () => {
    return (
        <IconButton onClick={searchKeyword}>
            <SearchOutlined />
        </IconButton>
    )
  }

  const LoadingFade = () => {
    return (
      <Backdrop open={loading} style={{zIndex: '5'}}>
        <CircularProgress />
      </Backdrop>
    )
  }

  function searchKeyword(searchKeys=null) {
    var search = searchKeys

    if (searchKeys === null) {
      search = searchBarRef.current.value
    }

    if (search.length === 0 || !search.replace(/\s/g, '').length) {
      alert('Please enter a valid search.')
      return
    }

    let keywords = search.split(',')
    let searchParams = {
      'keywords' : keywords
    }

    fireSearchAPI(searchParams)
  }

  function fireSearchAPI(searchParams) {
    setLoading(true)
    fetch(`${base_url}v1/biosketches/search/?id=${currentUser.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchParams)
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
          setSearchQuery('')
        } 
        else {
          setTableData(responseJson.data.data)
        }
      })
    })
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        
        <h1>Biosketches</h1>
        <LoadingFade />

        <div style={{position: 'absolute', top: 50, right: 0}}>
          <p>
          All information on this site is proprietary to Oceanit Laboratories, Inc. Material copied or downloaded from this site is only to be used by Oceanit employees and is not to be shared with those outside of Oceanit Laboratories, Inc. Some information on this site is restricted to those with certain access requirements.
          </p>
        </div>

        <Autocomplete
          freeSolo={true}
          value={searchQuery}
          defaultValue={searchKeys}
          options={authors}
          getOptionLabel={(option) => option.title}
          onChange={handleSearchKeysChange}
          onAbort={revertToDefaultData}
          disableClearable
          renderInput={(params) => (
              <TextField
                {...params}
                type={'search'}
                value={searchQuery}
                inputRef={searchBarRef}
                fullWidth
                variant="outlined"
                margin="normal"
                id="search_bar"
                name="search_bar"
                placeholder="Search"
                onChange={checkKeywordEmpty}
                InputProps={{ ...params.InputProps, 
                  startAdornment: ( <InputAdornment position="start"> <SearchButton /> 
                  </InputAdornment> ),
                  disableUnderline: true }}
            />
          )}
        />

        <Card>
          <CardBody>
            <BiosketchTable
              tableHeaderColor="primary"
              tableHead={['Person Name', 'Field of Work', 'Work Location', 'Expertise', 'Uploaded By', 'Updated On', '']}
              tableData={tableData}
              userID={currentUser.id}
            />
          </CardBody>
        </Card>

      </GridItem>
      
    </GridContainer>
  );
}