import React, { useRef } from "react"
import {
  useHistory, 
  useLocation 
} from 'react-router-dom'
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ProposalTable from "components/Table/ProposalsTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// @material-ui/core components
import { 
  Backdrop, 
  Button, 
  CircularProgress, 
  Chip,
  Checkbox,
  Grid,
  InputAdornment,
  IconButton,
  Menu,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemIcon,
  TextField, 
  Typography, 
  Collapse
 } from "@material-ui/core";

import {
  ExpandMore, 
  ExpandLess, 
  SearchOutlined, 
  HelpOutline} from '@material-ui/icons';

import { Autocomplete } from "@material-ui/lab";
import { authors } from "assets/extras/constants";

import { CSVLink } from "react-csv";
import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

const base_url = process.env.REACT_APP_BASE_URL;

export default function ProposalTableList() {
  const history = useHistory()
  const location = useLocation()
  const currentUser = checkUser(history)

  const searchBarRef = useRef(null)
  const urlSearchparams = new URLSearchParams(location.search)

  const [loading, setLoading] = React.useState(false)
  const [showHelperText, setShowHelperText] = React.useState(false)

  const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false)

  const [openGridArray, setOpenGridArray] = React.useState([false, false, false, false, false])
  const [filtersSelected, setFiltersSelected] = React.useState([])

  const [excelData, setExcelData] = React.useState([])

  var [tableData, setTableData] = React.useState([])
  const [allTopics, setAllTopics] = React.useState([])

  const [totalCount, setTotalCount] = React.useState(0)
  const [cacheTotalCount, setCacheTotalCount] = React.useState(0)
  const [lastPageIndex, setLastPageIndex] = React.useState(0)

  const [userDetails, setUserDetails] = React.useState({})
  const [searchResults, setSearchResults] = React.useState([])

  var [searchKeys] = React.useState()

  var [searchParams, setSearchParams] = React.useState({})
  const [searchQuery, setSearchQuery] = React.useState('')

  const [onDeviceFilterActive, setOnDeviceFilterActive] = React.useState(false)
  const [onDeviceSearchActive, setOnDeviceSearchActive] = React.useState(false)

  const [checkBoxesData, setCheckBoxesData] = React.useState({})
  const [clearFilterButtonVisibility, setClearFilterButtonVisibility] = React.useState('none')

  let headCellData = [
    // {id: 'solicitation_id', title: 'Solicitation ID', numeric: false, disablePadding: false},
    {id: 'pt', title: 'PT#', numeric: false, disablePadding: false},

    {id: 'title', title: 'Title', numeric: false, disablePadding: false},
    // {id: 'organisation', title: 'Organisation', numeric: false, disablePadding: false},
    {id: 'lead_name', title: 'Proposal Lead', numeric: false, disablePadding: false},

    {id: 'keywords', title: 'Keywords', numeric: false, disablePadding: false},
    {id: 'due_date', title: 'Due Date', numeric: true, disablePadding: false},
    {id: 'last_updated', title: 'Last Updated', numeric: true, disablePadding: false},
    {id: 'status', title: 'Status', numeric: false, disablePadding: false},
    {id: '', title: 'Related Proposals', numeric: true, disablePadding: false},
    // {id: '', title: 'Related Works', numeric: true, disablePadding: false},
  ]

  let listItemValues = [
    'Department',
    'Agency',
    'Grant',
    'Status',
    'Submission Year'
  ]

  let departmentValues = [

    'DOD',
    'DOE',

    'DOH',
    'HTDC',
    'NIH',

    'NOAA',
    'NASA',
    'NSF',

    'NIST',
    'TAQA',
    'INIT',

    'DHP',
    'EBRAP',
    'BARDA',

    'DOT',
    'EPA',
    'CDMRP',

    'USDA',
    'PRIVATE_FIRM',
  ]

  let agencyValues = [

    'DARPA',
    'ONR',
    'DHA',

    'NAVSEA',
    'NAVY',
    'ARMY',

    'AIR_FORCE',
    'MDA',
    'NAVAIR',

    'AFRL',
    'OSD',
    'CBD',

    'NGA',
    'AFOSR',
    'ARPA',

    'SOCOM',
    'USAF',
    'PACOM',

    'NSA',
    'DISA',
    'DTRA',

    'DHS',
    'OTHER'
  ]

  let grantValues = [
    'SBIR PH 1',
    'SBIR PH 2',

    'STTR PH 1',
    'STTR PH 2',

    'Other',
  ]

  let proposalStatusValues = [
    'AWARDED',
    'REJECTED',
    'UNDER EVALUATION'
  ]

  let submissionYear = [
    '2022',
    '2021',
    '2020',

    '2019',
    '2018',
    '2017',

    '2016',
    '2015',
    '2014',

    '2013',
    '2012',
    '2011',

    '2010',
    '2009',
    '2008',
    '2007',
  ]

  let listValueData = [
    departmentValues,
    agencyValues,
    grantValues,
    proposalStatusValues,
    submissionYear
  ]

  let searchHelperText = `
  Brain supports 3 kinds of search -

  1. Single word search: example "hydrogen" which searches for just the word hydrogen \n
  2. A Phase Search: example "composite polymer electrolyte" which searches for the complete phase as is \n
  3. Multi word search: this is supported by separating search queries with "," For example - "hydrogen, composite polymer electrolyte". This will return results with proposals where either hydrogen or composite polymer electrolyte was present in the proposal. We won't only return results where both or all keywords were present in each proposal. Even if 1 of them was found, search will return that.
  `

  React.useEffect(() => {
    if (currentUser !== undefined) {
      runInitalSetup()
    }
  }, [])

  function runInitalSetup() {
    if (currentUser !== undefined) {
      let searchType = urlSearchparams.get('type')

      if (searchType !== null) {
        checkFor(searchType)
      }
      else {
        fetchData()
      }
    }
    else {
      alert('It looks like you are not signed in. Please signin to continue.')
    }
  }

  function checkFor(searchType) {
    if (searchType === 'search') {
      let searchQuery = urlSearchparams.get('q')
      setSearchQuery(searchQuery)
      searchKeyword(searchQuery)
    }
    else {

    }
  }

  // Temp function. Remove in next build - 2/28.
  function updateUserInCookie(account) {
    setCookie('currentUser', JSON.stringify(account), 30)
  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  function checkUser(history) {
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push({
        pathname: '/signin',
        state: {urlPath: '/tool/browse/proposal'},
      })
      return

    } else {
      const accountJSON = JSON.parse(accountDetails)

      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }
      return accountJSON
    }
  }

  function setCheckBoxData() {
    var data = {}

    listValueData.forEach(listData => {

      listData.forEach(listValue => {
        data[listValue] = false
      })
    })

    setCheckBoxesData(data)
  }

  function parseExcelData(tableData) {
    var excelSheetData = [
      ['PT#', 'Title', 'Lead Name', 'Keywords', 'Due Date', 'Last Updated On', 'Status']
    ]

    for (let index in tableData) {
      let data = tableData[index]
      let excelRow = [
        data.pt,
        data.title,
        data.lead_name,
        data.keywords,
        data.due_date,
        data.last_updated,
        data.status
      ]

      excelSheetData.push(excelRow)
    }

    setExcelData(excelSheetData)
  }

  function fetchData(pageIndex=0) {
    logFirebaseEvent('Browse -> Proposals -> Arrived')

    setLoading(true)
    fetch(`${base_url}v1/proposals/?id=${currentUser.id}&page=${pageIndex}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {

          if (pageIndex === 0) {
            let tableData = responseJson.data.data
            let totalCount = responseJson.data.count

            setTableData(tableData)
            setAllTopics(tableData)

            setTotalCount(totalCount)
            setCacheTotalCount(totalCount)

            setCheckBoxData()
            fetchMemberDetails()
            parseExcelData(tableData)
          }
          else {
            let newTableData = tableData.concat(responseJson.data.data)

            setTableData(newTableData)
            setLoading(false)

            parseExcelData(newTableData)
          }
          setLastPageIndex(pageIndex)
        }
      })
    })
  }

  function fetchMemberDetails() {
    fetch(`${base_url}v1/login/?id=${currentUser.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert('Something went wrong. Please try again.')
        } 
        else {
          let account = responseJson.data.data

          setUserDetails(account)
          forceLogoutUser(account)
        }
      })
    })
  }

  // Temp function to force logout users. Remove in next release.
  function forceLogoutUser(account) {
    updateUserInCookie(account)

    if (account.is_logged_in === 0) {
      eraseCookie('currentUser')
      alert('You have been logged out of our system. Please contact admin for further details.')

      history.push({
        pathname: '/signin',
        state: {urlPath: '/tool/browse/proposal'},
      })
    }
  }

  function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  function revertToDefaultData() {
    setAutoCompleteOpen(false)
    setClearFilterButtonVisibility('none')

    setOnDeviceFilterActive(false)
    setOnDeviceSearchActive(false)

    setExcelData([])
    checkAllTopics()

    setSearchQuery('')
    setLastPageIndex(0)

    setCheckBoxData({})
    setSearchParams({})
    setFiltersSelected([])
  }

  // We need this for case when user comes from URL param search, allTopics are empty. So run fetchData in that case and update URL.
  function checkAllTopics() {
    if (allTopics.length === 0) {
      fetchData()
      window.history.replaceState({}, document.title, "/tool/browse/proposal" );
    }
    else {
      setTableData(allTopics)
      setTotalCount(cacheTotalCount)
    }
  }

  function checkKeywordEmpty() {
    let search = searchBarRef.current.value

    if (search.length === 0) {
      revertToDefaultData()
    }
  }

  function handleAutoFocusEnded() {
    setAutoCompleteOpen(false)
  }

  function showAutoComplete (event) {
    let search = searchBarRef.current.value

    if (search.length === 0) {
      setAutoCompleteOpen(true)
    }
    
    setTimeout(() => {
      searchBarRef.current.focus()  
    }, 100);
  }

  const LoadingFade = () => {
    return (
      <Backdrop open={loading} style={{zIndex: '5'}}>
        <CircularProgress />
      </Backdrop>
    )
  }

  const HelperTextFade = () => {
    return (
      <Backdrop open={showHelperText} style={{zIndex: '5'}}>
        <HelperText />
      </Backdrop>
    )
  }

  const HelperText = () => {
    return (
      <GridItem xs={6}>

        <Card>
          
          <CardBody>
            <Typography style={{whiteSpace: 'pre-wrap'}}>
              {searchHelperText}
            </Typography>
          </CardBody>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{width: '100px', alignSelf: 'center', 'marginBottom' : '20px'}}
            onClick={() => setShowHelperText(false)}
          >
            Done
          </Button>          

        </Card>

      </GridItem>
    )
  }

  function searchKeyword(searchKeys=null) {
    var search = searchKeys

    if (searchKeys === null) {
      search = searchBarRef.current.value
    }

    if (search.length === 0 || !search.replace(/\s/g, '').length) {
      alert('Please enter a valid search.')
      return
    }

    logFirebaseEvent(`Browse -> Proposals -> Search ${search}`)

    let keywords = search.split(',')
    let searchParams = {
      'keywords' : keywords
    }

    setOnDeviceFilterActive(true)
    setAutoCompleteOpen(false)

    if (onDeviceSearchActive) {
      fireOnDeviceSearchAPI(searchParams)
    }
    else {
      fireSearchAPI(searchParams)
    }
  }

  const handleSearchKeysChange = (event, value) => {
    if (value !== null) {

      if (value.title !== undefined) {
        searchKeys = value.title
        searchKeyword(searchKeys)
      }
      else {
        setSearchQuery(value)
        searchKeyword()
      }
    }
    else {
      revertToDefaultData()
    }
  }

  function fireOnDeviceSearchAPI(searchParams) {
    var updatedTableData = []

    let keywords = searchParams['keywords']
    let proposals = keywords.map(searchKeywordOnDevice)

    proposals.forEach(function(proposal) {
      updatedTableData = updatedTableData.concat(proposal)
    })

    if (updatedTableData.length === 0) {
      alert('It looks like there are no proposals for this search.')
      return
    }

    setTableData(updatedTableData)
    
    setTimeout(() => {
      searchBarRef.current.focus()  
    }, 100)
  }

  function searchKeywordOnDevice(keyword) {
    var proposals = []

    searchResults.forEach(function(proposal) {

      let proposalValues = Object.values(proposal)

      proposalValues.forEach(function(proposalValue) {

        if (typeof proposalValue === 'string') {

          if (proposalValue.toLowerCase().includes(keyword.toLowerCase())) {
            proposals.push(proposal)
          }
        }
      })
    })

    return [...new Set(proposals)];
  }

  function fireSearchAPI(searchParams) {
    setLoading(true)
    fetch(`${base_url}v1/proposals/search/?id=${currentUser.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchParams)
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
          setSearchQuery('')
        } 
        else {
          let tableData = responseJson.data.data

          setTableData(tableData)
          setSearchResults(tableData)

          parseExcelData(tableData)

          setLastPageIndex(-1)
          setTotalCount(tableData.length)

          setTimeout(() => {
            searchBarRef.current.focus()  
          }, 100)
        }
      })
    })
  }

  function checkGrantValue(value) {
    let grantMappings = {
      'SBIR PH 1' : 'SBIR_PH_1',
      'SBIR PH 2' : 'SBIR_PH_2',

      'STTR PH 1' : 'STTR_PH_1',
      'STTR PH 2' : 'STTR_PH_2',
    }

    return grantMappings[value]
  }

  const handleClearFiltersButtonClicked = () => {
    var data = checkBoxesData

    for (var key in data) {
      data[key] = false
    }

    setSearchParams({})
    setCheckBoxData(data)
    setFiltersSelected([])

    setSearchQuery('')
    setLastPageIndex(0)

    setOnDeviceFilterActive(false)
    setOnDeviceSearchActive(false)

    setClearFilterButtonVisibility('none')
    revertToDefaultTableData()
  }

  const handleClick = (index) => {
    const newValues = [...openGridArray]
    newValues[index] = !newValues[index]

    setOpenGridArray(newValues)
  }

  const handleCheckboxChange = (category, value, checked) => {
    // let category = event.target.id
    // let value = event.target.value
    // let checked = event.target.checked

    setOnDeviceSearchActive(true)

    let eventParams = {
      'value' : value,
      'checked' : checked
    }

    // if (!checked) {
    //   let valueIndex = filtersSelected.indexOf(value)
    //   filtersSelected.splice(valueIndex, 1)
    //   setFiltersSelected(filtersSelected)

    //   setCheckBoxesData({...checkBoxesData, [value] : checked})
    // }

    var categoryMappings = {}

    if (onDeviceFilterActive) {
      categoryMappings = {
        'Department' : 'organisation',
        'Agency' : 'agency',
        'Grant' : 'grant',
  
        'Status' : 'status',
        'Submission Year' : 'submissionYear'
      }
    }

    else {
      categoryMappings = {
        'Department' : 'organisation__in',
        'Agency' : 'agency__in',
        'Grant' : 'grant__in',
  
        'Status' : 'status__in',
        'Submission Year' : 'due_date__year__in'
      }
    } 

    var updatedValue = value
    let categoryValue = categoryMappings[category]

    logFirebaseEvent(`Filter ${category} Tapped`)

    if (categoryValue === 'grant__in') {
      updatedValue = checkGrantValue(value)
    }

    let previousSearchParam = {
      'category' : categoryValue,
      'value' : updatedValue
    }

    if (categoryValue in searchParams) {
      let exisitingValue = searchParams[categoryValue]
      
      if (checked) {
        exisitingValue.push(updatedValue)
      }
      else {
        let index = exisitingValue.indexOf(updatedValue)
        exisitingValue.splice(index, 1)
      }

      if (exisitingValue.length === 0) {
        delete searchParams[categoryValue]
      }
      else {
        searchParams[categoryValue] = exisitingValue
      }
    }

    else {
      searchParams[categoryValue] = [updatedValue]
    }

    if (Object.keys(searchParams).length === 0) {
      setTableData(allTopics)
      setClearFilterButtonVisibility('none')

      return
    }
    else {

      if (onDeviceFilterActive) {
        fireOnDeviceFilterAPI(searchParams, eventParams)
      }

      else {
        fireFilterAPI(searchParams, eventParams, previousSearchParam)
      }
    }
  }

  function fireOnDeviceFilterAPI(searchParams, eventParams=null) {
    setLoading(true)

    let topics = searchResults

    const filterOrganisationResults = []
    const filterGrantResults = []
    // const filterAgencyResults = []
    const filterStatusResults = []
    const filterSubmissionYearResults = []

    for (let i = 0; i < topics.length; i++) {

      if ('organisation' in searchParams) {

        let organisationList = searchParams['organisation']
        organisationList.forEach(organisation => {

          if (topics[i].organisation.includes(organisation)) {

            if (!filterOrganisationResults.includes(topics[i])) {
              filterOrganisationResults.push(topics[i])
            }
          }
        })
      }

      if ('grant' in searchParams) {

        let grantList = searchParams['grant']
        grantList.forEach(grant => {

          if (topics[i].organisation.includes(grant)) {

            if (!filterGrantResults.includes(topics[i])) {
              filterGrantResults.push(topics[i])
            }
          }
        })
      }

      // if ('agency' in searchParams) {

      //   let agencyList = searchParams['agency']
      //   agencyList.forEach(agency => {

      //     if (topics[i].organisation.includes(agency)) {

      //       if (!filterAgencyResults.includes(topics[i])) {
      //         filterAgencyResults.push(topics[i])
      //       }
      //     }
      //   })
      // }

      if ('submissionYear' in searchParams) {

        let submissionYearList = searchParams['submissionYear']
        submissionYearList.forEach(submissionYear => {

          if (topics[i].due_date.includes(submissionYear)) {

            if (!filterSubmissionYearResults.includes(topics[i])) {
              filterSubmissionYearResults.push(topics[i])
            }
          }
        })
      }

      if ('status' in searchParams) {

        let statusList = searchParams['status']
        statusList.forEach(status => {

          if (topics[i].status.includes(status)) {

            if (!filterStatusResults.includes(topics[i])) {
              filterStatusResults.push(topics[i])
            }
          }
        })
      }
    }

    var finalData = []
    var finalResults = []
    
    if (filterOrganisationResults.length > 0) {
      finalData.push(filterOrganisationResults)
    }

    if (filterGrantResults.length > 0) {
      finalData.push(filterGrantResults)
    }

    if (filterStatusResults.length > 0) {
      finalData.push(filterStatusResults)
    }

    if (filterSubmissionYearResults.length > 0) {
      finalData.push(filterSubmissionYearResults)
    }

    if (finalData.length === 0) {
      setLoading(false)
      // revertToDefault()

      // finalResults = topics
      // setTableData(finalResults)

      alert('It looks like there are no proposals for this grid selection.')
      return
    }
    else {
      finalResults = finalData.reduce((a, b) => a.filter(c => b.includes(c)));

      finalResults = finalResults.filter(function(item, pos) {
        return finalResults.indexOf(item) === pos;
      })
    }

    if (finalResults.length === 0) {
      setLoading(false)
      // revertToDefault()

      // finalResults = topics
      // setTableData(finalResults)

      alert('It looks like there are no proposals for this grid selection.')
      return
    }

    if (eventParams !== null) {
      let value = eventParams['value']
      let checked = eventParams['checked']

      if (checked) {
        let updatedFiltersSelected = [...filtersSelected, value]
        setFiltersSelected(updatedFiltersSelected)
      }
      else {
        let valueIndex = filtersSelected.indexOf(value)
        filtersSelected.splice(valueIndex, 1)
        setFiltersSelected(filtersSelected)
      }

      setClearFilterButtonVisibility('block')
      setCheckBoxesData({...checkBoxesData, [value] : checked})
    }

    setLoading(false)
    setTableData(finalResults)
    parseExcelData(finalResults)
  }

  function revertToDefault() {
    setSearchParams({})
    setCheckBoxData({})
    setFiltersSelected([])

    setLastPageIndex(0)
    setClearFilterButtonVisibility('none')
  }

  function revertToDefaultTableData() {
    setTimeout(() => {

      setExcelData([])
      setTableData(allTopics) 
      setTotalCount(cacheTotalCount)
    }, 50);
  }

  function updateSearchParams(previousSearchParam) {

    if (Object.keys(searchParams).length === 1) {
      revertToDefault()
      revertToDefaultTableData()
    }

    else {
      let category = previousSearchParam['category']
      let value = previousSearchParam['value']

      let searchParamsValue = searchParams[category]
      let searchParamValueIndex = searchParamsValue.indexOf(value)

      searchParamsValue.splice(searchParamValueIndex, 1)

      if (searchParamsValue.length === 0) {
        delete searchParams[category]
      }
      else {
        searchParams[category] = searchParamsValue
      }
      
      setSearchParams(searchParams)
    }
  }

  function fireFilterAPI(searchParams, eventParams=null, previousSearchParam={}) {
    setLoading(true)

    fetch(`${base_url}v1/proposals/filter/?id=${currentUser.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchParams)
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {

          if (Object.keys(previousSearchParam).length > 0) {
            updateSearchParams(previousSearchParam)
          }
          
          alert(responseJson.error.message)
        } 
        else {
          let tableData = responseJson.data.data

          setTableData(tableData)
          setSearchResults(tableData)
          parseExcelData(tableData)

          setSearchParams(searchParams)

          setLastPageIndex(-1)
          setTotalCount(tableData.length)
        
          if (eventParams !== null) {
            let value = eventParams['value']
            let checked = eventParams['checked']
  
            if (checked) {
              let updatedFiltersSelected = [...filtersSelected, value]
              setFiltersSelected(updatedFiltersSelected)
            }
            else {
              let valueIndex = filtersSelected.indexOf(value)
              filtersSelected.splice(valueIndex, 1)
              setFiltersSelected(filtersSelected)
            }
  
            setClearFilterButtonVisibility('block')
            setCheckBoxesData({...checkBoxesData, [value] : checked})
          }
        }
      })
    })
  }

  function updateFilterParams(filterValue) {

    for (var category in searchParams) {
      
      var filterValueIndex = -1
      let categoryValue = searchParams[category]

      categoryValue.forEach((value, index) => {
        if (filterValue === value) {
          filterValueIndex = index
        }
      })

      if (filterValueIndex !== -1) {
        categoryValue.splice(filterValueIndex, 1)

        if (categoryValue.length === 0) {
          delete searchParams[category]
        }
      }
    }

    if (Object.keys(searchParams).length === 0) {
      setExcelData([])
      setTableData(allTopics)

      setSearchQuery('')
      setClearFilterButtonVisibility('none')

      return
    }
    else {

      if (onDeviceFilterActive) {
        let updatedSearchParams = updateCategoryMappings(searchParams)
        fireOnDeviceFilterAPI(updatedSearchParams)
      }

      else {
        fireFilterAPI(searchParams)
      }
    }
  }

  function updateCategoryMappings(searchParams) {
    let oldMappingValues = [
      'organisation__in',
      'agency__in',
      'grant__in',
      'status__in',
      'due_date__year__in'
    ]

    let mappings = {
      'organisation__in' : 'organisation',
      'agency__in' : 'agency',
      'grant__in' : 'grant',

      'status__in' : 'status',
      'due_date__year__in' : 'submissionYear',
    }

    for (const [key, value] of Object.entries(searchParams)) {
      if (oldMappingValues.includes(key)) {
        let newKey = mappings[key]

        searchParams[newKey] = value
        delete searchParams[key]
      }
    }
    return searchParams
  }

  const handleChipDelete = (title) => {
    let valueIndex = filtersSelected.indexOf(title)

    filtersSelected.splice(valueIndex, 1)
    setLoading(true)

    updateFilterParams(title)
    
    setTimeout(() => {
      setLoading(false)

      setFiltersSelected(filtersSelected)
      setCheckBoxesData({...checkBoxesData, [title] : false})

      if (filtersSelected.length === 0) {
        setClearFilterButtonVisibility('none')
      }
    }, 100);
  }

  const ChipItem = (title) => {
    return (
      <Chip
        style={{'marginRight' : '5px', 'marginLeft' : '5px'}}
        label={title}
        onDelete={() => handleChipDelete(title)}
        variant="outlined"
      />
    )
  }

  const DataListButton = () => {
    if (currentUser !== undefined) {
      if (currentUser.role === 'ADMIN') {
        return (
          <Grid item xs={2} style={{'marginTop' : '25px'}}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                >
                  <CSVLink data={excelData} filename={'pt#list.csv'}>Export PT# List</CSVLink>
                </Button>
          </Grid>
        )
      }
      return null
    }
    return null
  }

  const SearchButton = () => {
    return (
        <IconButton onClick={searchKeyword}>
            <SearchOutlined />
        </IconButton>
    )
  }

  const SearchHelperTextButton = () => {
    return (
        <IconButton onClick={() => setShowHelperText(true)}>
            <HelpOutline />
        </IconButton>
    )
  }

  const SearchGrid = () => {
    return (
      <Grid
        container xs={5}
        direction="row"
      >
        <Autocomplete
          style={{'width' : '100%'}}
          open={autoCompleteOpen}
          freeSolo={true}
          defaultValue={searchKeys}
          value={searchQuery}
          options={authors}
          getOptionLabel={(option) => option.title || searchQuery}
          onChange={handleSearchKeysChange}
          onAbort={revertToDefaultData}
          renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                type={'search'}
                value={searchQuery}
                inputRef={searchBarRef}
                onFocus={showAutoComplete}
                onBlur={handleAutoFocusEnded}
                variant="outlined"
                margin="normal"
                id="search_bar"
                name="search_bar"
                placeholder="Search (hydrogen, composite polymer electrolyte)"
                onChange={checkKeywordEmpty}
                InputProps={{ ...params.InputProps, 
                  startAdornment: ( <InputAdornment position="start"> <SearchButton /> 
                  </InputAdornment> ),
                  endAdornment: ( <InputAdornment position="end"> <SearchHelperTextButton /> 
                  </InputAdornment> ),
                  disableUnderline: true }}
            />
          )}
        />
      </Grid>
    )
  }

  const FilterGrid = () => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState([null, null, null, null, null])

    const handleMenuClick = (event, index) => {
      var updatedValues = [...menuAnchorEl]
      updatedValues[index] = event.currentTarget

      setMenuAnchorEl(updatedValues);
    };
  
    const handleMenuClose = () => {
      setMenuAnchorEl([null, null, null]);
    };

    return (
      <Grid 
        container xs={6}
        direction="row"
        style={{
          height: '100%',
          maxHeight: '200vh',
          overflow: 'auto',
        }}
      >
          <Grid 
            container
            direction="row"
            justifyContent="space-around"
            style={{
              marginTop: '7px',
              paddingTop: '7px',
              paddingBottom: '10px',
              border: '1px solid #B8B8B8',
              borderRadius: '4px',
              alignSelf: 'center',
              backgroundColor: 'white'
            }}
          >
            <Typography 
              style={{
                // marginTop : '10px'
              }} 
              variant="h5">
              Filters:
            </Typography>

            <Button
              style={{
                height: '30px',
                marginTop: '10px',
                display: `${clearFilterButtonVisibility}`
              }}
              type="submit"
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClearFiltersButtonClicked}
            >
                Clear All
            </Button>

            {filtersSelected.map(ChipItem)}

        {listItemValues.map((headerValue, index) => {
          return (
            <Grid item>
              <Button 
                aria-controls="simple-menu" 
                aria-haspopup="true"
                onMouseOver={event => handleMenuClick(event, index)}
                // onClick={event => handleMenuClick(event, index)}
              >
                {headerValue}
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl[index]}
                open={Boolean(menuAnchorEl[index])}
                MenuListProps={{ onMouseLeave: handleMenuClose }}
                // onClose={handleMenuClose}
              >
                {listValueData[index].map((value) => {
                  return (
                    <ListItem
                      button
                      onClick={() => handleCheckboxChange(headerValue, value, !checkBoxesData[value])}
                      style={{'marginTop' : '-15px'}} 
                    >
                      <ListItemIcon>
                        <Checkbox
                          id={headerValue}
                          value={value}
                          checked={checkBoxesData[value]}
                          edge="start"
                          onChange={() => setCheckBoxesData({...checkBoxesData, [value] : !checkBoxesData[value]})}
                        />
                      </ListItemIcon>

                      <ListItemText style={{ 'marginLeft' : '-25px', 'fontSize' : 'small'}} primary={value} />
                    </ListItem>
                  )
                })}
              </Menu>
            </Grid>
          )
        })}
        </Grid>
    </Grid>
    )

    // return (
    //   <Grid 
    //     item
    //     style={{maxHeight: '200vh', overflow: 'auto'}}
    //   >

    //     <Grid 
    //       container
    //       direction="column"
    //       justifyContent="space-around"
    //       alignItems="baseline"
    //     >
    //       <Grid 
    //         container
    //         direction="row"
    //         justifyContent="space-around"
    //         alignItems="baseline"
    //       >
    //         <Typography 
    //           style={{
    //             'textAlign' : 'left',
    //             'marginTop' : '20px',
    //           }} 
    //           variant="h5">
    //           Filters
    //         </Typography>

    //         <Button
    //           style={{visibility: `${clearFilterButtonVisibility}`}}
    //           type="submit"
    //           variant="contained"
    //           size="small"
    //           color="primary"
    //           onClick={handleClearFiltersButtonClicked}
    //         >
    //             Clear All
    //         </Button>

    //       </Grid>
          
    //       <Grid 
    //         container 
    //         justifyContent="space-around"
    //         style={{'marginTop' : '10px'}}
    //       >
    //         {filtersSelected.map(ChipItem)}
    //       </Grid>
    //     </Grid>

    //     {listItemValues.map((headerValue, index) => {

    //       return (
    //           <List
    //           subheader={
    //           <ListSubheader 
    //             style={{
    //               'fontWeight' : 'bold', 
    //               'fontSize' : 'medium', 
    //               'textAlign' : 'left'
    //             }} 
    //             component="div" 
    //             id="nested-list-subheader"
    //             onClick={() => handleClick(index)}
    //           >
    //             {headerValue}
    //             {openGridArray[index] ? <ExpandLess /> : <ExpandMore />}
    //           </ListSubheader>
    //         }
    //       >
    //         <Collapse in={openGridArray[index]} timeout="auto" unmountOnExit>
    //           {listValueData[index].map((value) => {
    //             return (
    //               <ListItem style={{'marginTop' : '-15px'}} button>
    //                 <ListItemIcon>
    //                   <Checkbox
    //                     id={headerValue}
    //                     value={value}
    //                     checked={checkBoxesData[value]}
    //                     edge="start"
    //                     onChange={handleCheckboxChange}
    //                   />
    //                 </ListItemIcon>

    //                 <ListItemText style={{ 'marginLeft' : '-25px', 'fontSize' : 'small'}} primary={value} />
    //               </ListItem>
    //             )
    //           })}
    //         </Collapse>

    //         </List>
    //       )
    //     })}
    // </Grid>
    // )
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h1>Proposals</h1>

        <LoadingFade />
        <HelperTextFade />

        <div style={{position: 'absolute', top: 50, right: 0}}>
          <p>
          All information on this site is proprietary to Oceanit Laboratories, Inc. Material copied or downloaded from this site is only to be used by Oceanit employees and is not to be shared with those outside of Oceanit Laboratories, Inc. Some information on this site is restricted to those with certain access requirements.
          </p>
        </div>

        {/* <Autocomplete
          open={autoCompleteOpen}
          freeSolo={true}
          defaultValue={searchKeys}
          value={searchQuery}
          options={authors}
          getOptionLabel={(option) => option.title || searchQuery}
          onChange={handleSearchKeysChange}
          onAbort={revertToDefaultData}
          renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                type={'search'}
                value={searchQuery}
                inputRef={searchBarRef}
                variant="outlined"
                margin="normal"
                id="search_bar"
                name="search_bar"
                placeholder="Search (hydrogen, composite polymer electrolyte)"
                onChange={checkKeywordEmpty}
                InputProps={{ ...params.InputProps, 
                  startAdornment: ( <InputAdornment position="start"> <SearchButton /> 
                  </InputAdornment> ),
                  endAdornment: ( <InputAdornment position="end"> <SearchHelperTextButton /> 
                  </InputAdornment> ),
                  disableUnderline: true }}
            />
          )}
        /> */}
        
        <Grid container spacing={1} style={{'marginTop' : '30px'}}>

        <Grid 
            container xs={12}
            direction="row"
            justifyContent="space-around"
            // style={{
            //   border: '1px solid #B8B8B8',
            //   borderRadius: '4px',
            //   backgroundColor: 'white'
            // }}
          >

            {/* <DataListButton /> */}

            <SearchGrid/>
            <FilterGrid/>

          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardBody>
                <ProposalTable
                  tableHeaderColor="primary"
                  headCells={headCellData}
                  tableData={tableData}
                  totalCount={totalCount}
                  lastPageIndex={lastPageIndex}
                  fetchData={fetchData}
                  user={userDetails}
                  searchQuery={searchQuery}
                />
              </CardBody>

            </Card>
          </Grid>

        </Grid>
      </GridItem>

    </GridContainer>
  );
}
