import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Box,
  IconButton,
  Drawer,
  CircularProgress,
  Backdrop,
  Divider,
  Menu,
  MenuItem,
  MenuList,
} from "@material-ui/core"

import Highlighter from "react-highlight-words";
import { formatDistanceToNow } from 'date-fns';

import FavouriteNotSelectedIcon from '@material-ui/icons/FavoriteBorderSharp';
import FavouriteSelectedIcon from '@material-ui/icons/Favorite';
import CommentIcon from '@material-ui/icons/CommentOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import RelatedResourceIcon from '@material-ui/icons/EmojiObjectsOutlined';
import SubmitIcon from '@material-ui/icons/Done';
import ProfileIcon from '@material-ui/icons/AccountCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";
import { Mention, MentionsInput } from "react-mentions";

const base_url = process.env.REACT_APP_BASE_URL;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, headCells, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <React.Fragment>
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
          </TableCell> */}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.title}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </React.Fragment>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeadCell: {
    color: "inherit",
    "&, &$tableCell": {
      fontSize: "1em",
    },
  },
  tableCell: {
    lineHeight: "1.42857143",
    // padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  list: {
    width: 500,
  },
  fullList: {
    width: 'auto',
  },
  reactMentionsStyle: {
    backgroundColor: "#cee4e5",
  }
}));

function Row(props) {
  var {prop, user, key, classes, setLoading, searchQuery, userMentionsData} = props

  const [drawerOpen, setDrawerOpen] = useState(false)

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const [commentDrawerOpen, setCommentDrawerOpen] = useState(false)
  const [resourceDrawerOpen, setResourceDrawerOpen] = useState(false)

  var [topicData, setTopicData] = useState(prop)
  var [hasFavourited, setHasFavourited] = useState(prop.has_favourited)

  const [comments, setComments] = useState([])
  const [relatedProposals, setRelatedProposals] = useState([])

  const handleMouseOver = (event) => {
    setMenuOpen(true)
    setMenuAnchorEl(event.target)
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  const toggleDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    logFirebaseEvent(`Browse -> Topics -> Topic ${prop.title} Tapped`)
    fireTopicGetAPI(openDrawer)
  };

  const toggleCommentDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown') {
      return;
    }

    // logFirebaseEvent(`Browse -> Topics -> Topic ${prop.title} Tapped`)
    fireTopicCommentGetAPI(openDrawer)
  };

  const toggleResourceDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // logFirebaseEvent(`Browse -> Topics -> Topic ${prop.title} Tapped`)
    fireTopicRelatedProposalsGetAPI(openDrawer)
  };

  const TypographyHolder = (props) => {
    const {titleText, style} = props;
    return (
      <Typography 
        variant="body1"
        style={style} 
      >
        {titleText}
      </Typography>
    )
  }

  const handleFavouriteTopicClicked = () => {
    let request_type = 'A'

    if (hasFavourited) {
      request_type = 'R'
    }

    let params = {
      'topic_id' : prop.id,
      'request_type' : request_type,
      'updated_fav_value' : !hasFavourited
    }

    fireTopicPatchAPI(params)
  }

  const fireTopicRelatedProposalsGetAPI = (openDrawer) => {
    setLoading(true)
    fetch(`${base_url}v1/topic/related_proposals/?id=${user.id}&topic_id=${prop.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      response.json()
      .then(responseJson => {
        setLoading(false)

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          setRelatedProposals(responseJson.data.data)
          setResourceDrawerOpen(openDrawer)
        }
      })
    })
  }

  const fireTopicCommentGetAPI = (openDrawer) => {
    setLoading(true)
    fetch(`${base_url}v1/topic/comment/?id=${user.id}&topic_id=${prop.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      response.json()
      .then(responseJson => {
        setLoading(false)

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          setComments(responseJson.data.data)
          setCommentDrawerOpen(openDrawer)
        }
      })
    })
  }

  const fireTopicGetAPI = (openDrawer) => {
    setLoading(true)
    fetch(`${base_url}v1/topic/?id=${user.id}&topic_id=${prop.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      response.json()
      .then(responseJson => {
        setLoading(false)

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          let responseData = responseJson.data.data

          prop['comment_count'] = responseData['comment_count']
          prop['members_interested_string'] = responseData['members_interested_string']
          prop['members_interested_count'] = responseData['members_interested_count']

          setTopicData(prop)
          setDrawerOpen(openDrawer)
        }
      })
    })
  }

  const fireTopicPatchAPI = (params) => {

    fetch(`${base_url}v1/topic/?id=${user.id}&topic_id=${params['topic_id']}&type=${params['request_type']}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {

          if (params['request_type'] === 'A') {
            prop.members_interested_count += 1
          } 
          else {
            prop.members_interested_count -= 1
          }
          
          prop.has_favourited = params['updated_fav_value']

          hasFavourited = params['updated_fav_value']
          setHasFavourited(hasFavourited)
        }
      })
    })
  }

  const CommentPage = (props) => {
    var {tid} = props

    const [commentText, setCommentText] = React.useState('')

    const commentMentionStyle = {
      width: '90%',
      height: '100px',
      control: {
        backgroundColor: '#fff',
        fontSize: 15
        // fontWeight: 'normal',
      },
      '&multiLine': {
        control: {
          fontFamily: 'monospace',
        },
        highlighter: {
          padding: 10,
          border: '1px solid transparent',
        },
        input: {
          padding: 10,
          borderRadius: 4,
          border: '1px solid silver',
        },
      },
      suggestions: {
        list: {
          backgroundColor: 'white',
          border: '1px solid rgba(0,0,0)',
          fontSize: 15,
        },
        item: {
          padding: '5px 15px',
          borderBottom: '1px solid rgba(0,0,0,0.15)',
          '&focused': {
            backgroundColor: '#cee4e5',
          },
        },
      },
    }

    function Comment(props) {
      const {id, text, index, member_id, member_name, member_title, created_at} = props

      const commentDate = new Date(created_at)
      const relativeTime = formatDistanceToNow(commentDate, { addSuffix: true })

      return (
        <div>
          <IconButton
              style={{'marginLeft' : '10px', 'display' : 'inline-block'}}
              aria-label="profile"
              size="small"
            >
            <ProfileIcon style={{'transform': 'scale(0.8)', 'marginTop' : '5px'}} />
            <p style={{'marginLeft' : '2px', 'fontSize' : '15px', 'fontWeight' : 'bold'}}>{member_name}</p>
          </IconButton>

          {user.id === member_id ? 
            <IconButton
              style={{'marginLeft' : '35%', 'display' : 'inline-block'}}
              aria-label="profile"
              size="small"
              onClick={() => fireDeleteCommentAPI(id, index)}
            >
              <CloseIcon style={{'transform': 'scale(0.8)', 'marginTop' : '5px'}} />
            </IconButton>
            : null}

          <p style={{'marginLeft' : '40px', 'marginTop' : '-14px', 'fontSize' : '12px'}}>{member_title}</p>
          <p style={{'marginLeft' : '40px', 'marginTop' : '-3px', 'fontSize' : '15px'}}>{text}</p>

          <div style={{'position' : 'relative'}}>
            <p style={{
              'marginLeft' : '40px',
              'marginTop' : '10px',
              'fontSize' : '12px'
              }}
            >
                {relativeTime}
            </p>
          </div>

          <Divider style={{'width' : '100%','alignSelf' : 'center'}}/>
        </div>
      )
    }

    function Submit() {
      return (
        <React.Fragment>
          <IconButton
            aria-label="submit"
            size="small"
            onClick={() => executeCommentSubmitted()}
          >
            <SubmitIcon/>
          </IconButton>
        </React.Fragment>
      )
    }

    function executeCommentSubmitted() {
      if (!commentText.replace(/\s/g, '').length) {
        return
      }

      const [parsedCommentText, membersTagged] = parseCommentText(commentText)
      fireCreateCommentAPI(parsedCommentText, membersTagged)
    }

    function parseCommentText(commentText) {
      var membersTagged = []
      
      userMentionsData.forEach((user) => {
        let matchString = `@[${user['display']}](${user['id']})`

        if (commentText.includes(matchString)) {
          commentText = commentText.replace(matchString, user['display'])
          membersTagged.push(user['id'])
        }
      })

      return [commentText, membersTagged]
    }

    function fireCreateCommentAPI(commentText, membersTagged) {
      setLoading(true)

      let commentBody = {
        'member' : user.id,
        'topic' : tid,
        'text' : commentText,
        'members_tagged' : membersTagged
      }

      fetch(`${base_url}v1/topic/comment/?id=${user.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(commentBody)
      })
      .then(response => {
  
        response.json()
        .then(responseJson => {
  
          if (!response.ok) {
            alert(responseJson.error.message)
          } 
          else {
            let comment = {
              'id' : responseJson.data.data,
              'text' : commentText,
              'member_id' : user.id,
              'member_name' : user.first_name + ' ' + user.last_name,
              'member_title' : user.title,
              'created_at' : Date.now()
            }

            prop.comment_count += 1
            comments.unshift(comment)
            
            setComments(comments)
            setLoading(false)
          }
        })
      })
    }

    function fireDeleteCommentAPI(comment_id, index) {
      setLoading(true)

      fetch(`${base_url}v1/topic/comment/?id=${user.id}&comment_id=${comment_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
  
        response.json()
        .then(responseJson => {
  
          if (!response.ok) {
            alert(responseJson.error.message)
          } 
          else {
            prop.comment_count -= 1
            comments.splice(index, 1)
            
            setComments(comments)
            setLoading(false)
          }
        })
      })
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        executeCommentSubmitted()
      }
    }

    return (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onKeyDown={toggleCommentDrawer(false)}
      >
        <Box sx={{ margin: 30, width: '100%' }}>
          <h4>Comments</h4>

          <MentionsInput
            id="comment-mentions"
            value={commentText}
            placeholder='Add a comment ...'
            style={commentMentionStyle}
            onKeyDown={handleKeyDown}
            onChange={(e) => setCommentText(e.target.value)}
          >
            <Mention
              className={classes.reactMentionsStyle}
              data={userMentionsData} 
            />
          </MentionsInput>
          
            {comments.map((value, index) => {
              return (
                <Paper style={{
                  'marginTop' : '20px', 
                  'width' : '90%', 
                  'alignSelf' : 'center', 
                  'background' : '#F8F8FF'
                  }}
                >
                  <Comment
                    id={value.id}
                    text={value.text}
                    index={index}
                    member_id={value.member_id}
                    member_name={value.member_name}
                    member_title={value.member_title}
                    created_at={value.created_at}
                  />
                </Paper>
              )
            })}
        </Box>
      </div>
    )
  }

  const ResourcePage = () => {
    return (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onKeyDown={toggleResourceDrawer(false)}
      >
        <Box sx={{ margin: 30, width: '90%' }}>
          <h4>Related Proposals</h4>
          
          {relatedProposals.map((relatedProposal, index) => {
            let hyperlink = `browse/proposal?type=search&q=${relatedProposal.pid}`
            
            return (
              <Grid 
                container
                direction="column"
                alignItems="baseline"
              >
                <Link
                  style={{'fontSize' : '18px', 'fontWeight' : '700'}}
                  href={hyperlink}
                  target="_blank"
                  variant="h6"
                  color='textPrimary'
                >
                  {relatedProposal.title}
                </Link>

                {/* <HighlightDrawerText 
                  bodyText={relatedProposal.title}
                /> */}

                <TypographyHolder 
                  style={{
                    'marginRight': 5, 
                    'marginTop' : '10px', 
                    'fontWeight' : 700}} 
                  titleText={'Abstract:'}
                />
                <HighlightRowText 
                  bodyText={relatedProposal.abstract}
                />

                <TypographyHolder 
                  style={{
                    'marginRight': 5, 
                    'marginTop' : '10px', 
                    'fontWeight' : 700}} 
                  titleText={'Authors:'}
                />
                <HighlightRowText 
                  bodyText={relatedProposal.authors}
                />
                <br></br>
              </Grid>
            )
          })
          }
        </Box>
      </div>
    )
  }

  const DetailPage = () => {
    return (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onKeyDown={toggleDrawer(false)}
      >
        <Box sx={{ margin: 30, width: '90%' }}>

          <HighlightRowText bodyText={topicData.members_interested_string}/>
          <br></br>
          <br></br>

          <HighlightDrawerText bodyText={prop.title}/>
          <br></br>

          <Grid 
            container
            direction="column"
            alignItems="baseline"
          >
            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '10px', 
                'fontWeight' : 700}} 
              titleText={'Department:'}
            />
            <HighlightRowText 
              bodyText={prop.organisation}
            />

            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '10px', 
                'fontWeight' : 700}} 
              titleText={'Agency:'}
            />
            <HighlightRowText 
              bodyText={prop.agency}
            />

            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '10px', 
                'fontWeight' : 700}} 
              titleText={'Grant:'}
            />
            <HighlightRowText 
              bodyText={prop.grant}
            />

            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '10px', 
                'fontWeight' : 700}} 
              titleText={'Phase:'}
            />
            <HighlightRowText 
              bodyText={prop.phase}
            />

            <TypographyHolder 
              style={{
                'marginRight': 5, 
                'marginTop' : '10px', 
                'fontWeight' : 700}} 
              titleText={'Release Date:'}
            />
            <HighlightRowText 
              bodyText={prop.release_date}
            />
            
          </Grid>

          <br/>

          <HighlightRowText bodyText={prop.description}/>

        </Box>
      </div>
    )
  }

  const HighlightDrawerText = (props) => {
    const {bodyText} = props
    var keywords = searchQuery.split(',')
    
    keywords = keywords.map(keyword => {
      return keyword.trim()
    })

    return (
      <Highlighter
        style={{ whiteSpace: 'pre-line', fontWeight: '700'}}
        highlightClassName="textHighlight"
        searchWords={keywords}
        autoEscape={true}
        textToHighlight={bodyText}
      />
    )
  }

  const HighlightRowText = (props) => {
    const {bodyText} = props
    var keywords = searchQuery.split(',')
    
    keywords = keywords.map(keyword => {
      return keyword.trim()
    })

    return (
      <Highlighter
        style={{ whiteSpace: 'pre-line'}}
        highlightClassName="textHighlight"
        searchWords={keywords}
        autoEscape={true}
        textToHighlight={bodyText}
      />
    )
  }

  const ProposalAIItem = (props) => {
    const {item, topicID} = props

    return (
      <MenuItem onClick={() => handleProposalAIItemClicked(item, topicID)}>{item}</MenuItem>
    )
  }

  const handleProposalAIItemClicked = (item, topicID) => {
    let mappings = {
      'Generate Proposal' : 'proposal',
      'Generate Acronyms' : 'acronym',
      'Generate Abstract' : 'abstract',
    }
    window.open(`/tool/browse/ai/${mappings[item]}?topic_id=${topicID}`, '_blank')
  }

  let items = [
    'Generate Proposal',
    'Generate Acronyms',
    'Generate Abstract'
  ]

  return (
    <React.Fragment>
      <TableRow hover tabIndex={-1} key={key} className={classes.tableBodyRow}>

        <TableCell style={{padding: '0px'}} align='justify' key='Favourite'>

        <IconButton 
          aria-label="favourite"
          onClick={handleFavouriteTopicClicked}
        >
          <div style={{'fontSize' : '18px', 'paddingRight' : '10px'}}>
            {prop.members_interested_count > 0 ? prop.members_interested_count : null}
          </div>
          
          {prop.has_favourited ? <FavouriteSelectedIcon /> : <FavouriteNotSelectedIcon />}
        </IconButton>

        </TableCell>

        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.topic_number}/>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.title}/>
        </TableCell>
        
        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.organisation}/>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.agency}/>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>
          <HighlightRowText bodyText={prop.keywords}/>
        </TableCell>

        <TableCell className={classes.tableCell} key={key} onClick={toggleDrawer(true)}>{prop.due_date}</TableCell>

        <TableCell className={classes.tableCell} key={key} component="a" href={prop.topic_url} target="_blank">TOPIC URL</TableCell>

        <TableCell className={classes.tableCell} align='justify' key={key} onClick={toggleResourceDrawer(true)}>

          <IconButton 
            aria-label="related-resources"
          >
            <div style={{'fontSize' : '18px', 'paddingRight' : '10px'}}>
              {prop.related_proposals_count > 0 ? prop.related_proposals_count : null}
            </div>
            <RelatedResourceIcon />
          </IconButton>
          
        </TableCell>

        <TableCell className={classes.tableCell} align='justify' key={key} onClick={toggleCommentDrawer(true)}>

          <IconButton 
            aria-label="comment"
          >
            <div style={{'fontSize' : '18px', 'paddingRight' : '10px'}}>
              {prop.comment_count > 0 ? prop.comment_count : null}
            </div>
            <CommentIcon />
          </IconButton>
          
        </TableCell>

        <TableCell className={classes.tableCell} align='justify' key={key}>

          <IconButton 
            aria-owns={menuOpen ? 'simple-menu' : null}
            aria-haspopup="true"
            aria-label="proposal-ai" 
            onMouseOver={handleMouseOver}
          >
            <CreateOutlinedIcon />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen}
            MenuListProps={{ onMouseLeave: handleCloseMenu }}
          >
            {items.map((item, index) => {
              return (
                <ProposalAIItem key={index} item={item} topicID={prop.id} />
              )
            })}
        </Menu>
          
        </TableCell>

      </TableRow>

      <Drawer anchor={'right'} open={drawerOpen} onClose={toggleDrawer(false)}>
        <DetailPage/>
      </Drawer>

      <Drawer anchor={'right'} open={resourceDrawerOpen} onClose={toggleResourceDrawer(false)}>
        <ResourcePage/>
      </Drawer>

      <Drawer anchor={'right'} open={commentDrawerOpen} onClose={toggleCommentDrawer(false)}>
        <CommentPage tid={prop.id}/>
      </Drawer>
    </React.Fragment>
  );
}

export default function TopicTable(props) {
  var {
    user,
    tableRef,
    headCells, 
    tableData, 
    userMentionsData, 
    totalCount, 
    lastPageIndex, 
    fetchData, 
    searchQuery, 
    page,
    setPage,
  } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('') // Old Value: desc, maybe add later?
  const [orderBy, setOrderBy] = React.useState('') // Old value: due_date, maybe add later?

  const [dense, setDense] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [isLoading, setLoading] = React.useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    
    logFirebaseEvent(`Browse -> Proposals -> Sort ${property} Tapped`)
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    tableRef.current && tableRef.current.scrollIntoView()
    
    if (lastPageIndex === -1) {
      return
    }

    if (newPage > lastPageIndex) {
      fetchData(newPage)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Backdrop
            style={{'zIndex' : '3500'}}
            className={classes.backdrop}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Table
            innerRef={tableRef}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <Row 
                      prop={row} 
                      user={user}
                      key={index}
                      searchQuery={searchQuery}
                      userMentionsData={userMentionsData}
                      classes={classes}
                      setLoading={setLoading}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
