/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

// Footer class name - className={classes.footer}
export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer> 
      <div className={classes.container}>
        {/* <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="http://oceanit.com/" target="_blank" className={classes.block}>
                Company
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.heatxsurfaces.com/news/" target="_blank" className={classes.block}>
                Blog
              </a>
            </ListItem>
          </List>
        </div> */}
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a
              href="http://oceanit.com/"
              target="_blank"
              className={classes.a}
            >
              Oceanit
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}
