import React, { useState, useEffect, useRef } from 'react'
import {
    Backdrop,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Button,
    Paper,
    Typography,
} from '@material-ui/core'

import {
    useHistory, 
    useLocation 
} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        backgroundColor: '#ffffff',
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: '#ffffff',
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    multilineTextField: {
        marginBottom: theme.spacing(2),
        minHeight: '100px',
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
    refreshButton: {
        marginLeft: theme.spacing(1),
    },
}))


const base_url = process.env.REACT_APP_BASE_URL;

const DocumentTemplate = () => {
    var accountJSON = {}

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const currentUser = checkUser(history)

    const apiRan = useRef(false)
    const urlSearchparams = new URLSearchParams(location.search)

    const [isLoading, setIsLoading] = useState(false)

    const [abstractPrompt, setAbstractPrompt] = useState('Generate 200-300 word abstract for this topic')
    const [abstractResponse, setAbstractResponse] = useState('')

    const [topicData, setTopicData] = useState('')

    useEffect(() => {
		if (!apiRan.current) {
			apiRan.current = true
            let topicID = urlSearchparams.get('topic_id')
            
			if (topicID !== null) {
                fireCreateAIProposalAPI(topicID)
            }
            else {
                setAbstractPrompt('Generate 200-300 word abstract for [TOPIC TITLE]')
            }
		}
	}, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
                pathname: '/signin',
                state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            accountJSON = JSON.parse(accountDetails)

            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function fireCreateAIProposalAPI(topicID) {
        setIsLoading(true)

        fetch(`${base_url}v1/proposal/ai/?id=${currentUser.id}&topic_id=${topicID}&tonly=true`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)

                if (response.ok) {
                    let data = responseJson.data.topic_data
                    setTopicData(data)
                } 
                else {
                    let errorMessage = responseJson.error.message
                    alert(errorMessage)
                }
            })
        })
    }

    async function fireChatGPTAPI(params) {
        setIsLoading(true)

        const response = await fetch(`${base_url}v1/proposalai/ask/?id=${currentUser.id}&index=2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })

        if (!response.ok) {
            setIsLoading(false)
            
            response.json().then((responseJson) => {

                const errorMesssage = responseJson['error']['message']
                alert(errorMesssage)
            })
            .catch(() => {
                alert('Something went wrong. Pleast try again. If the issue persists, let us know.')
            })
            return
        }
    
        const reader = response.body.getReader()
        const decoder = new TextDecoder()
    
        // Initialize an empty string to accumulate the chunks of text
        let accumulatedText = ''
    
        // Function to process text chunks as they arrive
        async function processText() {
            let { done, value } = await reader.read()

            if (done) {
                setIsLoading(false)
                return
            }
    
            // Decode and process the chunk
            const chunk = decoder.decode(value, { stream: true })
            
            accumulatedText += chunk
            setAbstractResponse(accumulatedText)
    
            // Continue reading the next chunk
            processText()
        }
    
        // Start processing the text stream
        processText()
    }

    const handleAbstractPromptChange = (event) => {
        setAbstractPrompt(event.target.value)
    }

    const submitAbstractPrompt = async() => {
        const topicDescription = `Topic Title: ${topicData['title']} \n\n Topic Description: ${topicData['description']}`
        const finalQuestion = topicDescription + '\n\n' + abstractPrompt
        
        let params = {
            'dialog_history' : [{content: finalQuestion, role: 'user'}]
        }

        await fireChatGPTAPI(params) 
    }

    return (
        <Container maxWidth="lg" className={classes.container}>

            <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
            >
                <CircularProgress />
            </Backdrop>

            {
                topicData !== '' && (
                    <Typography variant="h4" gutterBottom>
                        {`${topicData['topic_number']}: ${topicData['title']}`}
                    </Typography>
                )   
            }

            <Typography variant="h5" gutterBottom>
                Abstract Generator
            </Typography>

            <Paper className={classes.paper}>
                <Grid container xs={12} ms={12} alignItems='center'>
                    <Grid item xs={10} md={10}>
                        <TextField
                            placeholder="Generate 200-300 word abstract for this topic"
                            value={abstractPrompt}
                            onChange={handleAbstractPromptChange}
                            fullWidth
                            variant="outlined"
                            className={classes.textField}
                        />
                    </Grid>

                    <Grid item xs={2} md={2} alignItems='center'>
                        <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            onClick={submitAbstractPrompt}
                        >
                            Ask GPT
                        </Button>
                    </Grid>
                </Grid>

                <TextField
                    label=""
                    value={abstractResponse}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={10}
                    className={classes.multilineTextField}
                />
            </Paper>
        </Container>
    )
}

export default DocumentTemplate
