import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'

import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

const base_url = process.env.REACT_APP_BASE_URL;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.oceanit.com/">
        Oceanit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#D37506',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignUp() {
  const classes = useStyles()

  const [loading, setLoading] = React.useState(false)

  const [isFirstName, setIsFirstName] = React.useState(false)
  const [isLastName, setIsLastName] = React.useState(false)

  const [isJobTitle, setIsJobTitle] = React.useState(false)
  const [isEmail, setIsEmail] = React.useState(false)
  const [isPassword, setIsPassword] = React.useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    checkFields(data)
  }

  function resetFields() {
    setIsFirstName(false)
    setIsLastName(false)
    setIsJobTitle(false)
    setIsEmail(false)
    setIsPassword(false)
  }

  function validateEmail(email) {
    return String(email)
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  function checkFields(data) {
      var allChecked = true

      let firstName = data.get('firstName')
      let lastName = data.get('lastName')
      let jobTitle = data.get('jobTitle')

      let email = data.get('email').toLowerCase()
      let password = data.get('password').replace(/\s/g, '')

      if (!firstName.replace(/\s/g, '').length) {
        allChecked = false
        setIsFirstName(true)
      }

      if (!lastName.replace(/\s/g, '').length) {
        allChecked = false
        setIsLastName(true)
      }

      if (!jobTitle.replace(/\s/g, '').length) {
        allChecked = false
        setIsJobTitle(true)
      }

      if (!email.replace(/\s/g, '').length) {
        allChecked = false
        setIsEmail(true)
      }

      if(!validateEmail(email)) {
        allChecked = false
        setIsEmail(true)
        alert('Please provide a valid email address to create an account.')
      }

      if (!email.includes('@oceanit.com')) {
        allChecked = false
        setIsEmail(true)
        alert('Please provide a valid oceanit address')
      }

      if (!password.replace(/\s/g, '').length) {
        allChecked = false
        setIsPassword(true)
      }

      if (allChecked) {
        setLoading(true)

        const account = {
          'first_name' : firstName,
          'last_name' : lastName,

          'title' : jobTitle,
          'email' : email,
          'password' : password,
        }
        createAccount(account)
      }
  }

  function createAccount(account) {
    fetch(`${base_url}v1/login/`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(account),
    })
    .then(response => {
      setTimeout(function() {
        setLoading(false)

        response.json()
        .then(responseJson => {

          if (response.ok) {
            setCookie('currentUser', JSON.stringify(responseJson.data.data), 30)
            alert(responseJson.data.message)

            window.location.reload(true)
          } else {
            alert(responseJson.error.message)
          }
        })
      }, 200)
    })
  }

  function setCookie(name, value, days) {
    var expires = ""
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + (days*24*60*60*1000))
        expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/"
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Sign up
        </Typography>

        <br></br>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                error={isFirstName}
                onChange={resetFields}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                error={isLastName}
                onChange={resetFields}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="jobTitle"
                label="Job Title"
                name="jobTitle"
                autoComplete="jTitle"
                error={isJobTitle}
                onChange={resetFields}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={isEmail}
                onChange={resetFields}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={isPassword}
                onChange={resetFields}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>

          <Grid container justify="center">
            <Grid item>
              <Link href="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}