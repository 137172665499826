import React, { useState, useRef } from "react";
import { useHistory } from 'react-router-dom'

// core components
import { 
    Box,
    Button,
    Drawer,
    IconButton,
    InputBase,
    Grid,
    List,
    ListItem,
    Paper,
} from "@material-ui/core";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";

import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/CloseOutlined';
import SendIcon from '@material-ui/icons/Send';

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

const useStyles = makeStyles((theme) => ({
  root: {
      width: '100%',
      height: '100vh',
      padding: theme.spacing(3),
  },
  header: {
      textAlign: 'center',
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: theme.spacing(2),
  },
  chatList: {
      overflow: 'auto',
      marginBottom: theme.spacing(6), // space for input box
      padding: theme.spacing(2),
      maxHeight: 'calc(100vh - 96px)', // Adjusted for header and input box
  },
  chatInputContainer: {
      width: '80%',
      alignContent: 'center',
      position: 'fixed',
      bottom: 0,
      padding: theme.spacing(1),
  },
  inputRoot: {
      color: 'inherit',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fff',
      padding: '2px 8px',
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      width: '100%',
  },
  inputInput: {
      marginLeft: theme.spacing(1),
      flex: 1,
  },
  sendButton: {
      padding: 10,
  },
  listItemText: {
      wordBreak: 'break-word',
      // backgroundColor: '#61dafb',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      margin: theme.spacing(0.5),
      // color: '#282c34',
      maxWidth: '80%',
  },
  messageBubble: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      margin: theme.spacing(0.3),
      maxWidth: '100%',
      color: '#282c34',
  },
  userBubble: {
      alignSelf: 'flex-end',
  },
  botBubble: {
      alignSelf: 'flex-start',
  },
  avatar: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
  },
  userName: {
      fontWeight: 'bold',
  },
  loaderContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // zIndex: 1300, // Ensure it's above most other components
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    dotsContainer: {
      display: 'flex',
    },
    dot: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      margin: '0 5px',
      animation: '$wave 1.5s infinite ease-in-out',
      '&:nth-child(2)': {
        animationDelay: '-1.2s',
      },
      '&:nth-child(3)': {
        animationDelay: '-0.9s',
      },
    },
    '@keyframes wave': {
      '0%, 60%, 100%': {
        transform: 'initial',
      },
      '30%': {
        transform: 'translateY(-15px)',
      },
    },
    thinkingText: {
      color: theme.palette.common.white,
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@keyframes blink' : {
      '50%' : {
          opacity: 0,
          }
      },
      blinkingCursor: {
          display: 'inline-block',
          marginLeft: '5px',
          width: '2px',
          height: '1em',
          backgroundColor: 'currentColor',
          animation: '$blink 1s step-start 0s infinite',
      }
}));

const base_url = process.env.REACT_APP_BASE_URL;

export default function BrainAI() {

  const classes = useStyles()
  const history = useHistory()
  const currentUser = checkUser(history)

  React.useEffect(() => {
    if (currentUser !== undefined) {
        // localStorage.setItem('brainAI', true)
        // history.goBack()
    }
  }, [])

  function checkUser(history) {
    logFirebaseEvent(`Browse -> BrainGPT -> Arrived`)
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push({
        pathname: '/signin',
        state: {urlPath: '/tool/browse/proposal'},
      })
      return

    } else {
      const accountJSON = JSON.parse(accountDetails)

      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }
      return accountJSON
    }
  }

  const DetailPage = () => {

    var [input, setInput] = useState('')
    const [dialogHistory, setDialogHistory] = useState([])
    const [loading, setLoading] = useState(false)

    const ThinkingLoader = () => {
        const classes = useStyles()
      
        return (
            <div className={classes.loaderContainer}>
                <div className={classes.dotsContainer}>
                    <div className={classes.dot}></div>
                    <div className={classes.dot}></div>
                    <div className={classes.dot}></div>
                </div>
                <Typography variant="h6" className={classes.thinkingText}>
                    Generating...
                </Typography>
            </div>
        )
    }

    const PromptButtons = () => {
        const prompts = [
            { 
                label: 'Generate Proposal', 
                value: 'Write a proposal on mitigation of cybersecurity failures'
            },
            { 
                label: 'Generate Abstract', 
                value: 'Write 500 word abstract for Topic: Next-Generation Sensing and Communication Technology for Cooperative Driving'
            },
            { 
                label: 'Generate Acronyms', 
                value: 'Generate [20] different options of [5-10] word acronyms that sound [Hawaiian] and use [Climate] [Storage] [Earth] as words. Use these as relevant keywords [Renewable] [Batteries]. Topic: Affordable Alane Fuel'
            },
            { 
                label: 'Generate Problem and Significance',
                value: 'Write 500 words on problem and significance of ocean plastic waste. Provide specific data and sources'
            }
        ]
      
        function handlePromptClicked(prompt) {
            setInput(prompt)
            input = prompt

            // sendMessage()
        }
      
        return (
            <Grid container spacing={2}>
                {prompts.map((prompt, index) => (
                    <Grid item key={index} xs={12} md={6}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            onClick={() => handlePromptClicked(prompt.value)}
                            style={{ 
                                padding: '10px', 
                                fontSize: '1rem',
                                color: '#333', 
                                borderRadius: '10px',
                                textTransform: 'none'
                            }}
                        >
                            {prompt.label}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        )
    }   

    const sendMessage = () => {
        if (!input.trim()) return

        // Add user message to dialogHistory array
        const newDialouges = [...dialogHistory, { content: input, role: 'user' }]
        setDialogHistory(newDialouges)

        logFirebaseEvent(`Browse -> BrainGPT -> Prompt ${input}`)

        // Call your API endpoint to get the response
        handleChatGPTAPI(newDialouges)
    }

    function handleChatGPTAPI(newDialouges) {
        setInput('')
        setLoading(true)
        
        let params = {
            'dialog_history' : newDialouges
        }

        fireChatGPTAPI(params)
    }

    async function fireChatGPTAPI(params) {
        setLoading(true)
        const response = await fetch(`${base_url}v1/brainai/ask/?id=${currentUser.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })

        if (!response.ok) {
            response.json().then((responseJson) => {
                setLoading(false)

                const errorMesssage = responseJson['error']['message']
                alert(errorMesssage)
            })
            .catch(() => {
                alert('Something went wrong. Pleast try again. If the issue persists, let us know.')
            })
            return
        }
    
        const reader = response.body.getReader()
        const decoder = new TextDecoder()
    
        // Initialize an empty string to accumulate the chunks of text
        let accumulatedText = ''
    
        // Function to process text chunks as they arrive
        async function processText() {
            let { done, value } = await reader.read()

            if (done) {
                setLoading(false)
                return
            }
    
            // Decode and process the chunk
            const chunk = decoder.decode(value, { stream: true })

            if (accumulatedText === '') {
                accumulatedText += chunk
                setDialogHistory(prevDialouges => [...prevDialouges, { content: accumulatedText, role: 'assistant' }])
            }
            else {
                accumulatedText += chunk
                setDialogHistory(prevDialogues => {
                    // Copy the previous dialogues except for the last one
                    const updatedDialogues = prevDialogues.slice(0, -1)
                
                    // Add the new dialogue with the updated text
                    updatedDialogues.push({ content: accumulatedText, role: 'assistant' })
                
                    return updatedDialogues
                })
            }
    
            // Continue reading the next chunk
            processText()
        }
    
        // Start processing the text stream
        processText()
    }

    const renderMessageText = (text) => {
        // Split the text by new lines and then by tabs
        return text.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                {line.split('\t').map((tabSegment, tabIndex) => (
                    <React.Fragment key={tabIndex}>
                        {tabIndex > 0 && <span style={{ display: 'inline-block', width: '40px' }}></span>} {/* This creates a tab effect */}
                        {tabSegment}
                    </React.Fragment>
                ))}
                {lineIndex < text.split('\n').length - 1 && <br />} {/* Adds a line break except after the last line */}
            </React.Fragment>
        ))
    }

    return (
        <Box className={classes.root}>

            <Typography className={classes.header}>Ask BrainGPT</Typography>

            <Paper className={classes.chatList}>
                <List>
                    {loading && (
                        <ListItem style={{ justifyContent: 'center' }}>
                            <ThinkingLoader />
                        </ListItem>
                    )}
                    {dialogHistory.map((message, index) => (
                        <ListItem key={index} className={classes.messageRow}>
                            <Box className={`${classes.messageBubble} ${message.role === 'user' ? classes.userBubble : classes.botBubble}`}>
                                <Typography className={classes.userName}>{message.role === 'user' ? 'You' : 'BrainGPT'}</Typography>
                                {/* <Typography style={{marginTop: '3px'}}>{renderMessageText(message.content)}</Typography> */}

                                <Typography style={{marginTop: '3px'}}>
                                    {message.role === 'assistant' && index === dialogHistory.length - 1 && loading
                                        ? <span>{renderMessageText(message.content)} <span className={classes.blinkingCursor}></span></span> 
                                        : renderMessageText(message.content)}
                                </Typography>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Paper>


            <Grid className={classes.chatInputContainer}>

                {dialogHistory.length === 0 && (
                    <Grid container alignItems="center" style={{marginBottom: 20}}>
                        <PromptButtons />
                    </Grid>
                )}

                <Paper>
                    <Grid container alignItems="center">
                        <Grid item xs style={{ paddingRight: 8 }}>
                            <InputBase
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                placeholder="Type a message..."
                                inputProps={{ 'aria-label': 'type a message' }}
                                fullWidth
                                value={input}
                                multiline={true}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        sendMessage()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                color="primary"
                                className={classes.sendButton}
                                onClick={sendMessage}
                                aria-label="send"
                            >
                                <SendIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Box>
      );
    };


  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <GridContainer>
        <DetailPage />
    </GridContainer>
  );
}
