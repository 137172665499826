import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { CircularProgress, Backdrop, Paper } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import AWS from 'aws-sdk'
import { downloadZip } from "client-zip/index.js"

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

const s3 = new AWS.S3({
  params: { Bucket: 'oceanit-biosketches'},
  region: 'us-gov-east-1',
})

const useStyles = makeStyles(styles);

function Row(props) {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)

  const {prop, key, openFile, downloadBiosketch, classes} = props

  const handleMouseOver = (event) => {
    setMenuOpen(true)
    setMenuAnchorEl(event.target)
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  const FileItem = (file_name) => {
    return (
      <MenuItem onClick={() => handleFileItemClick(file_name)}>{file_name}</MenuItem>
    )
  }

  const handleFileItemClick = (file_name) => {
    let file_name_split = file_name.split(' -- ')

    let params = {
      's3_key_id' : prop.s3_key_id,
      'file_name' : file_name_split[file_name_split.length - 1]
    }

    openFile(params)
  }

  return (
    <React.Fragment>
      <TableRow key={key} className={classes.tableBodyRow}>
        <TableCell className={classes.tableCell}>
          {prop.person_name}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {prop.field.toString()}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {prop.work_location}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {prop.expertise.toString()}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {prop.owner}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {prop.last_updated}
        </TableCell>

        <TableCell className={classes.tableCell} key='Download'>

        <IconButton 
          aria-owns={menuOpen ? 'simple-menu' : null}
          aria-haspopup="true"
          aria-label="download" 
          onMouseOver={handleMouseOver}
          onClick={() => downloadBiosketch(prop.s3_key_id, prop.person_name)}
        >
          <DownloadIcon />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen}
          MenuListProps={{ onMouseLeave: handleCloseMenu }}
        >
          {prop.files.map(FileItem)}
          
          <MenuItem onClick={() => downloadBiosketch(prop.s3_key_id, prop.person_name)}>Download Files</MenuItem>
        </Menu>

        </TableCell>
        </TableRow>
    </React.Fragment>
  );
}

export default function BiosketchTable(props) {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false)
  const { tableHeaderColor, tableHead, tableData, userID } = props;

  async function openFile(params) {
    setLoading(true)

    let s3KeyID = params['s3_key_id']
    let fileName = params['file_name']

    let fileKey = `${s3KeyID}${fileName}`

    const url = s3.getSignedUrl('getObject', {
        Bucket: 'oceanit-biosketches',
        Key: fileKey,
        Expires: 60 * 10
    })

    setLoading(false)
    window.open(url, '_blank')

    logFirebaseEvent(`Browse -> Biosketch -> Downloaded Biosketch ${fileName}`)
  }

  async function downloadBiosketch(fileKey, biosketchName) {
    setLoading(true)

    var fileArray = []
    const params = {
      Bucket: 'oceanit-biosketches',
      Delimiter: '/',
      Prefix: fileKey,
    }

    let objectData = await s3.listObjectsV2(params).promise()
    let contents = objectData.Contents
    
    for (let i = 0; i < contents.length; i++) {
      let params = {
        Bucket: 'oceanit-biosketches',
        Key: contents[i].Key
      }
      let url = await s3.getSignedUrlPromise('getObject', params)
      let fileData = await fetch(url)
      fileArray.push(fileData)
    }

    downloadZip(fileArray).blob()
    .then(data => {
      setLoading(false)
      logFirebaseEvent(`Browse -> Biosketch -> Downloaded Biosketch ${biosketchName}`)

      const hiddenElement = document.createElement("a")
      hiddenElement.href = URL.createObjectURL(data)

      hiddenElement.download = `${biosketchName}.zip`
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  return (
    <div className={classes.tableResponsive}>
      <Backdrop
        style={{'zIndex' : '3500'}}
        className={classes.backdrop}
        open={isLoading}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <Row 
                prop={prop}
                key={key}
                openFile={openFile}
                downloadBiosketch={downloadBiosketch} 
                classes={classes} 
              />
            )
          })}
        </TableBody>
      </Table>
    </div>
  );
}


BiosketchTable.defaultProps = {
  tableHeaderColor: "gray",
};

BiosketchTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
