import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
    Backdrop,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Button,
    IconButton,
    Paper,
    Box,
    TextareaAutosize,
    Typography,
} from '@material-ui/core'

import {
    useHistory, 
    useLocation 
} from 'react-router-dom'

import { 
    AlignmentType,
    BorderStyle,
    Table,
    TableCell,
    TableRow,
    WidthType,
    Document,
    Packer, 
    Paragraph, 
    TextRun, 
    HeadingLevel, 
    Header, 
    Footer,
    PageNumber,
} from 'docx'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import RefreshIcon from '@material-ui/icons/Refresh'

import { makeStyles } from '@material-ui/core/styles'

import { saveAs } from 'file-saver'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        backgroundColor: '#ffffff',
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: '#ffffff',
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    multilineTextField: {
        marginBottom: theme.spacing(2),
        minHeight: '100px',
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
    refreshButton: {
        marginLeft: theme.spacing(1),
    },
}))

const highlightText = (text) => {
    const regex = /\[([^\]]+)\]/g
    return text.split(regex).map((part, index) => {
        if (index % 2 === 1) {
            return `<span style="background-color: yellow;">${part}</span>`
        }
        return part
    }).join('')
}


const base_url = process.env.REACT_APP_BASE_URL;

const HighlightedText = ({ text }) => {
    const highlightedHTML = highlightText(text)

    return (
        <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: highlightedHTML }}
            style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}
        />
    )
}

const DocumentTemplate = () => {
    var accountJSON = {}

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const currentUser = checkUser(history)

    const apiRan = useRef(false)
    const urlSearchparams = new URLSearchParams(location.search)

    const [isLoading, setIsLoading] = useState(false)

    const [sections, setSections] = useState([{ title: '', paragraphs: [''] }])
    const [sectionsCache, setSectionsCache] = useState([{ title: '', paragraphs: ['']}])

    const [gptTopicInfo, setGptTopicInfo] = useState('')
    const [topicData, setTopicData] = useState({})

    const [responseGenerated, setResponseGenerated] = useState(
        [[false]]
    )
    const [gptPrompts, setGptPrompts] = useState(
        [['']]
    )

    const [isEditing, setIsEditing] = useState(
        sections.map((section) => section.paragraphs.map(() => false))
    )

    useEffect(() => {
		if (!apiRan.current) {
			apiRan.current = true
            let topicID = urlSearchparams.get('topic_id')
			fireCreateAIProposalAPI(topicID)
		}
	}, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
                pathname: '/signin',
                state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            accountJSON = JSON.parse(accountDetails)

            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    const fireCreateAIProposalAPI = (topicID) => {
        setIsLoading(true)

        fetch(`${base_url}v1/proposal/ai/?id=${accountJSON.id}&topic_id=${topicID}`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)

                if (response.ok) {
                    let responseData = responseJson.data.data
                    let gptPromptData = responseJson.data.gpt_prompt_data
                    let topicData = responseJson.data.topic_data

                    let gptTopicInfo = gptPromptData.gpt_topic_info.replace(/[\n\r]/g, '')

                    let gptPrompts = responseData.map((section) => section.paragraphs.map(() => {
                        return 'Write 300 words for this section paragraph'
                    }))

                    setGptPrompts(gptPrompts)
                    setTopicData(topicData)

                    setResponseGenerated(
                        responseData.map((section) => section.paragraphs.map(() => false))
                    )

                    setIsEditing(
                        responseData.map((section) => section.paragraphs.map(() => false))
                    )

                    setSections(responseData)
                    setSectionsCache(responseData)
                    setGptTopicInfo(gptTopicInfo)
                } 
                else {
                    let errorMessage = responseJson.error.message
                    alert(errorMessage)
                }
            })
        })
    }

    const handleAddSection = () => {
        setSections([...sections, { title: '', paragraphs: ['']}])
        setResponseGenerated([...responseGenerated, [false]])
        setGptPrompts([...gptPrompts, ['']])
        setIsEditing([...isEditing, [false]])
    }

    const handleRemoveSection = (index) => {
        const updatedSections = sections.filter((_, idx) => idx !== index)
        setSections(updatedSections)

        const updatedResponses = responseGenerated.filter((_, idx) => idx !== index)
        setResponseGenerated(updatedResponses)

        const updatedPrompts = gptPrompts.filter((_, idx) => idx !== index)
        setGptPrompts(updatedPrompts)

        const updatedEditing = isEditing.filter((_, idx) => idx !== index)
        setIsEditing(updatedEditing)
    }

    const handleTitleChange = (index, value) => {
        const updatedSections = sections.map((section, idx) =>
            idx === index ? { ...section, title: value } : section
        )
        setSections(updatedSections)
    }

    const handleParagraphChange = (sectionIndex, paragraphIndex, value) => {
        const updatedSections = sections.map((section, idx) =>
            idx === sectionIndex
                ? {
                        ...section,
                        paragraphs: section.paragraphs.map((p, pIdx) =>
                            pIdx === paragraphIndex ? value : p
                        ),
                    }
                : section
        )
        setSections(updatedSections)
    }

    const handleAddParagraph = (index) => {
        const updatedSections = sections.map((section, idx) =>
            idx === index
                ? { ...section, paragraphs: [...section.paragraphs, ''] }
                : section
        )
        setSections(updatedSections)

        const updatedResponses = responseGenerated.map((response, idx) =>
            idx === index ? [...response, false] : response
        )
        setResponseGenerated(updatedResponses)

        const updatedPrompts = gptPrompts.map((prompts, idx) =>
            idx === index ? [...prompts, ''] : prompts
        )
        setGptPrompts(updatedPrompts)

        const updatedEditing = isEditing.map((editing, idx) =>
            idx === index ? [...editing, false] : editing
        )
        setIsEditing(updatedEditing)
    }

    const handleRemoveParagraph = (sectionIndex, paragraphIndex) => {
        const updatedSections = sections.map((section, idx) =>
            idx === sectionIndex
                ? {
                        ...section,
                        paragraphs: section.paragraphs.filter(
                            (_, pIdx) => pIdx !== paragraphIndex
                        ),
                    }
                : section
        )
        setSections(updatedSections)

        const updatedResponses = responseGenerated.map((response, idx) =>
            idx === sectionIndex
                ? response.filter((_, pIdx) => pIdx !== paragraphIndex)
                : response
        )
        setResponseGenerated(updatedResponses)

        const updatedPrompts = gptPrompts.map((prompts, idx) =>
            idx === sectionIndex
                ? prompts.filter((_, pIdx) => pIdx !== paragraphIndex)
                : prompts
        )
        setGptPrompts(updatedPrompts)

        const updatedEditing = isEditing.map((editing, idx) =>
            idx === sectionIndex
                ? editing.filter((_, pIdx) => pIdx !== paragraphIndex)
                : editing
        )
        setIsEditing(updatedEditing)
    }

    const handleResetResponse = (sectionIndex, paragraphIndex) => {
        const updatedSections = sections.map((section, idx) =>
            idx === sectionIndex
                ? {
                        ...section,
                        paragraphs: section.paragraphs.map((p, pIdx) =>
                            pIdx === paragraphIndex ? sectionsCache[sectionIndex].paragraphs[paragraphIndex] : p
                        ),
                    }
                : section
        )

        setSections(updatedSections)

        const updatedResponses = responseGenerated.map((response, idx) =>
            idx === sectionIndex
                ? response.map((res, pIdx) => (pIdx === paragraphIndex ? false : res))
                : response
        )
        setResponseGenerated(updatedResponses)
    }

    const handleChatbotResponse = async (sectionIndex, paragraphIndex) => {
        const question = document.getElementById(`question-${sectionIndex}-${paragraphIndex}`).value
        const paragraphTemplate = `Section Template: ${sectionsCache[sectionIndex].paragraphs[paragraphIndex]}`
        
        const finalQuestion = gptTopicInfo + '\n\n' + question + paragraphTemplate
        
        let params = {
            'dialog_history' : [{content: finalQuestion, role: 'user'}]
        }

        await fireChatGPTAPI(params, sectionIndex, paragraphIndex)
    }

    function updateSections(response, sectionIndex, paragraphIndex) {
        const updatedSections = sections.map((section, idx) => 
            idx === sectionIndex 
                ? {
                    ...section,
                    paragraphs: section.paragraphs.map((p, pIdx) =>
                        pIdx === paragraphIndex ? response : p
                    ),
                } 
                : section
        )
        setSections(updatedSections)

        const updatedResponses = responseGenerated.map((response, idx) =>
            idx === sectionIndex
                ? response.map((res, pIdx) => (pIdx === paragraphIndex ? true : res))
                : response
        )
        setResponseGenerated(updatedResponses)
    }

    const handlePromptChange = (sectionIndex, paragraphIndex, value) => {
        const updatedPrompts = gptPrompts.map((prompts, idx) =>
            idx === sectionIndex
                ? prompts.map((prompt, pIdx) =>
                    pIdx === paragraphIndex ? value : prompt
                )
                : prompts
        )
        setGptPrompts(updatedPrompts)
    }

    async function fireChatGPTAPI(params, sectionIndex, paragraphIndex) {
        setIsLoading(true)

        const response = await fetch(`${base_url}v1/proposalai/ask/?id=${currentUser.id}&index=0`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })

        if (!response.ok) {
            setIsLoading(false)
            
            response.json().then((responseJson) => {

                const errorMesssage = responseJson['error']['message']
                alert(errorMesssage)
            })
            .catch(() => {
                alert('Something went wrong. Pleast try again. If the issue persists, let us know.')
            })
            return
        }
    
        const reader = response.body.getReader()
        const decoder = new TextDecoder()
    
        // Initialize an empty string to accumulate the chunks of text
        let accumulatedText = ''
    
        // Function to process text chunks as they arrive
        async function processText() {
            let { done, value } = await reader.read()

            if (done) {
                setIsLoading(false)
                return
            }
    
            // Decode and process the chunk
            const chunk = decoder.decode(value, { stream: true })
            
            accumulatedText += chunk
            updateSections(accumulatedText, sectionIndex, paragraphIndex)
    
            // Continue reading the next chunk
            processText()
        }
    
        // Start processing the text stream
        processText()
    }

    const generateWord = async () => {
        const header = new Header({
            children: [
                new Table({
                    width: { size: 100, type: WidthType.PERCENTAGE },
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [new Paragraph("OCEANIT")],
                                    width: { size: 30, type: WidthType.PERCENTAGE },
                                    borders: {
                                        top: { style: BorderStyle.NONE },
                                        bottom: { style: BorderStyle.NONE },
                                        left: { style: BorderStyle.NONE },
                                        right: { style: BorderStyle.NONE },
                                    },
                                    verticalAlign: AlignmentType.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({
                                        text: `${topicData['number']}`,
                                        alignment: AlignmentType.CENTER,
                                    })],
                                    width: { size: 30, type: WidthType.PERCENTAGE },
                                    borders: {
                                        top: { style: BorderStyle.NONE },
                                        bottom: { style: BorderStyle.NONE },
                                        left: { style: BorderStyle.NONE },
                                        right: { style: BorderStyle.NONE },
                                    },
                                    verticalAlign: AlignmentType.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({
                                        text: "H231-002-0073",
                                        alignment: AlignmentType.RIGHT,
                                    })],
                                    width: { size: 30, type: WidthType.PERCENTAGE },
                                    borders: {
                                        top: { style: BorderStyle.NONE },
                                        bottom: { style: BorderStyle.NONE },
                                        left: { style: BorderStyle.NONE },
                                        right: { style: BorderStyle.NONE },
                                    },
                                    verticalAlign: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    ],
                }),
            ],
        })
    
        const footer = new Footer({
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            children: ["Page ", PageNumber.CURRENT, " of ", PageNumber.TOTAL_PAGES],
                        }),
                    ],
                    alignment: AlignmentType.CENTER,
                }),
            ],
        })
    
        const doc = new Document({
            sections: [
                {
                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1
                            }
                        }
                    },
                    headers: { default: header },
                    footers: { default: footer },
                    children: [
                        new Paragraph({
                            text: `${topicData['title']}`,
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                            border: {
                                bottom: {
                                    color: "auto",
                                    space: 1,
                                    value: "single",
                                    size: 6,
                                },
                            },
                        }),
                        ...sections.flatMap((section) => [
                            new Paragraph({
                                text: section.title || 'Untitled Section',
                                heading: HeadingLevel.HEADING_1,
                            }),
                            new Paragraph({
                                border: {
                                    bottom: {
                                        color: "auto",
                                        space: 1,
                                        value: "single",
                                        size: 6,
                                    },
                                },
                            }),
                            ...section.paragraphs.map((paragraph) =>
                                new Paragraph({
                                    children: paragraph.split(/(\[.*?\])/).map((part) =>
                                        part.startsWith('[') && part.endsWith(']')
                                            ? new TextRun({
                                                text: part,
                                                highlight: 'yellow',
                                            })
                                            : new TextRun(part)
                                    ),
                                })
                            ),
                        ]),
                    ],
                }
            ],
        })    
    
        const blob = await Packer.toBlob(doc)
        saveAs(blob, 'document.docx')
    } 

    const handleEditToggle = (sectionIndex, paragraphIndex) => {
        const updatedEditing = isEditing.map((editing, idx) =>
            idx === sectionIndex
                ? editing.map((edit, pIdx) =>
                    pIdx === paragraphIndex ? !edit : edit
                )
                : editing
        )
        setIsEditing(updatedEditing)
    }

    const onDragEnd = (result, sectionIndex) => {
        if (!result.destination) {
            return
        }

        const updatedSections = sections.map((section, idx) => {
            if (idx !== sectionIndex) {
                return section
            }

            const reorderedParagraphs = Array.from(section.paragraphs)
            const [removed] = reorderedParagraphs.splice(result.source.index, 1)
            reorderedParagraphs.splice(result.destination.index, 0, removed)

            return {
                ...section,
                paragraphs: reorderedParagraphs,
            }
        })

        setSections(updatedSections)
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
            >
                <CircularProgress />
            </Backdrop>
            <Typography variant="h4" gutterBottom>
                {`${topicData['number']}: ${topicData['title']}`}
            </Typography>
            {sections.map((section, sectionIndex) => (
                <Paper key={sectionIndex} className={classes.paper}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <TextField
                            label={`Section Title ${sectionIndex + 1}`}
                            value={section.title}
                            onChange={(e) =>
                                handleTitleChange(sectionIndex, e.target.value)
                            }
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            className={classes.textField}
                        />
                        <IconButton
                            onClick={() => handleRemoveSection(sectionIndex)}
                            className={classes.deleteButton}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>

                    <DragDropContext onDragEnd={(result) => onDragEnd(result, sectionIndex)}>
                            <Droppable droppableId={`section-${sectionIndex}`}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {section.paragraphs.map((paragraph, paragraphIndex) => (
                                            <Draggable key={paragraphIndex} draggableId={`paragraph-${paragraphIndex}`} index={paragraphIndex}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Box key={paragraphIndex} mb={2}>
                                                            <Box display="flex" alignItems="center" mb={1}>
                                                                <TextField
                                                                    id={`question-${sectionIndex}-${paragraphIndex}`}
                                                                    label="Write [WORD-LENGTH] words on [SECTION-NAME] for this topic"
                                                                    value={gptPrompts[sectionIndex][paragraphIndex]}
                                                                    onChange={(e) => handlePromptChange(sectionIndex, paragraphIndex, e.target.value)}
                                                                    fullWidth
                                                                    onKeyPress={(event) => {
                                                                        if (event.key === 'Enter') {
                                                                            handleChatbotResponse(sectionIndex, paragraphIndex)
                                                                        }
                                                                    }}
                                                                    variant="outlined"
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => handleChatbotResponse(sectionIndex, paragraphIndex)}
                                                                    className={classes.addButton}
                                                                >
                                                                    Ask GPT
                                                                </Button>
                                                            </Box>
                                                                {responseGenerated[sectionIndex][paragraphIndex] && (
                                                                    <Box display="flex" alignItems="center" mb={1}>
                                                                        {/* <TextField
                                                                            label="Response"
                                                                            value={paragraph}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            disabled
                                                                        /> */}
                                                                        <IconButton
                                                                            onClick={() => handleResetResponse(sectionIndex, paragraphIndex)}
                                                                            className={classes.refreshButton}
                                                                        >
                                                                            <RefreshIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}

                                                            <Box display="flex" alignItems="center">
                                                                {isEditing[sectionIndex][paragraphIndex] ? (
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        multiline
                                                                        rows={12}
                                                                        value={paragraph}
                                                                        onChange={(e) =>
                                                                            handleParagraphChange(
                                                                                sectionIndex,
                                                                                paragraphIndex,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        onBlur={() => handleEditToggle(sectionIndex, paragraphIndex)}
                                                                        className={classes.multilineTextField}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        onClick={() => handleEditToggle(sectionIndex, paragraphIndex)}
                                                                        style={{
                                                                            border: '1px solid #ced4da',
                                                                            padding: '10px',
                                                                            borderRadius: '4px',
                                                                            minHeight: '100px',
                                                                            overflow: 'auto',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <HighlightedText text={paragraph} />
                                                                    </div>
                                                                )}
                                                                {/* <TextField
                                                                    label={`Paragraph ${paragraphIndex + 1}`}
                                                                    value={paragraph}
                                                                    onChange={(e) =>
                                                                        handleParagraphChange(
                                                                            sectionIndex,
                                                                            paragraphIndex,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    multiline
                                                                    rows={12}
                                                                    className={classes.multilineTextField}
                                                                /> */}
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleRemoveParagraph(sectionIndex, paragraphIndex)
                                                                    }
                                                                    className={classes.deleteButton}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Box> 
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddParagraph(sectionIndex)}
                        startIcon={<AddIcon />}
                        className={classes.addButton}
                    >
                        Add Paragraph
                    </Button>
                </Paper>
            ))}
            <Button
                variant="contained"
                color="secondary"
                onClick={handleAddSection}
                startIcon={<AddIcon />}
                className={classes.addButton}
            >
                Add Section
            </Button>
            <Button
                variant="contained"
                color="default"
                onClick={generateWord}
                className={classes.addButton}
            >
                Export to Word
            </Button>
        </Container>
    )
}

export default DocumentTemplate
