import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'

import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

const base_url = process.env.REACT_APP_BASE_URL;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.oceanit.com/">
        Oceanit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#D37506',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ResetPassword() {
  const history = useHistory()
  const classes = useStyles()
  
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')

  const [loading, setLoading] = React.useState(false)
  const [isPassword, setIsPassword] = React.useState(false)
  const [isConfirmPassword, setIsConfirmPassword] = React.useState(false)
  const [doPasswordsMatch, setPasswordsMatch] = React.useState(true)
  const [helperText, setHelperText] = React.useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    checkFields(data)
  }

  function resetFields() {
    setIsPassword(false)
    setIsConfirmPassword(false)
    setPasswordsMatch(true)
  }

  function checkFields(data) {
      var allChecked = true
      var password = data.get('password')
      var confirmPassword = data.get('confirmPassword')

      if (password === '') {
        allChecked = false
        setIsPassword(true)
      }

      if (confirmPassword === '') {
        allChecked = false
        setIsConfirmPassword(true)
      }

      if (password !== confirmPassword) {
        setPasswordsMatch(false)
        setHelperText('Passwords do not match!')
      }

      if (allChecked) {
        setLoading(true)

        const data = {
          'cred' : password
        }
        resetPassword(data)
      }
  }

  function resetPassword(data) {
    fetch(`${base_url}v1/login/?type=cred&id=${id}`, {
      method: 'PUT',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => {
      setTimeout(function() {
        setLoading(false)

        response.json()
        .then(responseJson => {

          if (response.ok) {
            alert(responseJson.data.message)
            history.push('/signin')
          } else {
            alert(responseJson.error.message)
          }
          window.location.reload(false)
        })
      }, 200)
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        <br></br>

        <Typography component="h2" variant="h6">
          Enter your new password below
        </Typography>
        
        <br></br>

        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>

        <br></br>
        <br></br>
        
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container justify="center">
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="password"
                name="password"
                variant="outlined"
                type="password"
                required
                fullWidth
                id="password"
                label="Password"
                autoFocus
                error={isPassword}
                onChange={resetFields}
              />
            </Grid>
        </Grid>

        <br></br>
        <br></br>

        <Grid container justify="center">
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="confirmPassword"
                name="confirmPassword"
                variant="outlined"
                type="password"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                autoFocus
                error={isConfirmPassword || !doPasswordsMatch}
                helperText={helperText}
                onChange={resetFields}
              />
            </Grid>
        </Grid>

        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
        </Button>
      </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}