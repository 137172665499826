import React, { useRef, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from 'react-router-dom'

import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import { Backdrop, Button, CircularProgress, Box, Grid, TextField, IconButton, Fade } from "@material-ui/core"

import CloseIcon from '@material-ui/icons/CloseOutlined';
import Drawer from '@material-ui/core/Drawer';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  backdrop: {
    zIndex: 5,
    color: '#fff',
  },
  list: {
    width: 800,
  },
}

const useStyles = makeStyles(styles)
var profile = {}

const base_url = process.env.REACT_APP_BASE_URL;

export default function UserProfile() {
  const classes = useStyles()
  const history = useHistory()

  const currentUser = checkUser(history)
  const feedbackRef = useRef(null)

  const [feedbackOpen, setFeedbackOpen] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  const [firstName, setFirstName] = useState(profile.first_name)
  const [lastName, setLastName] = useState(profile.last_name)

  const [jobTitle, setJobTitle] = useState(profile.title)
  const [email] = useState(profile.email)

  const [userRole] = useState(profile.role)

  React.useEffect(() => {
    if (currentUser !== undefined) {

    }
  }, [])

  function checkUser(history) {
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push({
        pathname: '/signin',
        state: {'urlPath': '/tool/user'}
      })

      return
    } else {
      const accountJSON = JSON.parse(accountDetails)

      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }

      profile = accountJSON
      profile.password = ''

      return profile
    }
  }

  function updateUserInCookie(account) {
    setCookie('currentUser', JSON.stringify(account), 30)
  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  function prepareToUpdateMemberDetails() {
    setLoading(true)

    let memberDetails = {
      'first_name' : firstName,
      'last_name' : lastName,
      'title' : jobTitle,
    }

    updateMemberDetails(memberDetails)
  }

  function updateMemberDetails(memberDetails) {
    fetch(`${base_url}v1/login/?id=${currentUser.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(memberDetails)
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert('Something went wrong. Please try again.')
        } 
        else {
          let account = responseJson.data.data
          let responseMessage = responseJson.data.message

          updateUserInCookie(account)
          alert(responseMessage)
        }
      })
    })
  }

  const LoadingFade = () => {
    return (
      <Backdrop open={isLoading} style={{zIndex: '5'}}>
        <CircularProgress />
      </Backdrop>
    )
  }

  const handleFeedbackComponent = () => {

    if (feedbackOpen) {
      let feedbackText = feedbackRef.current.value

      if (feedbackText !== '' && feedbackText.trim() !== '') {
        let feedback = {
          'feedback' : feedbackText
        }
        fireFeedbackAPI(feedback)
      }
      else {
        alert('Please provide some feedback to submit.')
      } 
    }
    else {
      setFeedbackOpen(true)
    }
  }

  function fireFeedbackAPI(feedback) {
    setLoading(true)
    fetch(`${base_url}v1/feedback/?id=${profile.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedback)
    })
    .then(response => {
      setLoading(false)
      setFeedbackOpen(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          alert(responseJson.data.message)
        }
      })
    })
  }

  const handleFeedbackClose = () => {
    setFeedbackOpen(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFeedbackComponent()
    }
}

  const Feedback = () => {
    return (
      <Drawer anchor={'right'} open={feedbackOpen} onClose={handleFeedbackClose}>
          <FeedbackDetailPage />
      </Drawer>  
    )
  }

  const FeedbackDetailPage = () => {
    return (
      <Box
          className={clsx(classes.list)}
          role="presentation"
      >
          <LoadingFade />

          <IconButton 
              aria-label="close" 
              onClick={handleFeedbackClose}
          >
              <CloseIcon />
          </IconButton>

          <h2 style={{'textAlign' : 'center'}}>Feedback</h2>

          <TextField
            style={{'marginLeft' : '3%', 'marginRight' : '3%', width: '94%'}}
            inputRef={feedbackRef}
            id="outlined-multiline-static"
            label="Feedback"
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            onKeyDown={handleKeyDown}
          />

          <Button
            style={{'marginTop' : '20px', marginLeft: '47%', marginRight: '47%'}}
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleFeedbackComponent}
          >
            Submit
          </Button>
      </Box>
    )
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <div>
      {/* <Feedback/> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>

          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>My Profile</h4>
            </CardHeader>

            <CardBody>

            <GridContainer>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="first-name"
                    value={firstName}
                    onChange={(newValue) => {
                      setFirstName(newValue.target.value)
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="last-name"
                    value={lastName}
                    onChange={(newValue) => {
                      setLastName(newValue.target.value)
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Job Title"
                    id="job-title"
                    value={jobTitle}
                    onChange={(newValue) => {
                      setJobTitle(newValue.target.value)
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email address"
                    id="email-address"
                    value={email}
                    formControlProps={{
                      fullWidth: true,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Access Role"
                    id="access-role"
                    value={userRole}
                    formControlProps={{
                      fullWidth: true,
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>

            </CardBody>

          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={8}>

        <Button
          style={{'marginRight' : '10px'}}
          type="submit"
          variant="contained"
          size="medium"
          color="primary"
          onClick={prepareToUpdateMemberDetails}
        >
            Update Details
        </Button>
        
        <Button
          style={{'marginLeft' : '10px'}}
          type="submit"
          variant="contained"
          size="medium"
          color="primary"
          onClick={handleFeedbackComponent}
        >
            Share Feedback
        </Button>
      </GridItem>

      <Feedback/>

      </GridContainer>
    </div>
  )
}
