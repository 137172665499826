/* eslint-disable no-loop-func */
import React, { useRef, useState } from "react";
import {
  useHistory, 
  useLocation 
} from 'react-router-dom'
  
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TopicTable from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { 
  SearchOutlined
 } from "@material-ui/icons";

// @material-ui/core components
 import { 
  Backdrop, 
  Button, 
  Checkbox, 
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Grid, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  TextField,
  Typography, 
  Menu
} from "@material-ui/core";

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

let headCellData = [
  {id: 'members_interested_count', title: 'Likes', numeric: true, disablePadding: false},
  {id: 'title', title: 'Title', numeric: false, disablePadding: false},
  {id: 'organisation', title: 'Organisation', numeric: false, disablePadding: false},

  {id: 'agency', title: 'Agency', numeric: false, disablePadding: false},
  {id: 'keywords', title: 'Keywords', numeric: false, disablePadding: false},

  {id: 'release_date', title: 'Release Date', numeric: true, disablePadding: false},
  {id: 'due_date', title: 'Due Date', numeric: true, disablePadding: false},
  {id: 'topic_url', title: 'Topic URL', numeric: false, disablePadding: false},

  {id: 'related_proposals_count', title: 'Related Proposals', numeric: true, disablePadding: false},
  {id: 'comment_count', title: 'Comments', numeric: true, disablePadding: false},
  {id: 'proposal_ai', title: 'Proposal AI', numeric: false, disablePadding: false},
]

let listItemValues = [
  'Department',
  'Agency',
  'Grant',
  'My Activity'
]

let departmentValues = [

  'DOD',
  'DOE',

  'DOH',
  'HTDC',
  'NIH',

  'NOAA',
  'NASA',
  'NSF',

  'NIST',
  'TAQA',
  'INIT',

  'DHP',
  'EBRAP',
  'BARDA',

  'DOT',
  'EPA',
  'CDMRP',

  'USDA'
]

let agencyValues = [

  'DARPA',
  'ONR',
  'DHA',

  'NAVSEA',
  'NAVY',
  'ARMY',

  'AIR_FORCE',
  'MDA',
  'NAVAIR',

  'AFRL',
  'OSD',
  'CBD',

  'NGA',
  'AFOSR',
  'ARPA',

  'SOCOM',
  'USAF',
  'PACOM',

  'NSA',
  'DISA',
  'DTRA',

  'DHS',
  'OTHER'
]

let grantValues = [
  'SBIR',
  'STTR',
]

let phaseValues = [
  'PHASE 1',
  'PHASE 2',
]

let myActivityValues = [
  'Likes',
  'Comments',
  'Tagged Comments'
]

let listValueData = [
  departmentValues,
  agencyValues,
  grantValues,
  myActivityValues
]

const base_url = process.env.REACT_APP_BASE_URL;

export default function TopicsArchivedTableList() {
  const history = useHistory()
  const location = useLocation()
  const currentUser = checkUser(history)

  const tableRef = useRef(null)
  const searchBarRef = useRef(null)
  
  const urlSearchparams = new URLSearchParams(location.search)

  var [tableData, setTableData] = useState([])
  var [userMentionsData, setUserMentionsData] = useState([])

  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [cacheTotalCount, setCacheTotalCount] = useState(0)

  const [isLoading, setLoading] = useState(false)

  const [lastPageIndex, setLastPageIndex] = useState(0)
  var [searchQuery, setSearchQuery] = useState('')

  const [filtersSelected, setFiltersSelected] = useState([])

  const [checkBoxesData, setCheckBoxesData] = useState({})
  const [clearFilterButtonVisibility, setClearFilterButtonVisibility] = useState('none')

  const [searchResults, setSearchResults] = useState([])
  var [searchParams, setSearchParams] = useState({})

  const [onDeviceFilterActive, setOnDeviceFilterActive] = useState(false)
  const [onDeviceSearchActive, setOnDeviceSearchActive] = useState(false)

  React.useEffect(() => {
    runInitalSetup()
  }, [])

  function runInitalSetup() {
    if (currentUser !== undefined) {
      let searchType = urlSearchparams.get('type')

      if (searchType !== null) {
        checkFor(searchType)
      }
      else {
        fetchData()
      }
    }
    else {
      alert('It looks like you are not signed in. Please signin to continue.')
    }
  }

  function checkUser(history) {
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push({
        pathname: '/signin',
        state: {urlPath: '/tool/browse/proposal'},
      })
      return

    } else {
      const accountJSON = JSON.parse(accountDetails)
      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }
      return accountJSON
    }
  }

  function checkFor(searchType) {
    if (searchType === 'search') {
      searchQuery = urlSearchparams.get('q')
      searchKeyword()
    }
    else {

    }
  }

  function fetchData(pageIndex=0) {
    setLoading(true)

    fetch(`${base_url}v1/topics/?id=${currentUser.id}&page=${pageIndex}&archived=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {

      response.json()
      .then(responseJson => {
        setLoading(false)

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          if (pageIndex === 0) {
            let tableData = responseJson.data.data
            let totalCount = responseJson.data.count
            let userMentionsData = responseJson.data.user_mentions_data

            setTableData(tableData)
            setUserMentionsData(userMentionsData)

            setTotalCount(totalCount)
            setCacheTotalCount(totalCount)

            setLoading(false)
          }
          else {
            let newTableData = tableData.concat(responseJson.data.data)

            setTableData(newTableData)
            setLoading(false)
          }
        }
        setLastPageIndex(pageIndex)
      })
    })
  }

  function searchKeyword() {
    let search = searchQuery

    if (search.length === 0 || !search.replace(/\s/g, '').length) {
      alert('Please enter a valid search.')
      return
    }

    let keywords = search.split(',')
    let searchParams = {
      'keywords' : keywords
    }

    setSearchQuery(search)
    setOnDeviceFilterActive(true)

    logFirebaseEvent(`Browse -> Topics -> Search ${search}`)

    if (onDeviceSearchActive) {
      fireOnDeviceSearchAPI(searchParams)
    }
    else {
      fireSearchAPI(searchParams)
    }
  }

  function fireOnDeviceSearchAPI(searchParams) {
    var updatedTableData = []

    let keywords = searchParams['keywords']
    let proposals = keywords.map(searchKeywordOnDevice)

    proposals.forEach(function(proposal) {
      updatedTableData = updatedTableData.concat(proposal)
    })

    if (updatedTableData.length === 0) {
      alert('It looks like there are no topics for this search.')
      return
    }

    setTableData(updatedTableData)
  }

  function searchKeywordOnDevice(keyword) {
    var proposals = []

    searchResults.forEach(function(proposal) {

      let proposalValues = Object.values(proposal)

      proposalValues.forEach(function(proposalValue) {

        if (typeof proposalValue === 'string') {

          if (proposalValue.toLowerCase().includes(keyword.toLowerCase())) {
            proposals.push(proposal)
          }
        }
      })
    })

    return [...new Set(proposals)];
  }

  function fireSearchAPI(searchParams) {
    setLoading(true)
    fetch(`${base_url}v1/topics/search/?id=${currentUser.id}&archived=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchParams)
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
          setSearchQuery('')
        } 
        else {
          let tableData = responseJson.data.data

          setTableData(tableData)
          setSearchResults(tableData)

          setLastPageIndex(-1)
          setTotalCount(tableData.length)
        }
      })
    })
  }

  // function checkPhaseValue(value) {
  //   let phaseMappings = {
  //     'PHASE 1' : 'PHASE_1',
  //     'PHASE 2' : 'PHASE_2',
  //   }

  //   return phaseMappings[value]
  // }

  function setCheckBoxData() {
    var data = {}

    listValueData.forEach(listData => {

      listData.forEach(listValue => {
        data[listValue] = false
      })
    })

    setCheckBoxesData(data)
  }

  const handleClearFiltersButtonClicked = () => {
    var data = checkBoxesData

    for (var key in data) {
      data[key] = false
    }

    setSearchParams({})
    setCheckBoxData({})
    setFiltersSelected([])

    setOnDeviceFilterActive(false)
    setOnDeviceSearchActive(false)

    setClearFilterButtonVisibility('none')
    revertToDefaultTableData()

    setSearchQuery('')

    setPage(0)
    setLastPageIndex(0)

    searchBarRef.current.value = ''
    tableRef.current && tableRef.current.scrollIntoView()
  }

  const handleCheckboxChange = (event) => {
    let category = event.target.id
    let value = event.target.value
    let checked = event.target.checked

    // Used below in categoryMappings if category is My Activity, with value either Likes or Comments.
    let categoryMappingValue = ''

    let eventParams = {
      'value' : value,
      'checked' : checked
    }

    if (category === 'My Activity') {

      if (value === 'Likes') {
        categoryMappingValue = 'members_interested__in'
      }
      else if (value === 'Comments') {
        categoryMappingValue = 'members_commented__in'
      }
      else {
        categoryMappingValue = 'members_tagged__in'
      }
      value = currentUser.id
    }

    setOnDeviceSearchActive(true)

    var categoryMappings = {}

    if (onDeviceFilterActive) {
      categoryMappings = {
        'Department' : 'organisation',
        'Agency' : 'agency',
        'Grant' : 'grant',
        // 'Phase' : 'phase',
      }
    }

    else {
      categoryMappings = {
        'Department' : 'organisation__in',
        'Agency' : 'agency__in',
        'Grant' : 'grant__in',
        'My Activity' : categoryMappingValue,
      }
    } 

    var updatedValue = value
    let categoryValue = categoryMappings[category]

    logFirebaseEvent(`Filter ${category} Tapped`)

    // if (categoryValue === 'phase__in') {
    //   updatedValue = checkPhaseValue(value)
    // }

    let previousSearchParam = {
      'category' : categoryValue,
      'value' : updatedValue
    }

    if (categoryValue in searchParams) {
      let exisitingValue = searchParams[categoryValue]
      
      if (checked) {
        exisitingValue.push(updatedValue)
      }
      else {
        let index = exisitingValue.indexOf(updatedValue)
        exisitingValue.splice(index, 1)
      }

      if (exisitingValue.length === 0) {
        delete searchParams[categoryValue]
      }
      else {
        searchParams[categoryValue] = exisitingValue
      }
    }

    else {
      searchParams[categoryValue] = [updatedValue]
    }

    if (Object.keys(searchParams).length === 0) {
      revertToDefault()
      revertToDefaultTableData()
      
      return
    }
    else {

      if (onDeviceFilterActive) {
        fireOnDeviceFilterAPI(searchParams, eventParams)
      }

      else {
        searchParams['archived'] = true
        fireFilterAPI(searchParams, eventParams, previousSearchParam)
      }
    }
  }

  function fireOnDeviceFilterAPI(searchParams, eventParams=null) {
    setLoading(true)

    let topics = searchResults

    const filterOrganisationResults = []
    const filterGrantResults = []
    const filterAgencyResults = []

    for (let i = 0; i < topics.length; i++) {

      if ('organisation' in searchParams) {

        let organisationList = searchParams['organisation']
        organisationList.forEach(organisation => {

          if (topics[i].organisation.includes(organisation)) {

            if (!filterOrganisationResults.includes(topics[i])) {
              filterOrganisationResults.push(topics[i])
            }
          }
        })
      }

      if ('grant' in searchParams) {

        let grantList = searchParams['grant']
        grantList.forEach(grant => {

          if (topics[i].grant.includes(grant)) {

            if (!filterGrantResults.includes(topics[i])) {
              filterGrantResults.push(topics[i])
            }
          }
        })
      }

      if ('agency' in searchParams) {

        let agencyList = searchParams['agency']
        // eslint-disable-next-line no-loop-func
        agencyList.forEach(agency => {

          if (topics[i].agency.includes(agency)) {

            if (!filterAgencyResults.includes(topics[i])) {
              filterAgencyResults.push(topics[i])
            }
          }
        })
      }

    var finalData = []
    var finalResults = []
    
    if (filterOrganisationResults.length > 0) {
      finalData.push(filterOrganisationResults)
    }

    if (filterGrantResults.length > 0) {
      finalData.push(filterGrantResults)
    }

    if (filterAgencyResults.length > 0) {
      finalData.push(filterAgencyResults)
    }

    if (finalData.length === 0) {
      setLoading(false)

      alert('It looks like there are no topics for this grid selection.')
      return
    }
    else {
      finalResults = finalData.reduce((a, b) => a.filter(c => b.includes(c)));

      finalResults = finalResults.filter(function(item, pos) {
        return finalResults.indexOf(item) === pos;
      })
    }

    if (finalResults.length === 0) {
      setLoading(false)

      alert('It looks like there are no topics for this grid selection.')
      return
    }

    if (eventParams !== null) {
      let value = eventParams['value']
      let checked = eventParams['checked']

      if (checked) {
        let updatedFiltersSelected = [...filtersSelected, value]
        setFiltersSelected(updatedFiltersSelected)
      }
      else {
        let valueIndex = filtersSelected.indexOf(value)
        filtersSelected.splice(valueIndex, 1)
        setFiltersSelected(filtersSelected)
      }

      setClearFilterButtonVisibility('block')
      setCheckBoxesData({...checkBoxesData, [value] : checked})
    }

    setLoading(false)
    setTableData(finalResults)
  }
}

function updateSearchParams(previousSearchParam) {
  
  if (Object.keys(searchParams).length === 1) {
    revertToDefault()
    revertToDefaultTableData()
  }

  else {
    let category = previousSearchParam['category']
    let value = previousSearchParam['value']

    let searchParamsValue = searchParams[category]
    let searchParamValueIndex = searchParamsValue.indexOf(value)

    searchParamsValue.splice(searchParamValueIndex, 1)

    if (searchParamsValue.length === 0) {
      delete searchParams[category]
    }
    else {
      searchParams[category] = searchParamsValue
    }
    
    setSearchParams(searchParams)
  }
}

function fireFilterAPI(searchParams, eventParams=null, previousSearchParam={}) {
  setLoading(true)

  fetch(`${base_url}v1/topics/filter/?id=${currentUser.id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(searchParams)
  })
  .then(response => {
    setLoading(false)

    response.json()
    .then(responseJson => {

      if (!response.ok) {

        if (Object.keys(previousSearchParam).length > 0) {
          updateSearchParams(previousSearchParam)
        }
        
        alert(responseJson.error.message)
      } 
      else {
        let responseData = responseJson.data.data

        setTableData(responseData)
        setSearchResults(responseData)

        setSearchParams(searchParams)

        setPage(0)
        setLastPageIndex(-1)

        setTotalCount(responseData.length)

        if (eventParams !== null) {
          let value = eventParams['value']
          let checked = eventParams['checked']

          if (checked) {
            let updatedFiltersSelected = [...filtersSelected, value]
            setFiltersSelected(updatedFiltersSelected)
          }
          else {
            let valueIndex = filtersSelected.indexOf(value)
            filtersSelected.splice(valueIndex, 1)
            setFiltersSelected(filtersSelected)
          }

          setClearFilterButtonVisibility('block')
          setCheckBoxesData({...checkBoxesData, [value] : checked})
        }
        tableRef.current && tableRef.current.scrollIntoView()
      }
    })
  })
}

function updateFilterParams(filterValue) {

  for (var category in searchParams) {

    if (category === 'archived') {
        continue
    }
    
    var filterValueIndex = -1
    let categoryValue = searchParams[category]

    categoryValue.forEach((value, index) => {
      if (filterValue === value) {
        filterValueIndex = index
      }
    })

    if (filterValueIndex !== -1) {
      categoryValue.splice(filterValueIndex, 1)

      if (categoryValue.length === 0) {
        delete searchParams[category]
      }
    }
  }

  if (Object.keys(searchParams).length === 0) {
    fetchData()

    setSearchQuery('')
    setClearFilterButtonVisibility('none')

    return
  }
  else {

    if (onDeviceFilterActive) {
      let updatedSearchParams = updateCategoryMappings(searchParams)
      fireOnDeviceFilterAPI(updatedSearchParams)
    }

    else {
      fireFilterAPI(searchParams)
    }
  }
}

function updateCategoryMappings(searchParams) {
  let oldMappingValues = [
    'organisation__in',
    'agency__in',
    'grant__in',
    // 'phase__in'
  ]

  let mappings = {
    'organisation__in' : 'organisation',
    'agency__in' : 'agency',
    'grant__in' : 'grant',
    // 'phase__in' : 'phase',
  }

  for (const [key, value] of Object.entries(searchParams)) {
    if (oldMappingValues.includes(key)) {
      let newKey = mappings[key]

      searchParams[newKey] = value
      delete searchParams[key]
    }
  }
  return searchParams
}

const handleChipDelete = (title) => {
  let valueIndex = filtersSelected.indexOf(title)

  filtersSelected.splice(valueIndex, 1)
  setLoading(true)

  if (title === 'Likes' || title === 'Comments' || title === 'Tagged Comments') {
    updateFilterParams(currentUser.id)
  }
  else {
    updateFilterParams(title)
  }
  
  setTimeout(() => {
    setLoading(false)

    setFiltersSelected(filtersSelected)
    setCheckBoxesData({...checkBoxesData, [title] : false})

    if (filtersSelected.length === 0) {
      searchBarRef.current.value = ''
      setClearFilterButtonVisibility('none')
    }
  }, 100);
}

const ChipItem = (title) => {
  return (
    <Chip
      style={{
        'marginTop' : '10px',
      }}
      label={title}
      onDelete={() => handleChipDelete(title)}
      variant="outlined"
    />
  )
}

  function handleSearchChange() {
    searchQuery = searchBarRef.current.value

    if (searchQuery.length === 0) {
      revertToDefaultData()
    }
  }

  function revertToDefaultData() {
    setClearFilterButtonVisibility('none')

    setOnDeviceFilterActive(false)
    setOnDeviceSearchActive(false)

    setCheckBoxData({})
    setSearchParams({})
    setFiltersSelected([])

    fetchData()

    setSearchQuery('')
    setLastPageIndex(0)
  }

  function revertToDefault() {
    setSearchParams({})
    setCheckBoxData({})
    setFiltersSelected([])

    setLastPageIndex(0)
    setClearFilterButtonVisibility('none')
  }

  function revertToDefaultTableData() {
    setTimeout(() => {

      fetchData()
      setTotalCount(cacheTotalCount)
    }, 50);
  }

  function escapeCSV(value) {
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value}"`;
    }
    return value;
  }  

  function convertToCSV(data) {
    if (data.length === 0) return ''
  
    // Find the key which holds the array values
    const arrayKey = Object.keys(data[0]).find(key => Array.isArray(data[0][key]))
  
    // Create header row from object keys
    const headers = Object.keys(data[0]).join(",")
    let csvContent = headers + "\n"
  
    data.forEach(item => {
      if (arrayKey && item[arrayKey].length) {
        var isFirst = true

        item[arrayKey].forEach(arrayItem => {

          if (isFirst) {
            isFirst = false
            let row = Object.keys(item).map(key => {

              if (key === arrayKey) return escapeCSV(arrayItem)
              return escapeCSV(item[key])
              
            }).join(",")
  
            csvContent += row + "\n"
          }
          else {
            let row = Object.keys(item).map(key => {

              if (key === arrayKey) return escapeCSV(arrayItem)
              return ''
              
            }).join(",")
  
            csvContent += row + "\n"
          }
        })
      } 
      else {
        let row = Object.keys(item).map(key => {

          if (key === arrayKey) return ''
          return escapeCSV(item[key])

        }).join(",")

        csvContent += row + "\n"
      }
    });
  
    return csvContent;
  }
  
  function downloadCSV(csv, filename) {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)

      link.setAttribute('href', url)
      link.setAttribute('download', filename)

      link.style.visibility = 'hidden'
      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
    }
  }

  function fireFetchActiveTopicsDataAPI() {
    setLoading(true)

    fetch(`${base_url}v1/topics/data/?id=${currentUser.id}&archived=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {

      response.json()
      .then(responseJson => {
        setLoading(false)

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          let topicsData = responseJson.data.data

          const topicsCsvData = convertToCSV(topicsData)
          downloadCSV(topicsCsvData, 'topics_data.csv')
        }
      })
    })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchKeyword()
    }
  }

  const LoadingFade = () => {
    return (
      <Backdrop open={isLoading} style={{zIndex: '5'}}>
        <CircularProgress />
      </Backdrop>
    )
  }

  const SearchButton = () => {
    return (
        <IconButton onClick={searchKeyword}>
            <SearchOutlined />
        </IconButton>
    )
  }

  const DataListButton = () => {
    if (currentUser !== undefined) {
      if (currentUser.role !== 'GUEST') {
        return (
          <Grid item xs={1.5} style={{'marginTop' : '25px'}}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={fireFetchActiveTopicsDataAPI}
                >
                  Export Data
                </Button>
          </Grid>
        )
      }
      return null
    }
    return null
  }

  const SearchGrid = () => {
    return (
      <Grid
        container
        direction="row"
        xs={4}
      >
        <TextField
          style={{backgroundColor: 'white'}}
          fullWidth
          type={'search'}
          defaultValue={searchQuery}
          inputRef={searchBarRef}
          variant="outlined"
          margin="normal"
          id="search_bar"
          name="search_bar"
          placeholder="Search"
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          InputProps={{ 
            startAdornment: ( <InputAdornment position="start"> <SearchButton /> 
            </InputAdornment> ),
            // endAdornment: ( <InputAdornment position="end"> <SearchHelperTextButton /> 
            // </InputAdornment> ),
            disableUnderline: true }}
          />
      </Grid>
    )
  }

  const FilterGrid = () => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState([null, null, null])

    const handleMenuClick = (event, index) => {
      var updatedValues = [...menuAnchorEl]
      updatedValues[index] = event.currentTarget

      setMenuAnchorEl(updatedValues);
    };
  
    const handleMenuClose = () => {
      setMenuAnchorEl([null, null, null]);
    };

    return (
      <Grid 
        container xs={6}
        direction="row"
        style={{
          height: '100%',
          maxHeight: '200vh',
          overflow: 'auto',
        }}
      >
          <Grid 
            container
            direction="row"
            justifyContent="space-around"
            style={{
              marginTop: '7px',
              paddingTop: '7px',
              paddingBottom: '10px',
              border: '1px solid #B8B8B8',
              borderRadius: '4px',
              alignSelf: 'center',
              backgroundColor: 'white'
            }}
          >
            <Typography 
              style={{
                // marginTop : '10px'
              }} 
              variant="h5">
              Filters:
            </Typography>

            <Button
              style={{
                height: '30px',
                marginTop: '10px',
                display: `${clearFilterButtonVisibility}`
              }}
              type="submit"
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClearFiltersButtonClicked}
            >
                Clear All
            </Button>

            {filtersSelected.map(ChipItem)}

        {listItemValues.map((headerValue, index) => {
          return (
            <Grid item>
              <Button 
                aria-controls="simple-menu" 
                aria-haspopup="true"
                onMouseOver={event => handleMenuClick(event, index)}
              >
                {headerValue}
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl[index]}
                open={Boolean(menuAnchorEl[index])}
                MenuListProps={{ onMouseLeave: handleMenuClose }}
              >
                {listValueData[index].map((value) => {
                  return (
                    <ListItem
                      button
                      style={{'marginTop' : '-15px'}} 
                    >
                      <ListItemIcon>
                        <Checkbox
                          id={headerValue}
                          value={value}
                          checked={checkBoxesData[value]}
                          edge="start"
                          onChange={handleCheckboxChange}
                        />
                      </ListItemIcon>

                      <ListItemText style={{ 'marginLeft' : '-25px', 'fontSize' : 'small'}} primary={value} />
                    </ListItem>
                  )
                })}
              </Menu>
            </Grid>
          )
        })}
        </Grid>
    </Grid>
    )
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h1>Topics</h1>

        <LoadingFade />

        <div style={{position: 'absolute', top: 50, right: 0}}>
          <p>
          All information on this site is proprietary to Oceanit Laboratories, Inc. Material copied or downloaded from this site is only to be used by Oceanit employees and is not to be shared with those outside of Oceanit Laboratories, Inc. Some information on this site is restricted to those with certain access requirements.
          </p>
        </div>

        <Grid container spacing={1} style={{'marginTop' : '30px'}}>

          <Grid 
            container xs={12}
            direction="row"
            justifyContent="space-around"
            // style={{
            //   border: '1px solid #B8B8B8',
            //   borderRadius: '4px',
            //   backgroundColor: 'white'
            // }}
          >
            <SearchGrid />
            <FilterGrid />
            <DataListButton />

          </Grid>

          <Grid item xs={12}>
            <Card>  
              <CardBody>
                <TopicTable
                  tableHeaderColor="primary"
                  user={currentUser}
                  tableRef={tableRef}
                  headCells={headCellData}
                  tableData={tableData}
                  userMentionsData={userMentionsData}
                  totalCount={totalCount}
                  lastPageIndex={lastPageIndex}
                  fetchData={fetchData}
                  searchQuery={searchQuery}
                  page={page}
                  setPage={setPage}
                />
              </CardBody>
            </Card>
          </Grid>

        </Grid>
      </GridItem>
      
    </GridContainer>
  );
}
