import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const base_url = process.env.REACT_APP_BASE_URL;

export default function EmailNotVerified() {
  const history = useHistory()
  checkUser(history)

  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)

  const message = "Your account is pending verification with us. Please check your email and come back again."

  const checkVerificationEmail = () => {
    var accountDetails = getCookie('currentUser')

    if (accountDetails !== null) {
      sendVerificationEmail(JSON.parse(accountDetails)._id)
    } 
    else {
      alert('Something went wrong. Please try again later')
    }
  }

  function sendVerificationEmail(email) {
    setLoading(true)

    fetch(`${base_url}accounts/verification/?id=${email}`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
    })
    .then(response => {
        setTimeout(function() {
          setLoading(false)

          if (!response.ok) {
              alert('Something went wrong. Please try again later.')
          } else {
              response.json()
              .then(() => {
              alert('Verification link sent. Please check your email to verify your account.')
              window.location.reload(false)
              })
          }
        }, 200)
    })
  }

  function checkUser(history) {
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push('/signin')
      return
    } else {
      const accountJSON = JSON.parse(accountDetails)
      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }
      return accountJSON
    }
  }
  
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) ===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        style={{
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>

        <Typography component="h1" variant="h5">
          {message}
        </Typography>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={checkVerificationEmail}
        >
            Resend verification email
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            width: '237px'
          }}
          onClick={() => {history.push('signin')}}
        >
            Sign In
        </Button>
      </Box>
    </Container>
  );
}