import React, { useState, useEffect, useRef } from 'react'
import {
    Backdrop,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Button,
    Paper,
    Typography,
} from '@material-ui/core'

import {
    useHistory, 
    useLocation 
} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        backgroundColor: '#ffffff',
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: '#ffffff',
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    multilineTextField: {
        marginBottom: theme.spacing(2),
        minHeight: '100px',
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
    refreshButton: {
        marginLeft: theme.spacing(1),
    },
    highlightedText: {
        backgroundColor: 'yellow',
    },
}))

const base_url = process.env.REACT_APP_BASE_URL;

const DocumentTemplate = () => {
    var accountJSON = {}

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const currentUser = checkUser(history)

    const apiRan = useRef(false)
    const urlSearchparams = new URLSearchParams(location.search)

    const [isLoading, setIsLoading] = useState(false)

    const [formData, setFormData] = useState({
        numberOfAcronyms: '',
        maxWords: '',
        requiredWords: '',
        theme: '',
        keywords: '',
        topicTitle: '',
    })
    
    const [acronymPrompt, setAcronymPrompt] = useState('Generate [20] different options of [5-10] word acronyms that sound [Hawaiian] and use [Climate] [Education] [Community] as words. Use these as relevant keywords [Children] [Technology]')
    const [acronymResponse, setAcronymResponse] = useState('')

    const [topicData, setTopicData] = useState('')

    useEffect(() => {
		if (!apiRan.current) {
			apiRan.current = true
            let topicID = urlSearchparams.get('topic_id')
			
            if (topicID !== null) {
                fireCreateAIProposalAPI(topicID)
            }
            // else {
            //     setAcronymPrompt('Generate [20] different options of [5-10] word acronyms that sound [Hawaiian] and use [Climate] [Education] [Community] as words. Use these as relevant keywords [Children] [Technology]. Topic title is [TOPIC TITLE]')
            // }
		}
	}, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
                pathname: '/signin',
                state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            accountJSON = JSON.parse(accountDetails)

            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function fireCreateAIProposalAPI(topicID) {
        setIsLoading(true)

        fetch(`${base_url}v1/proposal/ai/?id=${currentUser.id}&topic_id=${topicID}&tonly=true`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then(response => {
            response.json()
            .then(responseJson => {
                setIsLoading(false)

                if (response.ok) {
                    let data = responseJson.data.topic_data
                    setTopicData(data)
                } 
                else {
                    let errorMessage = responseJson.error.message
                    alert(errorMessage)
                }
            })
        })
    }

    async function fireChatGPTAPI(params) {
        setIsLoading(true)

        const response = await fetch(`${base_url}v1/proposalai/ask/?id=${currentUser.id}&index=1`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })

        if (!response.ok) {
            setIsLoading(false)
            
            response.json().then((responseJson) => {

                const errorMesssage = responseJson['error']['message']
                alert(errorMesssage)
            })
            .catch(() => {
                alert('Something went wrong. Pleast try again. If the issue persists, let us know.')
            })
            return
        }
    
        const reader = response.body.getReader()
        const decoder = new TextDecoder()
    
        // Initialize an empty string to accumulate the chunks of text
        let accumulatedText = ''
    
        // Function to process text chunks as they arrive
        async function processText() {
            let { done, value } = await reader.read()

            if (done) {
                setIsLoading(false)
                return
            }
    
            // Decode and process the chunk
            const chunk = decoder.decode(value, { stream: true })
            
            accumulatedText += chunk
            setAcronymResponse(accumulatedText)
    
            // Continue reading the next chunk
            processText()
        }
    
        // Start processing the text stream
        processText()
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }))
    };

    function generateAcronymPrompt() {
        let acronymPrompt = `Generate ${formData.numberOfAcronyms} different options of [${formData.maxWords}] word acronyms that sound [${formData.theme}] and use [${formData.requiredWords}] as words. Use these as relevant keywords [${formData.keywords}]`

        if (formData.topicTitle !== '') {
            const topicTitlePrompt = `Topic Title: ${formData.topicTitle}`
            acronymPrompt += topicTitlePrompt
        }
        return acronymPrompt
    }

    const submitAcronymPrompt = async() => {
        let finalQuestion = ''
        let acronymPrompt = generateAcronymPrompt()

        if (topicData !== '') {
            const topicDescription = `Topic Title: ${topicData['title']} \n\n Topic Description: ${topicData['description']}`
            finalQuestion = topicDescription + '\n\n' + acronymPrompt
        }
        else {
            finalQuestion += acronymPrompt
        }
        
        let params = {
            'dialog_history' : [{content: finalQuestion, role: 'user'}]
        }

        await fireChatGPTAPI(params)
    }

    const CommaSeparatedList = ({ items }) => {
        if (items === undefined) {
            return null
        }

        return (
            <Typography variant="body1" gutterBottom>
                {`Suggested Keywords: ${items.join(', ')}`}
            </Typography>
        )
    }
      
    return (
        <Container maxWidth="lg" className={classes.container}>

            <Backdrop
                style={{
                    color: '#fff',
                    zIndex: 10,
                }}
                open={isLoading}
            >
                <CircularProgress />
            </Backdrop>

            {
                topicData !== '' && (
                    <Typography variant="h4" gutterBottom>
                        {`${topicData['topic_number']}: ${topicData['title']}`}
                    </Typography>
                )
            }

            <Typography variant="h5" gutterBottom>
                Acronym Generator
            </Typography>

            <Paper className={classes.paper}>
                <Grid container xs={12} ms={12}>
                    <Grid item xs={12} md={12}>
                        <CommaSeparatedList items={topicData['generated_keywords']} />
                    </Grid>

                    <Grid container xs={10} md={10} alignItems="center" spacing={2}>

                        <Grid item xs={4} md={3}>
                            <Typography variant="body1" gutterBottom>
                                Number of Acronyms
                            </Typography>
                        </Grid>

                        <Grid item xs={6} md={7}>
                            <TextField
                                name={'numberOfAcronyms'}
                                value={formData.numberOfAcronyms}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={10} md={10} alignItems="center" spacing={2}>

                        <Grid item xs={4} md={3}>
                            <Typography variant="body1" gutterBottom>
                                Max Words
                            </Typography>
                        </Grid>

                        <Grid item xs={6} md={7}>
                            <TextField
                                name={'maxWords'}
                                value={formData.maxWords}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={10} md={10} alignItems="center" spacing={2}>

                        <Grid item xs={4} md={3}>
                            <Typography variant="body1" gutterBottom>
                                Required Words
                            </Typography>
                        </Grid>

                        <Grid item xs={6} md={7}>
                            <TextField
                                name={'requiredWords'}
                                value={formData.requiredWords}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={10} md={10} alignItems="center" spacing={2}>

                        <Grid item xs={4} md={3}>
                            <Typography variant="body1" gutterBottom>
                                Theme (Hawaii, Greek, Roman)
                            </Typography>
                        </Grid>

                        <Grid item xs={6} md={7}>
                            <TextField
                                name={'theme'}
                                value={formData.theme}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={10} md={10} alignItems="center" spacing={2}>

                        <Grid item xs={4} md={3}>
                            <Typography variant="body1" gutterBottom>
                                Keywords
                            </Typography>
                        </Grid>

                        <Grid item xs={6} md={7}>
                            <TextField
                                name={'keywords'}
                                value={formData.keywords}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={10} md={10} alignItems="center" spacing={2}>

                        <Grid item xs={4} md={3}>
                            <Typography variant="body1" gutterBottom>
                                Topic Title (Optional)
                            </Typography>
                        </Grid>

                        <Grid item xs={6} md={7}>
                            <TextField
                                name={'topicTitle'}
                                value={formData.topicTitle}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={10} md={10}>
                        <TextField
                            placeholder='Generate [20] different options of [5-10] word acronyms that sound [Hawaiian] and use [Climate] [Education] [Community] as words. Use these as relevant keywords [Children] [Technology]'
                            multiline
                            maxRows={3}
                            value={acronymPrompt}
                            onChange={handleAcronymPromptChange}
                            fullWidth
                            variant="outlined"
                            className={classes.textField}
                        />
                    </Grid> */}

                    <Grid item xs={2} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            style={{'marginTop': '5px'}}
                            onClick={submitAcronymPrompt}
                        >
                            Ask GPT
                        </Button>
                    </Grid>
                </Grid>

                <TextField
                    label=""
                    value={acronymResponse}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={10}
                    className={classes.multilineTextField}
                />
            </Paper>
        </Container>
    )
}

export default DocumentTemplate
