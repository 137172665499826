import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { CircularProgress, Backdrop } from "@material-ui/core";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles(styles);

function Row(props) {
    const {prop, key, classes} = props

    return (
    <React.Fragment>
        <TableRow key={key} className={classes.tableBodyRow}>
            <TableCell className={classes.tableCell}>
                {prop.name}
            </TableCell>

            <TableCell className={classes.tableCell}>
                {prop.title}
            </TableCell>

            <TableCell className={classes.tableCell}>
                {prop.files}
            </TableCell>

            <TableCell className={classes.tableCell}>
                {prop.dates}
            </TableCell>
        </TableRow>
    </React.Fragment>
    );
}

export default function ProposalDownloadTable(props) {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false)
  const { tableHeaderColor, tableHead, tableData} = props;

  return (
    <div className={classes.tableResponsive}>
      <Backdrop
        style={{'zIndex' : '3500'}}
        className={classes.backdrop}
        open={isLoading}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <Row prop={prop} key={key} classes={classes} />
            )
          })}
        </TableBody>
      </Table>
    </div>
  );
}


ProposalDownloadTable.defaultProps = {
  tableHeaderColor: "gray",
};

ProposalDownloadTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
