import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../assets/extras/msalAuthConfig";
import { EventType} from "@azure/msal-browser";

const base_url = process.env.REACT_APP_BASE_URL;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.oceanit.com/">
        Oceanit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#D37506',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignIn() {
  const classes = useStyles()
  const location = useLocation()

  const history = useHistory()
  const { instance } = useMsal();

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    // This will be run on component mount

    const callbackId = instance.addEventCallback(message => {
      switch (message.eventType) {

          case EventType.LOGIN_SUCCESS:
            parseAccountDetails(message.payload.account)
            break
  
          case EventType.ACQUIRE_TOKEN_SUCCESS:
            parseAccountDetails(message.payload.account)
            break
          
          default:
            break  
      }
  });

    return () => {
        // This will be run on component unmount
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
    }
    
}, []);

  const handleSignIn = () => {
    setLoading(true)

    // let payload = {
    //   'name' : 'Piyush Nagpal-Contractor',
    //   'username' : 'pnagpal-c@oceanit.com'
    // }

    // hardcodedUserDetails(payload)

    instance.loginPopup(loginRequest)
    .catch(error => {
      setLoading(false)
      alert(error.message)
    })
  }

  // Only for localhost. Temp fix. Ask Rod to check into msal config.
  // function hardcodedUserDetails(payload) {
  //   const [first, ...rest] = payload.name.split(' ')
  //   let accountDetails = {
  //     'first_name' : first,
  //     'last_name' : rest.join(' '),

  //     'email' : payload.username,
  //     'password' : 'oceanit' // Dummy value after MSAL integration
  //   }

  //   fireAuthenticateAccountAPI(accountDetails)
  // }

  function parseAccountDetails(payload) {
    const [first, ...rest] = payload.name.split(' ')
    let accountDetails = {
      'first_name' : first,
      'last_name' : rest.join(' '),

      'email' : payload.username,
      'password' : 'oceanit' // Dummy value after MSAL integration
    }

    fireAuthenticateAccountAPI(accountDetails)
  }

  function fireAuthenticateAccountAPI(accountDetails) {
    fetch(`${base_url}v1/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(accountDetails),
    })
    .then(response => {
      setTimeout(function(){
        setLoading(false)

        response.json()
        .then(responseJson => {

          if (!response.ok) {
            alert(responseJson.error.message)
          } else {
            setCookie('currentUser', JSON.stringify(responseJson.data.data), 30)

            var urlPath = '/'
            if (location.state !== undefined) {
              urlPath = location.state.urlPath
            }
            history.push(urlPath)
          }
        })
    }, 200)
    })

  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <br></br>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>

        <br></br>
        <br></br>

        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSignIn}
          >
            Sign In
        </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}