import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "assets/extras/constants";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const logFirebaseEvent = (eventName) => {
    let date = new Date()
    let userProfile = checkUser()

    let eventParams = {
        'User' : userProfile.first_name + " " + userProfile.last_name,
        'Date' : date.toLocaleDateString()
    }

    logEvent(analytics, eventName, eventParams)
}

function checkUser() {
    var profile = {}
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      return {}
    } 
    else {
      const accountJSON = JSON.parse(accountDetails)

      if (accountJSON.is_verified === 0) {
        return {}
      }

      profile = accountJSON
      profile.password = ''

      return profile
    }
  }
  function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)===' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }

export default logFirebaseEvent
