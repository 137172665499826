/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SearchIcon from '@material-ui/icons/Search'
import MyProposalsIcon from '@material-ui/icons/MenuOutlined'
import UploadResourceIcon from '@material-ui/icons/CloudUpload'
import GraphIcon from '@material-ui/icons/ShowChart'
import GradeIcon from '@material-ui/icons/Grade';

import Proposal from "views/Proposal/Proposal.js"
import HistoricProposal from "views/Proposal/HistoricProposal"

import UserProposals from 'views/UserProposals/UserProposals.js'
import UserRelatedWorks from 'views/UserProposals/UserRelatedWorks.js'
import UserBiosketches from 'views/UserProposals/UserBiosketches.js'

import Biosketch from "views/Biosketch/Biosketch.js"
import RelatedWork from "views/RelatedWork/RelatedWork.js"
import UserProfile from "views/UserProfile/UserProfile.js"

import TopicsTableList from "views/TableList/TopicsTableList.js"
import TopicsArchivedTableList from "views/TableList/TopicsArchivedTableList.js"

import ProposalTableList from "views/TableList/ProposalTableList.js"
import BiosketchTableList from "views/TableList/BiosketchTableList.js"
import RelatedWorkTableList from "views/TableList/RelatedWorkTableList"
import ProposalDownloadTableList from 'views/TableList/ProposalDownloadsTableList'

import BrainAI from 'views/BrainAI/BrainAI'
import ProposalAI from 'views/Proposal/ProposalAI'
import ProposalAIAcronym from 'views/Proposal/ProposalAIAcronymGenerator'
import ProposalAIAbstract from 'views/Proposal/ProposalAIAbstractGenerator'
import BrainRAGAI from 'views/BrainAI/BrainRAGAI'
// import RelatedWorkTableList from "views/TopicsTableList/RelatedWorkTableList.js"

const dashboardRoutes = [
  {
    path: "/user/proposals",
    name: "My Proposals",
    icon: MyProposalsIcon,
    component: UserProposals,
    layout: "/tool",
  },
  {
    path: "/user/related_works",
    name: "My Related Works",
    icon: MyProposalsIcon,
    component: UserRelatedWorks,
    layout: "/tool",
  },
  {
    path: "/user/biosketches",
    name: "My Biosketches",
    icon: MyProposalsIcon,
    component: UserBiosketches,
    layout: "/tool",
  },
  {
    path: "/proposal",
    name: "Upload Proposal",
    icon: UploadResourceIcon,
    component: HistoricProposal,
    layout: "/tool",
  },
  {
    path: "/biosketch",
    name: "Upload Biosketch",
    icon: UploadResourceIcon,
    component: Biosketch,
    layout: "/tool",
  },
  {
    path: "/related_work",
    name: "Upload Related Work",
    icon: UploadResourceIcon,
    component: RelatedWork,
    layout: "/tool",
  },
  {
    path: "/browse/topics",
    name: "Discover New Topics",
    icon: SearchIcon,
    component: TopicsTableList,
    layout: "/tool",
  },
  {
    path: "/browse/ai/proposal",
    name: "Proposal AI",
    icon: SearchIcon,
    component: ProposalAI,
    layout: "/tool",
  },
  {
    path: "/browse/ai/acronym",
    name: "Proposal AI Acronym",
    icon: SearchIcon,
    component: ProposalAIAcronym,
    layout: "/tool",
  },
  {
    path: "/browse/ai/abstract",
    name: "Proposal AI Abstract",
    icon: SearchIcon,
    component: ProposalAIAbstract,
    layout: "/tool",
  },
  {
    path: "/browse/archived/topics",
    name: "Archived Topics",
    icon: SearchIcon,
    component: TopicsArchivedTableList,
    layout: "/tool",
  },
  {
    path: "/browse/proposal",
    name: "Browse Proposals",
    icon: SearchIcon,
    component: ProposalTableList,
    layout: "/tool",
  },
  {
    path: "/browse/biosketch",
    name: "Browse Biosketches",
    icon: SearchIcon,
    component: BiosketchTableList,
    layout: "/tool",
  },
  {
    path: "/browse/related_work",
    name: "Browse Related Works",
    icon: SearchIcon,
    component: RelatedWorkTableList,
    layout: "/tool",
  },
  {
    path: "/browse/analytics",
    name: "Proposal Downloads",
    icon: GraphIcon,
    component: ProposalDownloadTableList,
    layout: "/tool",
  },
  // {
  //   path: "/brainai",
  //   name: "BrainGPT",
  //   icon: GradeIcon,
  //   component: BrainAI,
  //   layout: "/tool",
  // },
  {
    path: "/gpt/ask",
    name: "Ask BrainGPT",
    icon: UploadResourceIcon,
    component: BrainAI,
    layout: "/tool",
  },
  {
    path: "/gpt/rag",
    name: "Brain Knowledge Base",
    icon: UploadResourceIcon,
    component: BrainRAGAI,
    layout: "/tool",
  },
  {
    path: "/gpt/abstract",
    name: "Abstract Generator",
    icon: UploadResourceIcon,
    component: ProposalAIAbstract,
    layout: "/tool",
  },
  {
    path: "/gpt/acronyms",
    name: "Acronyms Generator",
    icon: UploadResourceIcon,
    component: ProposalAIAcronym,
    layout: "/tool",
  },
  {
    path: "/user",
    name: "Profile",
    icon: AccountCircleIcon,
    component: UserProfile,
    layout: "/tool",
  },
]

export default dashboardRoutes;

export const parentRoutes = [
  {
    path: "/user/proposals",
    name: "My Resources",
    icon: MyProposalsIcon,
    component: UserProposals,
    layout: "/tool",
  },
  {
    path: "/start",
    name: "Upload",
    icon: UploadResourceIcon,
    component: Proposal,
    layout: "/tool",
  },
  {
    path: "/browse/topics",
    name: "Browse",
    icon: SearchIcon,
    component: TopicsTableList,
    layout: "/tool",
  },
  {
    path: "/gpt/ask",
    name: "BrainGPT",
    icon: GradeIcon,
    component: BrainAI,
    layout: "/tool",
  },
]

const userRoutes = [
  {
    path: "/user/proposals",
    name: "Proposals",
    icon: MyProposalsIcon,
    component: UserProposals,
    layout: "/tool",
  },
  {
    path: "/user/related_works",
    name: "Related Works",
    icon: MyProposalsIcon,
    component: UserRelatedWorks,
    layout: "/tool",
  },
  {
    path: "/user/biosketches",
    name: "Biosketches",
    icon: MyProposalsIcon,
    component: UserBiosketches,
    layout: "/tool",
  },
]

const uploadRoutes = [
  {
    path: "/proposal",
    name: "Proposal",
    icon: UploadResourceIcon,
    component: HistoricProposal,
    layout: "/tool",
  },
  {
    path: "/biosketch",
    name: "Biosketch",
    icon: UploadResourceIcon,
    component: Biosketch,
    layout: "/tool",
  },
  {
    path: "/related_work",
    name: "Related Work",
    icon: UploadResourceIcon,
    component: RelatedWork,
    layout: "/tool",
  },
]

const browseRoutes = [
  {
    path: "/browse/topics",
    name: "New Topics",
    icon: SearchIcon,
    component: TopicsTableList,
    layout: "/tool",
  },
  {
    path: "/browse/archived/topics",
    name: "Archived Topics",
    icon: SearchIcon,
    component: TopicsArchivedTableList,
    layout: "/tool",
  },
  // {
  //   path: "/browse/proposal/AI",
  //   name: "Proposal AI",
  //   icon: SearchIcon,
  //   component: ProposalAI,
  //   layout: "/tool",
  // },
  {
    path: "/browse/proposal",
    name: "Proposals",
    icon: SearchIcon,
    component: ProposalTableList,
    layout: "/tool",
  },
  {
    path: "/browse/biosketch",
    name: "Biosketches",
    icon: SearchIcon,
    component: BiosketchTableList,
    layout: "/tool",
  },
  {
    path: "/browse/related_work",
    name: "Related Works",
    icon: SearchIcon,
    component: RelatedWorkTableList,
    layout: "/tool",
  },
  {
    path: "/browse/analytics",
    name: "Proposal Downloads",
    icon: GraphIcon,
    component: ProposalDownloadTableList,
    layout: "/tool",
  },
]

const brainGPTRoutes = [
  {
    path: "/gpt/ask",
    name: "Ask BrainGPT",
    icon: UploadResourceIcon,
    component: BrainAI,
    layout: "/tool",
  },
  {
    path: "/gpt/rag",
    name: "Brain Knowledge Base",
    icon: UploadResourceIcon,
    component: BrainRAGAI,
    layout: "/tool",
  },
  {
    path: "/gpt/abstract",
    name: "Generate Abstract",
    icon: UploadResourceIcon,
    component: ProposalAIAbstract,
    layout: "/tool",
  },
  {
    path: "/gpt/acronyms",
    name: "Generate Acronyms",
    icon: UploadResourceIcon,
    component: ProposalAIAcronym,
    layout: "/tool",
  },
]

const extraRoutes = [
  {
    path: "/user",
    name: "Profile",
    icon: AccountCircleIcon,
    component: UserProfile,
    layout: "/tool",
  },
]

export const childRoutes = [
  userRoutes,
  uploadRoutes,
  browseRoutes,
  brainGPTRoutes,
  extraRoutes
]
