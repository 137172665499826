import React from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles"
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

import { 
  FormControl, 
  FormControlLabel, 
  IconButton, 
  ListItem, 
  ListItemText,
  Paper,
  RadioGroup } from "@material-ui/core"

import CheckedBox from "@material-ui/icons/CheckBoxOutlined";
import UnCheckedBox from "@material-ui/icons/CheckBoxOutlineBlankSharp";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";

const useStyles = makeStyles({
    root: {
        width: '50%',
        flex: '1',
        margin: '0 auto',
        justifyContent: 'center',
    },
    timeline: {
        minWidth: '220px',
        alignSelf: 'center',
        marginTop: '-100px',
        marginLeft: '-35px',
        transform: "rotate(90deg)"
    },
    timelineContentContainer: {
        textAlign: "left"
    },
    timelineContent: {
        display: "inline-block",
        transform: "rotate(-90deg)",
        textAlign: "center",
        minWidth: 50
    },
    timelineIcon: {
        transform: "rotate(-90deg)"
    },
    statusText:{
        fontSize: '14px',
        marginTop: '-7px'
    },
    titleText: {
        // marginTop: '6px',
        fontWeight: '900'
    },
    organisationText: {
        marginTop: '-4px'
    },
    listItem: {
        display : 'flex', 
        flexDirection : 'column', 
        paddingTop : '20px', 
        paddingLeft : '30px', 
        paddingRight : '30px'
    },
    checklistDiv: {
        width: '100%',
        display : 'flex',
        flexDirection : 'row',
        paddingRight: '10px',
        paddingLeft: '10px',
        paddingBottom: '20px',
    },
    checklistLeft: {
        alignSelf: 'flex-start'
    }
})

const base_url = process.env.REACT_APP_BASE_URL;

export default function UserRelatedWorks() {
    const history = useHistory()
    const classes = useStyles()
    const currentUser = checkUser(history)

    const [loading, setLoading] = React.useState(false)

    const [userRelatedWorks, setUserRelatedWorks] = React.useState([])
    const [allUserRelatedWorks, setAllUserRelatedWorks] = React.useState([])

    React.useEffect(() => {
        if (currentUser !== undefined) {
            fireFetchDataAPI()
        }
    }, [])

    function checkUser(history) {
        var accountDetails = getCookie('currentUser')

        if (accountDetails == null) {
            history.push({
            pathname: '/signin',
            state: {urlPath: '/tool/browse/proposal'},
            })
            return

        } else {
            const accountJSON = JSON.parse(accountDetails)
            if (accountJSON.is_verified === 0) {
                history.push('notVerified')
                return
            }
            return accountJSON
        }
    }

    function fireFetchDataAPI() {
        logFirebaseEvent('My Resources -> Related Works -> Arrived')
        setLoading(true)

        fetch(`${base_url}v1/related_work/member/?id=${currentUser.id}`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then(response => {
            setLoading(false)

            response.json()
            .then(responseJson => {

                if (!response.ok) {
                    alert(responseJson.error.message)
                } else {
                    setUserRelatedWorks(responseJson.data.data)
                    setAllUserRelatedWorks(responseJson.data.data)
                }
            })
        })
    }

    function chunkArray(arr,n){
        var chunkLength = Math.max(arr.length/n ,1);
        var chunks = [];
        for (var i = 0; i < n; i++) {
            if(chunkLength*(i+1)<=arr.length)chunks.push(arr.slice(chunkLength*i, chunkLength*(i+1)));
        }
        return chunks; 
    }

    function ChecklistComponent(props) {
        const {data} = props
        let dataSet = chunkArray(data, 2)

        return (
            <div className={classes.checklistDiv}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="title" name="existing_proposals">

                        {dataSet[0].map((value, index) => {
                            if (value[1]) {
                                return (
                                    <FormControlLabel value={String(index)} control={<CheckedBox style={{'marginRight' : '10px'}} />} label={value} />
                                )
                            }
                            return (
                                <FormControlLabel value={String(index)} control={<UnCheckedBox style={{'marginRight' : '10px'}} />} label={value} />
                            )
                        })}

                    </RadioGroup>
                </FormControl>

                <FormControl style={{'position' : 'absolute', 'right' : '30px'}} component="fieldset">
                    <RadioGroup aria-label="title" name="existing_proposals">

                        {dataSet[1].map((value, index) => {
                            if (value[1]) {
                                return (
                                    <FormControlLabel value={String(index)} control={<CheckedBox style={{'marginRight' : '10px'}} />} label={value} />
                                )
                            }
                            return (
                                <FormControlLabel value={String(index)} control={<UnCheckedBox style={{'marginRight' : '10px'}} />} label={value} />
                            )
                        })}

                    </RadioGroup>
                </FormControl>
            </div>
        )
    }

    function Feed(props) {
        const {relatedWork} = props

        function fireFetchRelatedWorkAPI(rid, pathname) {
            fetch(`${base_url}v1/related_work/?id=${currentUser.id}&rid=${rid}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            .then(response => {
                response.json()
                .then(responseJson => {

                    setLoading(false)

                    if (!response.ok) {
                        alert(responseJson.error.message)
                    } 
                    else {
                        let relatedWork = responseJson.data.data

                        history.push({
                            pathname: pathname,
                            state: {
                            relatedWork: relatedWork
                            },
                        })
                    }
                })
            })
        }

        function fireDeleteRelatedWorkAPI(rid) {
            fetch(`${base_url}v1/related_work/?id=${currentUser.id}&rid=${rid}`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            .then(response => {
                response.json()
                .then(responseJson => {

                    setLoading(false)

                    if (!response.ok) {
                        alert(responseJson.error.message)
                    } 
                    else {
                        alert(responseJson.data.message)
                        fireFetchDataAPI()
                    }
                })
            })
        }

        function checkDeleteRelatedWork(relatedWork) {
            let answer = window.confirm('Are you sure you want to delete this related work?')

            if (answer) {
                fireDeleteRelatedWorkAPI(relatedWork.id)
            }
        }

        function updateRelatedWorkClicked(relatedWork, pathname='/tool/related_work/') {
            setLoading(true)
            fireFetchRelatedWorkAPI(relatedWork.id, pathname)
        }

        return (
            <React.Fragment>
            <Paper style={{'marginTop' : '20px'}}>
            <ListItem className={classes.listItem} alignItems="flex-start">
                <ListItemText
                    primary={`Title: ${relatedWork.title}`}
                />
                <ListItemText
                    classes={{primary: classes.statusText}}
                    primary={`Field : ${relatedWork.field.toString()}`}
                />

                <ListItemText
                    classes={{primary: classes.statusText}}
                    style={{'marginTop' : 20}}
                    primary={`Department | Grant: ${relatedWork.organisation} | ${relatedWork.grant}`}
                />
                <ListItemText
                    classes={{primary: classes.statusText}}
                    primary={`Agency: ${relatedWork.agency}`}
                />

                <ListItemText
                    classes={{primary: classes.statusText}}
                    style={{'marginTop' : 20}}
                    primary={`PI: ${relatedWork.principal_investigator}`}
                />
                <ListItemText
                    classes={{primary: classes.statusText}}
                    primary={`TPOC Name: ${relatedWork.tpoc_name}`}
                />
                <IconButton
                    style={{'position' : 'absolute', 'right': 40, 'top':  5}}
                    aria-label="share"
                    size="small"
                    onClick={() => updateRelatedWorkClicked(relatedWork)}
                >
                    <EditIcon/>
                </IconButton>

                <IconButton
                    style={{'position' : 'absolute', 'right': 5, 'top':  5}}
                    aria-label="share"
                    size="small"
                    onClick={() => checkDeleteRelatedWork(relatedWork)}
                >
                    <DeleteIcon/>
                </IconButton>

                {/* <ChecklistComponent data={proposal.files} /> */}

            </ListItem>
            </Paper>
            </React.Fragment>
        )
    }

    function checkKeywordEmpty(keyword) {
        if (keyword.length === 0) {
          setUserRelatedWorks(allUserRelatedWorks)
        }
    }

    function searchKeyword(keyword) {
        if (keyword.length === 0 || !keyword.replace(/\s/g, '').length) {
            alert('Please enter a valid search.')
            return
        }
        
        const proposals = allUserRelatedWorks
        const searchKey = keyword.toLowerCase()

        setUserRelatedWorks([])

        const filterTitleResults = []
        const filterProposalIDResults = []
        const filterInternalIDResults = []
        const filterAuthorResults = []
        const filterAgencyResults = []

        for (let i = 0; i < proposals.length; i++) {
            let proposal = proposals[i]
            
            // Putting this at top for Micah, might push this to bottom later
            let agency = proposal.organisation.toLowerCase()

            if (agency.includes(searchKey)) {
                if (!filterAgencyResults.includes(proposal)) {
                    filterAgencyResults.push(proposal)
                }
            }

            let topicTitle = proposal.title.toLowerCase()

            if (topicTitle.includes(searchKey)) {
                if (!filterTitleResults.includes(proposal)) {
                    filterTitleResults.push(proposal)
                }
            }

            let proposalID = proposal.solicitation_id.toLowerCase()

            if (proposalID.includes(searchKey)) {
                if (!filterProposalIDResults.includes(proposal)) {
                    filterProposalIDResults.push(proposal)
                }
            }

            let internalID = proposal.internal_id.toLowerCase()

            if (internalID.includes(searchKey)) {
                if (!filterInternalIDResults.includes(proposal)) {
                    filterInternalIDResults.push(proposal)
                }
            }

            let authors = proposal.author.toLowerCase()

            if (authors.includes(searchKey)) {
                if(!filterAuthorResults.includes(proposal)) {
                    filterAuthorResults.push(proposal)
                }
            }
        }

        var finalResults = filterAgencyResults
                           .concat(filterTitleResults)
                           .concat(filterProposalIDResults)
                           .concat(filterInternalIDResults)
                           .concat(filterAuthorResults)

        finalResults = finalResults.filter(function(item, pos) {
            return finalResults.indexOf(item) === pos;
        })
        
        if (finalResults.length === 0) {
            finalResults = proposals
            alert('No results found for this search.')
        }
        setUserRelatedWorks(finalResults)
    }

    function MainFeedComponent() {
        return (
            <React.Fragment>
            {/* <h2>My Proposals</h2> */}

            {userRelatedWorks.map((relatedWork, index) => {
                return (
                <Feed
                    relatedWork={relatedWork} 
                />
                )
            })}

            </React.Fragment>
        )
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) ===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    return (
        <div className={classes.root}>
            <Fade
            in={loading}
            style={{
                transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
            >
                <CircularProgress />
            </Fade>

            <h2>My Related Works</h2>

            {/* <SearchBar
                style={{
                    maxWidth: 1000
                }}
                placeholder="Search"
                onRequestSearch={(keyword) => searchKeyword(keyword)}
                onCancelSearch={() => setUserProposals(allUserProposals)}
                onChange={(keyword) => checkKeywordEmpty(keyword) }
            /> */}

            <MainFeedComponent />
        </div>
    );
}
