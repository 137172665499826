import React, { useState, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom'
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import RelatedWorkTable from "components/Table/RelatedWorkTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';

import CircularProgress from '@material-ui/core/CircularProgress'

import { Autocomplete } from "@material-ui/lab";

import IconButton from '@material-ui/core/IconButton';
import { SearchOutlined } from "@material-ui/icons";
import { authors } from "assets/extras/constants";

import logFirebaseEvent from "assets/extras/FirebaseAnalytics";
import { Backdrop } from "@material-ui/core";

const base_url = process.env.REACT_APP_BASE_URL;

export default function RelatedWorkTableList() {
  const history = useHistory()
  const location = useLocation()
  const currentUser = checkUser(history)

  const searchBarRef = useRef(null)
  const urlSearchparams = new URLSearchParams(location.search)

  const [loading, setLoading] = useState(false)
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')
  const [tableData, setTableData] = useState([])
  const [allTopics, setAllTopics] = useState([])

  var [searchKeys, setSearchKeys] = useState()

  React.useEffect(() => {
    if (currentUser !== undefined) {
      runInitalSetup()
    }
  }, [])

  function runInitalSetup() {
    if (currentUser !== undefined) {
      let searchType = urlSearchparams.get('type')

      if (searchType !== null) {
        checkFor(searchType)
      }
      else {
        fetchData()
      }
    }
    else {
      alert('It looks like you are not signed in. Please signin to continue.')
    }
  }

  function checkFor(searchType) {
    if (searchType === 'search') {
      let searchQuery = urlSearchparams.get('q')
      
      setSearchQuery(searchQuery)
      searchKeyword(searchQuery)
    }
    else {

    }
  }

  function checkUser(history) {
    var accountDetails = getCookie('currentUser')
  
    if (accountDetails == null) {
      history.push({
        pathname: '/signin',
        state: {urlPath: '/tool/browse/proposal'},
      })
      return

    } else {
      const accountJSON = JSON.parse(accountDetails)
      if (accountJSON.is_verified === 0) {
        history.push('notVerified')
        return
      }
      return accountJSON
    }
  }

  function fetchData() {
    logFirebaseEvent('Browse -> Related Work -> Arrived')
    
    setLoading(true)
    fetch(`${base_url}v1/related_works/?id=${currentUser.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          alert(responseJson.error.message)
        } 
        else {
          setTableData(responseJson.data.data)
          setAllTopics(responseJson.data.data)
        }
      })
    })
  }

  function revertToDefaultData() {
    setTableData(allTopics)
    setSearchQuery('')
    setAutoCompleteOpen(false)
  }

  function checkKeywordEmpty() {
    let search = searchBarRef.current.value

    if (search.length === 0) {
      revertToDefaultData()
    }
    else {
      if (!autoCompleteOpen) {
        setAutoCompleteOpen(true)
      }
    }
  }

  const handleSearchKeysChange = (event, value) => {
    if (value !== null) {

      if (value.title !== undefined) {
        searchKeys = value.title
        searchKeyword(searchKeys)
      }
      else {
        searchBarRef.current.value = value
        searchKeyword()
      }
    }
    else {
      revertToDefaultData()
    }
  }

  const SearchButton = () => {
    return (
        <IconButton onClick={searchKeyword}>
            <SearchOutlined />
        </IconButton>
    )
  }

  const LoadingFade = () => {
    return (
      <Backdrop open={loading} style={{zIndex: '5'}}>
        <CircularProgress />
      </Backdrop>
    )
  }

  function searchKeyword(searchKeys=null) {
    var search = searchKeys

    if (searchKeys === null) {
      search = searchBarRef.current.value
    }

    if (search.length === 0 || !search.replace(/\s/g, '').length) {
      alert('Please enter a valid search.')
      return
    }

    setSearchQuery(search)

    let keywords = search.split(',')
    let searchParams = {
      'keywords' : keywords
    }

    setAutoCompleteOpen(false)
    fireSearchAPI(searchParams)
  }

  function fireSearchAPI(searchParams) {
    setLoading(true)
    fetch(`${base_url}v1/related_works/search/?id=${currentUser.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchParams)
    })
    .then(response => {
      setLoading(false)

      response.json()
      .then(responseJson => {

        if (!response.ok) {
          setSearchQuery('')
          alert(responseJson.error.message)
        } 
        else {
          setTableData(responseJson.data.data)
        }
      })
    })
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        
        <h1 >Related Works</h1>
        <LoadingFade />

        <div style={{position: 'absolute', top: 50, right: 0}}>
          <p>
          All information on this site is proprietary to Oceanit Laboratories, Inc. Material copied or downloaded from this site is only to be used by Oceanit employees and is not to be shared with those outside of Oceanit Laboratories, Inc. Some information on this site is restricted to those with certain access requirements.
          </p>
        </div>

        <Autocomplete
          open={autoCompleteOpen}
          freeSolo={true}
          defaultValue={searchKeys}
          options={authors}
          value={searchQuery}
          getOptionLabel={(option) => option.title || searchQuery}
          onChange={handleSearchKeysChange}
          onAbort={revertToDefaultData}
          disableClearable
          renderInput={(params) => (
              <TextField
                {...params}
                type={'search'}
                inputRef={searchBarRef}
                fullWidth
                variant="outlined"
                margin="normal"
                id="search_bar"
                name="search_bar"
                placeholder="Search"
                onChange={checkKeywordEmpty}
                InputProps={{ ...params.InputProps, 
                  startAdornment: ( <InputAdornment position="start"> <SearchButton /> 
                  </InputAdornment> ),
                  disableUnderline: true }}
            />
          )}
        />

        <Card>
          <CardBody>
            <RelatedWorkTable
              tableHeaderColor="primary"
              tableHead={['Title', 'Department', 'Field', 'TPOC Name', 'Keywords', '']}
              tableData={tableData}
              searchQuery={searchQuery}
              userID={currentUser.id}
            />
          </CardBody>
        </Card>
        
      </GridItem>

    </GridContainer>
  );
}